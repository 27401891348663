import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-n-reset',
  templateUrl: './n-reset.component.html',
  styleUrls: ['./n-reset.component.scss']
})
export class NResetComponent implements OnInit {
  fieldTextType:any;
  constructor() { }

  ngOnInit() {
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
