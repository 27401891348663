import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CategoryComponent } from "./category.component";
import { of, pipe, Observer } from "rxjs";
import { tap } from "rxjs/operators";
import { GlobalConstants } from "../../global-constants";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  addcategoryForm: any;

  createAuthorizationHeader() {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append("x-access-token", sessionStorage.getItem("token"));
  }

  constructor(private http: HttpClient) {}
  public server = GlobalConstants.apiURL;
  private _refeshneeded$ = new Subject<void>();

  getrefeshneeded$() {
    return this._refeshneeded$;
  }

  getdata(): Observable<CategoryComponent[]> {
    //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    // this.createAuthorizationHeader();
    return this.http.get<CategoryComponent[]>(
      this.server + "api/categories/getAll"
    );
  }

  deleteCategorylist(id): Observable<CategoryComponent[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .delete<CategoryComponent[]>(
        this.server + "api/categories/delete/" + id,
        { headers }
      )
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }

  editCategorires(id): Observable<CategoryComponent[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get<CategoryComponent[]>(
      this.server + "api/categories/get/" + id,
      { headers }
    );
  }

  _loginUrl = this.server + "api/categories/create";
  addCategorires(value) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this._loginUrl, value, { headers }).pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }

  // edit functionaliy
  editcategory_model(values) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .put(this.server + "api/categories/update/" + values.id, values, {
        headers,
      })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }

  Companylist(): Observable<CategoryComponent[]> {
    //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get<CategoryComponent[]>(
      this.server + "api/companies/getAllCompanyInfo",
      {
        headers,
      }
    );
  }
}

//  }
