import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthenComponent } from "./authen.component";
import { NLoginComponent } from "./n-login/n-login.component";
import { NForgetComponent } from "./n-forget/n-forget.component";
import { NOtpComponent } from "./n-otp/n-otp.component";
import { NResetComponent } from "./n-reset/n-reset.component";
import { SignupComponent } from "./signup/signup.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { CLoginComponent } from "./c-login/c-login.component";
import { SurveySharingComponent } from "./survey-sharing/survey-sharing.component";
import { CviewGoogleMapComponent } from "./g-map/cview.google.map.component";
import { SurveyPreviewComponent } from "./survey-preview/survey-preview.component";
import { ChoosethemeComponent } from "./choosetheme/choosetheme.component";
import { PublicDashboardSuperadminComponent } from "./public-dashboard-superadmin/public-dashboard-superadmin.component";

const routes: Routes = [
  {
    path: "",
    component: AuthenComponent,
    children: [
      {
        path: "adminlogin",
        component: NLoginComponent,
      },
      {
        path: "login",
        component: CLoginComponent,
      },
      {
        path: "signup",
        component: SignupComponent,
      },
      {
        path: "forget",
        component: NForgetComponent,
      },
      {
        path: "changePassword",
        component: ChangePasswordComponent,
      },
      {
        path: "generate_pass/:token",
        component: NOtpComponent,
      },
      {
        path: 'preview/:companyId/:surveyId',
        component: SurveyPreviewComponent,
      },
      {
        path: 'chooseCustomisetheme/:companyId/:surveyId',
        component: ChoosethemeComponent,
      },
      {
        path: "reset",
        component: NResetComponent,
      },
      {
        path: "survey-sharing/:key",
        component: SurveySharingComponent,
      },
      {
        path: "public-system-superuser-dashboard",
        component: PublicDashboardSuperadminComponent,
      },
      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenRoutingModule {}

export const routedComponents = [
  AuthenComponent,
  NLoginComponent,
  CLoginComponent,
  SignupComponent,
  NForgetComponent,
  ChangePasswordComponent,
  NOtpComponent,
  NResetComponent,

];
