import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { NgForm} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, EmailValidator, Validators } from "@angular/forms";
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";

import { loginservice } from '../authapi.service';

@Component({
  selector: 'ngx-n-login',
  templateUrl: './n-login.component.html',
  styleUrls: ['./n-login.component.scss']
})
export class NLoginComponent implements OnInit {
  // profileForm: FormGroup;
  submitted = false;
  form: FormGroup;
  response: any; 
 
  public edited = false;
  public edited_fail = false;
  selectedlanguage: string;
  language: string;
  browserDetails:any;

  dismiss() {
    this.router.navigate(['./adminlogin']);
  }



  forget_password() {
    this.router.navigate(['./forget']);
  }

  success_login() {
    this.router.navigate(['/cvdashboard']);
  }

  public constructor(
    private loginservice: loginservice,
    private router: Router,
    private http: HttpClient,
    public fb: FormBuilder,
    private activatedRoute : ActivatedRoute,
    private translateService: TranslateService,
    private SpinnerService: NgxSpinnerService
  ) {

    // this.form = this.fb.group({
    //   name: [''],
    //   password: ['']
    // })
  }

  ngOnInit() {    
    this.language ='en';
   // this.selectedlanguage = sessionStorage.setItem('language');
    this.switchLanguage(this.language);
    this.browserDetails = this.myBrowser();
  }
 
  switchLanguage(language) {
    this.selectedlanguage = language;
    this.translateService.use(language);
    sessionStorage.setItem("language",language);  
  } 

  profileForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    role_id: new FormControl('1'),
    //  console.log(FormGroup);
  });
  get f() { return this.profileForm.controls; }

  submitForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }
    let loginData = {
      email:this.profileForm.value.email,
      password:this.profileForm.value.password,
      role_id:1,
      browser_details:this.browserDetails
    }
    // console.log("Before login ",loginData);
    this.SpinnerService.show();
    this.loginservice.loginUser(loginData).subscribe(response => {
          // console.log("After login ",response);
          if(response.success == true){
            sessionStorage.setItem('token', response["accessToken"]);
            localStorage.setItem('token', response["accessToken"]);
            this.getAllData(response);
            // this.router.navigate(['/cvdashboard']);
          }else{
            this.router.navigate(['/adminlogin']);
          }

          // this.getAllData(response);
          
        },
        error => {
          this.SpinnerService.hide();
          console.log("After login error = ",error);
          this.edited_fail = true;
          this.dismiss();
        }
      )
  };

  getAllData(response) {
    let id = response.id
    //console.log(response);
    this.loginservice.findAllUserData(id).subscribe((response) => {
       console.log("findAllUserData:",response);
      if (response["data"].role_id != 1) {
        this.SpinnerService.hide();
        this.edited_fail = true;
        this.edited = false;
      }else {
        var data = JSON.stringify(response["data"]);
        sessionStorage.setItem('userData', data);
        sessionStorage.setItem('isLoggedin', 'true');
        this.edited = true;
        this.edited_fail = false;
        this.router.navigate(['/cvdashboard']);
        this.SpinnerService.hide();
        
      }
    })
  }
  submit() {
    this.router.navigate(['/cvdashboard']);
  }

  forget() {
    this.router.navigate(['/forget']);

  }
  login() {
    this.router.navigate(['/forget']);

  }
  myBrowser() { 

    var navUserAgent = navigator.userAgent;
    var browserName  = navigator.appName;
    var browserVersion  = ''+parseFloat(navigator.appVersion); 
    var majorVersion = parseInt(navigator.appVersion,10);
    var tempNameOffset,tempVersionOffset,tempVersion;
    
    
    if ((tempVersionOffset=navUserAgent.indexOf("Opera"))!=-1) {
     browserName = "Opera";
     browserVersion = navUserAgent.substring(tempVersionOffset+6);
     if ((tempVersionOffset=navUserAgent.indexOf("Version"))!=-1) 
       browserVersion = navUserAgent.substring(tempVersionOffset+8);
    } else if ((tempVersionOffset=navUserAgent.indexOf("MSIE"))!=-1) {
     browserName = "Microsoft Internet Explorer";
     browserVersion = navUserAgent.substring(tempVersionOffset+5);
    } else if ((tempVersionOffset=navUserAgent.indexOf("Chrome"))!=-1) {
     browserName = "Chrome";
     browserVersion = navUserAgent.substring(tempVersionOffset+7);
    } else if ((tempVersionOffset=navUserAgent.indexOf("Safari"))!=-1) {
     browserName = "Safari";
     browserVersion = navUserAgent.substring(tempVersionOffset+7);
     if ((tempVersionOffset=navUserAgent.indexOf("Version"))!=-1) 
       browserVersion = navUserAgent.substring(tempVersionOffset+8);
    } else if ((tempVersionOffset=navUserAgent.indexOf("Firefox"))!=-1) {
     browserName = "Firefox";
     browserVersion = navUserAgent.substring(tempVersionOffset+8);
    } else if ( (tempNameOffset=navUserAgent.lastIndexOf(' ')+1) < (tempVersionOffset=navUserAgent.lastIndexOf('/')) ) {
     browserName = navUserAgent.substring(tempNameOffset,tempVersionOffset);
     browserVersion = navUserAgent.substring(tempVersionOffset+1);
     if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
     }
    }
    
    // trim version
    if ((tempVersion=browserVersion.indexOf(";"))!=-1)
       browserVersion=browserVersion.substring(0,tempVersion);
    if ((tempVersion=browserVersion.indexOf(" "))!=-1)
       browserVersion=browserVersion.substring(0,tempVersion);  
    // alert("BrowserName = " + browserName + "\n" + "Version = " + browserVersion);
    let browserData = ("BrowserName="+browserName+ ","+"Version="+browserVersion);
    return  browserData

}

}

