import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { of, pipe, Observer } from "rxjs";
import { map, tap } from "rxjs/operators";
import { GlobalConstants } from "../../../../global-constants";
import { CreateSurveyComponent } from "./create-survey.component";
import { CommonModule } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class SurveyService {
  constructor(private http: HttpClient) {}
  public server = GlobalConstants.apiURL;
  private _refeshneeded$ = new Subject<void>();

  getrefeshneeded$() {
    return this._refeshneeded$;
  }

  survey_list(graph_data?, companyId?): Observable<CreateSurveyComponent[]> {
    //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    let query = "";
    if (graph_data && companyId) {
      query = `graph_data=true&company_id=${companyId}`;
    }
    return this.http.get<CreateSurveyComponent[]>
    (`${this.server}api/survey-data/getAll?${query}`,{headers});
  }

  survey_list_between_dates(companyId?, surveyId?, startDate?, endDate?): Observable<CreateSurveyComponent[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get<CreateSurveyComponent[]>
    (`${this.server}api/survey-data/company/${companyId}/survey/${surveyId}/date/${startDate}/${endDate}`,{headers});
  }

  surveyadmin_list(graph_data?, companyId?): Observable<CreateSurveyComponent[]> {
    //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    let query = "";
    if (graph_data && companyId) {
      query = `graph_data=true&company_id=${companyId}`;
    }
    return this.http.get<CreateSurveyComponent[]>(
      `${this.server}api/survey-data/getAlladmin?${query}`,{headers});
  }
//==============================================================================================
companySurveyData(company_id){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(`${this.server}api/survey-data/get-all-survey-list-by-companyId/`+company_id, {headers});
}
//==============================================================================================
  survey_response_list(survey_id,company_id): Observable<CreateSurveyComponent[]> {
    //for display response data on dashboard
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get<CreateSurveyComponent[]>(
      this.server +
        "api/survey-data-customer-response/surveyResponseData/" + survey_id + "/" + company_id,{headers});
  }

  // display add templete
  templetelist(): Observable<CreateSurveyComponent[]> {
    //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get<CreateSurveyComponent[]>(
      this.server + "api/templates/getAll",
      {
        headers,
      },
    );
  }
  _surveyUrl = this.server + "api/survey-data/create";
  addsurvey(value) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this._surveyUrl, value, { headers }).pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }

  deletesurvey(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .delete(this.server + "api/survey-data/delete/" + id, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }
  question_surveydata = this.server + "api/survey-data-questions/create";
  question_insert(values) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.question_surveydata, values, { headers }).pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }

  answer_surveydata =
    this.server + "api/survey-data-answers/createMultipleAnswer";
  answer_survey(values) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.answer_surveydata, values, { headers });
  }

  question_get(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/survey-data/surveyGetAllData/" + id,
      { headers }
    );
  }

  questionsurveyUrl = this.server + "api/survey-data-questions/create";
  createquestion_survey(values) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.questionsurveyUrl, values, { headers }).pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }

  answersurveyUrl =
    this.server + "api/survey-data-answers/createMultipleAnswer";
  createanswer_survey(values) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.answersurveyUrl, values, { headers }).pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }
  deletequestion_survey(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .delete(this.server + "api/survey-data-questions/delete/" + id, {
        headers,
      })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }

  editquestion_survey(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/survey-data-questions/get/" + id, {
      headers,
    });
  }
  editanswer_survey(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/survey-data-questions/getAnswersOfQuestions/" + id,
      { headers }
   );
   }
  // updatequestionsurveyUrl = this.server +"/api/survey-data-questions/update/:id";
  updatequestion_survey(values, id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(
      this.server + "api/survey-data-questions/update/" + id,
      values,
      { headers }
    );
  } 

  updateanswer_survey(values, id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(
      this.server + "api/survey-data-answers/update/" + id,
      values,
      { headers }
    );
  }

  deleteanswer(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.delete(
      this.server + "api/survey-data-answers/deleteAnswerOfQuestion/" + id,
      { headers }
    );
  }

  deletephotoanswer(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.delete(
      this.server + "api/survey-data-answers/delete/" + id,
      { headers }
    );
  }

  //AddSurvey Fields
  addSurveyField(params) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server + "api/survey_fields/create", params, {
      headers,
    });
  }

  getSurveyFields(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/survey_fields/get/" + id, {
      headers,
    });
  }

  geteditdata(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/survey-data/get/" + id, {
      headers,
    });
  }

  editSurvey(id, values) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .put(this.server + "api/survey-data/update/" + id, values, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
   }
   //===============================================================================================
   publishSurvey(id,values){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .put(this.server + "api/survey-data/publish-survey/" + id, values, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
   }
   //===============================================================================================

   changeSurveyActivationStatus(suveyId, activationStatus) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .put(this.server + "api/survey-data/changeActivationStatus/" + suveyId +"/activationStatus/"+activationStatus,{}, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
   }
  //for reordering question in company admin in survey question list
  reorderquestion_survey(id, value) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .put(this.server + "api/survey-data-questions/update/" + id, value, {
        headers,
      })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }
  // for create_thankyou question in company admin in survey question list
  create_thankyou(value) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .post(this.server + "api/thankyou/create", value, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }
  // for update thankyou pages
  update_thankyou(id, value) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .put(this.server + "api/thankyou/update/" + id, value, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }
 // for create_welcome question in company admin in survey question list
  create_welcome(value) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .post(this.server + "api/welcome/create", value, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
     }
  // for update update_welcome pages
  update_welcome(id, value) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .put(this.server + "api/welcome/update/" + id, value, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }

  // for update update_welcome pages
  survey_question_dashbaord(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/survey-data/getAllfornps/" + id, {
      headers,
    });
  }

  survey_response_dashbaord(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/survey-data-customer-response/getAllNPSData/" + id,
      { headers }
    );
  }

  survey_response_count(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/survey-data-customer-response/getAllSurveyData/" + id,
      { headers }
    );
   }


   survey_response_nps_question(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/survey-data-customer-response/getAllnpsresponse/" + id, { headers }
    );
   }

   npsResponseWithFilters(filterData) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(
      this.server + "api/survey-data-customer-response/npsResponseWithFilters", filterData ,{ headers }
    );
   }
   npsResponseFilterByStates(filterData) {
    let headers: HttpHeaders = new HttpHeaders();
    // alert("survey services")
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(
      this.server + "api/survey-data-customer-response/npsResponseFilterByStates", filterData ,{ headers }
    );
   }

   nps_response_between_dates(surveyId, startDate, endDate) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      `${this.server}api/survey-data-customer-response/getNpsResponseBetweenDates/surveyID/${surveyId}/date/${startDate}/${endDate}`, { headers }
    );
  }


   survey_response_rating(questionID) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/survey-data-customer-response/getAllratingresponse/" + questionID,
      { headers }
    );
   }

   ratingResponseWithFilter(filterData) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(
      this.server + "api/survey-data-customer-response/ratingResponseWithFilter",filterData,{ headers });
   }

   getAnswersByQuestionID(questionID){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/survey-data-answers/getAnswersByQuestionID/" + questionID, {headers});
   }
   survey_response_multiplechoice(id){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/survey-data-customer-response/getAllmultiplechoice/" + id,
      { headers }
    );
   }
   
   multiplechoiceResponseWithFilters(filterData){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(
      this.server + "api/survey-data-customer-response/multiplechoiceResponseWithFilters", filterData ,{ headers });
   }

   textBoxResponseWithFilters(filterData){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(
      this.server + "api/survey-data-customer-response/textBoxResponseWithFilters", filterData ,{ headers });
   }

  survey_response_rating_between_dates(questionId, startDate, endDate) {
    return this.http.get(
      `${this.server}api/survey-data-customer-response/getRatingResponseBetweenDates/question/${questionId}/date/${startDate}/${endDate}`
    );
  }
//============================ Response count on behalf on surveyid =====================================================
  responseCount(id) {
    return this.http.get(this.server + "api/survey-data-customer-response/response-count/survey/" + id,
    );
  }
 // ====================================================================================================================
 allsharevalue(id) {
  return this.http.get(this.server + "api/survey-data-customer-response/response-count/survey/" + id,
  );
}

// let url = "https://api.ipgeolocation.io/ipgeo?apiKey=b81cc74e124247a0b99438bc3c22f79c&ip="+ip+"&fields=geo"; 
geolocation(ip) {
  return this.http.get("https://api.ipgeolocation.io/ipgeo?apiKey=b81cc74e124247a0b99438bc3c22f79c&ip="+ip+"&fields=geo" );
}
geolocationV2(lat,long){
  return this.http.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`);
}

getTotalCountOfSurvey(id){
let headers: HttpHeaders = new HttpHeaders();
headers = headers.append("x-access-token", sessionStorage.getItem("token"));
return this.http.get(
  this.server + "api/survey-data-customer-response/response-count-by-survey-id/" + id, {headers});
}

total_response_between_dates(surveyId, startDate, endDate){
 // let headers: HttpHeaders = new HttpHeaders();
//headers = headers.append("x-access-token", sessionStorage.getItem("token"));
return this.http.get(`${this.server}api/survey-data-customer-response/response-count-between-dates/survey/${surveyId}/date/${startDate}/${endDate}`, {});
}

totalResponseWithFilter(filterValues){
return this.http.post(`${this.server}api/survey-data-customer-response/responseWithFilters`, filterValues );
}

//======================================= insertion of contact list data in DB ============================================================= 
 insertMappedData(values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post( this.server + "api/insert-csv-importdata/insert_import_data", values, {headers}).pipe(
     tap(() => {
      this._refeshneeded$.next();
    })
  );
 }
//======================================== get value of contact list  file name  =========================================== 
contactList(company_id){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(
    this.server + "api/import-data/get-by-companyId/" + company_id  , {headers});
}


//======================================== get value of contact list  With Active Status  =========================================== 
contactListWithActiveStatus(company_id){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(
    this.server + "api/import-data/getAllBycompanySurveyIdWithActiveStatus/" + company_id  , {headers});
}





//================================================ get value from file name ====================================================

indiviualsContactList(id){ 
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(
    this.server + "api/importdata/get-Data-from-File-id/getByfileId/" + id, {headers});
  }

  indiviualsContactListAll(id){ 
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/importdata/get-Data-from-File-id/getByfileIdAll/" + id, {headers});
  }
//=================================================== Delete data from conatc page  ========================================
deleteImportFile(id) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http
    .delete(this.server + "api/import-data-info/deleteanCSV/" + id, { headers })
    .pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
}
//=================================================== Delete data from indiviuals Page ========================================
deleteIndiviuals(id) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http
    .get(this.server + "api/importdata/delete/" + id, { headers })
    .pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
}


is_label_deleted(selectedContactArray) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server +  "api/import-data-info/Is-label-delete-All-Selected-ContactList/",selectedContactArray,{ headers }) 
 }

 //=================================================== (Hitesh Edit) deleteSelectedContactList ========================================
  deleteSelectedInfoFromContact(selectedContactArrayData:any){
    
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server +  "api/import-data-info/hard-delete-contact-info-from-db/",selectedContactArrayData,{ headers }) 
 
  }

  softDeleteSelectedInfoFromContact(selectedContactArrayData:any){

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server +  "api/import-data-info/soft-delete-label-contact-info-from-db/",selectedContactArrayData,{ headers }) 
 
  }

//=================================================== deleteSelectedContactList ========================================

 deleteSelectedContactList(selectedContactArray){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server + "api/import-data-info/delete-selected-contact-list",selectedContactArray,{ headers }) 
  }

  deletecontactalldatafromcompanyimport(company_id){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.delete(this.server + "api/import-data-info/delete-all-contact-list/" +company_id,{ headers })
   }
 //=========================================== Activate Deactivate  the Contact List ==========================================
 ActiveDeactiveContact(id,values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.put(
    this.server + "api/import-data-info/update_Active-List-By-Id/" + id,
    values,
    { headers }
  ).pipe(
    tap(() => {
      this._refeshneeded$.next();
    })
  );
} 

//=========================================== Activate Deactivate  the Contact List ==========================================
ActiveDeactiveIndiviuals(id,values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.put(
    this.server + "api/importdata/update_Active-List-By-Id/" + id,
    values,
    { headers }
  )  .pipe(
    tap(() => {
      this._refeshneeded$.next();
    })
  );
} 
//================================================ edit functionality in indiviuals List ======================================
//=========================================== Activate Deactivate  the Contact List ==========================================
editIndiviuals(id,values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.put(
    this.server + "api/importdata/update_Indiviuals-List-By-Id/" + id,
    values,
    { headers }
  )  .pipe(
    tap(() => {
      this._refeshneeded$.next();
    })
   );
  } 

  //=============================================get all data from labels  ==================================================================
  labels(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/import-label/getalllabel/companyId/" + id,
      { headers }
    ) 
    } 
  
  //=============================================get all data from labels  ==================================================================
  getDataLabelId(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/importdata/Get-List-By-LabelId/" + id,
      { headers }
    )  .pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
     );
    } 
    getDataNewLabelId(id) {
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append("x-access-token", sessionStorage.getItem("token"));
      return this.http.get(
        this.server + "api/importdata/Get-New-List-By-LabelId/" + id,
        { headers }
      )  .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
       );
      } 
//=================================================== get all data data from contacts ===========================
getDataCompanyId(id) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(
    this.server + "api/importdata/getAllDataFromCompanyId/" + id,
    { headers }
  )  .pipe(
    tap(() => {
      this._refeshneeded$.next();
    })
   );
  } 

  getAllDataCompanyId(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/importdata/getAllDataFromCompanyIdwithAllStatus/" + id,
      { headers }
    )  .pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
     );
    } 
  


  //=================================================== get all data data from email smtp  ===========================
smtpapprovedemail(id) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(
    this.server + "api/import-label/get-emailList-smtp-approved/" + id,
    { headers }
  )  .pipe(
    tap(() => {
      this._refeshneeded$.next();
    })
   );
  } 
//==================================================== get all data from active status ================================
//=================================================== get all data data from contacts ===========================
getDataActiveData(id) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(
    this.server + "api/importdata/getAllDataFromCompanyIdwithActiveStatus/" + id,
    { headers }
  )  .pipe(
    tap(() => {
      this._refeshneeded$.next();
    })
   );
  } 

//===================================================== label functionality =======================================================
createLabel(values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post( this.server + "api/import-label/create_label", values, {headers}).pipe(
     tap(() => {
      this._refeshneeded$.next();
    })
  );
 } 
 updateLabel(values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post( this.server + "api/import-label/update_label", values, {headers}).pipe(
     tap(() => {
      this._refeshneeded$.next();
    })
  );
 } 
 deleteLabel(values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post( this.server + "api/import-label/delete_label", values, {headers}).pipe(
     tap(() => {
      this._refeshneeded$.next();
    })
  );
 } 
//=====================================================  End of Code =================================================================
deleteSelectedIds(values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post( this.server + "api/importdata/delete-by-selectedId", values, {headers}).pipe(
     tap(() => {
      this._refeshneeded$.next();
    })
  );
 } 

 //====================================== Create indiviuals Lists =============================================
 //===================================================== label functionality =======================================================
createindiviuals(values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post( this.server + "api/importdata/insertdata", values, {headers}).pipe(
     tap(() => {
      this._refeshneeded$.next();
    })
  );
 } 
//============================================= Get all data from survey Id ==================================================================
  surveydataById(id) {
   let headers: HttpHeaders = new HttpHeaders();
   headers = headers.append("x-access-token", sessionStorage.getItem("token"));
   return this.http.get(
     this.server + "api/survey-data/getdatabyId/" + id,
      { headers }
    ) 
  } 
//======================================================== Get all data ================================================================
emailtemplatetest(values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.post(
    this.server + "api/auth/send-mail-email-template" ,values,
     { headers }
   ) 
 } 

//=================================== get Survey List and Assign Survey to users =========================================

 new_survey_list(id) {  // for display data of
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem('token'));
  return this.http.get(this.server + "api/survey-data/getAllbycompany/"+id , {});
 }

 assignAllSurveystoUser(data){
  return this.http.post(this.server + "api/survey-data/assignAllSurveystoUser" , data);
 }

 removeAllAssignedSurveytoUser(userId){
   return this.http.delete(this.server + "api/survey-data/removeAllAssignedSurveytoUser/"+userId,{})
 }


 assignSingleSurvey(data){
  return this.http.post(this.server + 'api/survey-data/assignSingleUser',data)
}


removeSingleSurvey(userId, surveyId){
  return this.http.delete(this.server + 'api/survey-data/removeAssignedUser/userID/'+userId+'/surveyID/'+surveyId)
}

 //===================================================== EXPORT ALL RESPONSE =============================================
 exportAllSurveyResponse(surveyId){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(this.server + "api/survey-data-customer-response/export-all-responses-of-survey/" + surveyId,{ headers }) 
 }
//==================================================== GET ALL USER'S UPDATED LAT LNG ============================
getAllusersUpdatedLatLng(companyId){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(this.server + "api/registrations/get-lat-long-by-companyId/" + companyId,{ headers }) 
 }
//==================================================== GET SURVEY DATA FOR DAHBOARD WIDGET ============================
getAllSurveyDataByCompanyId(companyId){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(this.server + "api/survey-data/get-all-survey-counts/" + companyId,{ headers }) 
 }

 //===================================|COPY SURVEY API |================================================
copyParticularSurvey(surveyId){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(this.server +"api/survey-data/copy-survey/" + surveyId,{ headers }) 
 }

 //===================================| Mail Share API |================================================
   SendmailtoQueue(value){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server +"api/send-mail-to-importdata/email_queue",value, { headers }) 
  }

  //================================================ get all email status  ====================================================

  EmailListstatus(id){ 
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(
    this.server + "api/import-data/getAllEmailBySurveyId/" + id, {headers});
  }

//===================================================== get email tempplate from email templete Table ======================
//======================================== get value of contact list  file name  =========================================== 
emailTemplete(slugname,survey_id){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(this.server + "api/email_template/get_name_email_template/bulkEmailshootForSurveyLink/" + slugname +  '/'+ survey_id , {headers});
}

  //================================================ Email Shoot Bulk   ====================================================

  EmailBulkShoot(companyId){ 
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/import-data/sendmailfromaws/" +companyId , {headers});
    }

//=============================================== |Export end here|==============================

allcontactlabelupdate(contactlist){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.post(this.server + "api/import-data-info/Update-Label-contact" ,contactlist ,{ headers })
 }

//======================================= SMTP verify with email And Password  ============================================================= 

smtpverifyresult(values) {
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post( this.server + "api/email_template/verify_email_smtp_send", values, {headers}).pipe(
     tap(() => {
      this._refeshneeded$.next();
    })
  );
 } 

}

