import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { loginservice } from '../authapi.service';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

 export class ChangePasswordComponent implements OnInit {
  public changePassword: FormGroup;
  public edited = false;
  public edited_fail = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: loginservice,
    private router: Router,
  ) { 

     this.changePassword = this.formBuilder.group({
      old_pass: ["", [Validators.required]],
      newPsw: ["", [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit() {
  }
    changePsw() {
       this.authService.changePassword({
        old_pass: this.changePassword.value.old_pass,
        newPsw: this.changePassword.value.newPsw,
      }).subscribe(
        (response: any) => {
         // alert(response);
            this.edited = true;
            // setTimeout(function () {
            //  // this.edited = false;
            // }, 300000);
            // this.toastr.success(response["message"]);
            // this.router.navigate(["/my-profile"]); 
        },error => {
        //  alert('failded');
          this.edited_fail = true;
          setTimeout(function () {
            this.edited_fail = false;
          }, 3000);
          // this.toastr.error(error.error.message);
        }
      );
  }
}
