
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { StripeModule } from "stripe-angular"
import { Angular2SignaturepadModule } from 'angular2-signaturepad';

import {
  NbChatModule,

  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,


} from '@nebular/theme';
import {DataTablesModule} from 'angular-datatables';
import { AuthenModule } from './authen/authen.module';
import { ModelDeletetempleteComponent } from './pages/template/model-deletetemplete/model-deletetemplete.component';
import { ModalDeleteTemplateModule } from './pages/template/model-deletetemplete/model-deletetemplate.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { InactivePlanModelComponent } from './pages/plans-pricing/inactive-plan-model/inactive-plan-model.component';
import {  ChartsModule } from "ng2-charts";
import { NgxSpinnerModule } from "ngx-spinner";
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { JoyrideModule, JoyrideStepComponent } from "ngx-joyride";
import { AuthGuard } from './auth.guard';
import { AuthInterceptor } from './services/auth-interceptor.service';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".json" },
  ]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AuthenModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    Ng2TelInputModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    Ng2SmartTableModule,
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    ModalDeleteTemplateModule,
    DataTablesModule.forRoot(),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularEditorModule,
    StripeModule.forRoot("sk_test_51HtUXuFZUaPqu7GusnKzQFYlwcU9QG6QfhY2yPhin7CGpQLlsIHdjWQ7eAqqjYo5Taj7YW0W4vYonvqgiHkiQ0ah003sdpVYmF"),
    ChartsModule,
    Angular2SignaturepadModule,
    LeafletModule,
    LeafletDrawModule,
    NgxSpinnerModule,
    JoyrideModule.forRoot()
  ],
  exports: [ TranslateModule  ],
  providers: [AuthGuard,{
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents:[ModelDeletetempleteComponent,JoyrideStepComponent],
})
export class AppModule {
}
