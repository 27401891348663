import { NgModule} from '@angular/core';
import { ThemeModule } from '../@theme/theme.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule , HttpClient} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {GlobalConstants} from '../global-constants'
import { Angular2SignaturepadModule } from 'angular2-signaturepad';
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { ColorPickerModule } from 'ngx-color-picker';


  export function translateHttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }
  
import { 
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    NbAlertModule,
    NbLayoutModule,
    
  } from '@nebular/theme';

import { AuthenRoutingModule } from './authen.routing.module';
import { AuthenComponent } from './authen.component';
import { NLoginComponent } from './n-login/n-login.component';
import { NForgetComponent } from './n-forget/n-forget.component';
import { NOtpComponent } from './n-otp/n-otp.component';
import { NResetComponent } from './n-reset/n-reset.component';
import { SignupComponent } from './signup/signup.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CLoginComponent } from './c-login/c-login.component';
import { NgxSpinnerModule } from "ngx-spinner";  
import { SurveySharingComponent } from "./survey-sharing/survey-sharing.component";
import { MapComponent } from "./geofencing/map/map.component";
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import { CviewGoogleMapComponent } from "./g-map/cview.google.map.component";
import { AgmCoreModule } from '@agm/core';
import { GoogleLoginProvider, FacebookLoginProvider,LinkedinLoginProvider, AuthService } from 'angular-6-social-login';  
import { SocialLoginModule, AuthServiceConfig } from 'angular-6-social-login'; 
import { SurveyPreviewComponent } from "./survey-preview/survey-preview.component";
import { ChoosethemeComponent } from './choosetheme/choosetheme.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PublicDashboardSuperadminComponent } from './public-dashboard-superadmin/public-dashboard-superadmin.component';

import { HighchartsChartModule } from 'highcharts-angular';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { ThemeService } from "ng2-charts";

export const routedComponents = [
    AuthenComponent,
    NLoginComponent,
    NForgetComponent,
    NOtpComponent,
    NResetComponent,
    SignupComponent,
    ChangePasswordComponent,
    CLoginComponent,
    SurveySharingComponent,
    MapComponent,
    CviewGoogleMapComponent,
    SurveyPreviewComponent,
    ChoosethemeComponent,
    PublicDashboardSuperadminComponent
]

// Configs 
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
      [
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider("472838190401101")
        },
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(GlobalConstants.googleClientID)
        },
          {
            id: LinkedinLoginProvider.PROVIDER_ID,
            provider: new LinkedinLoginProvider("78bb3n4vca5asx")
          },
      ]
  )
  return config;
}
@NgModule({
    imports:[  
        HttpClientModule,
        NbActionsModule,
        ReactiveFormsModule,
        Ng2SearchPipeModule,
        FormsModule,
        NbButtonModule,
        NbCardModule,
        NbCheckboxModule,
        NbIconModule,
        NbInputModule,
        NbRadioModule,
        NbSelectModule,
        NbUserModule,
        NbAlertModule,
        NbLayoutModule,       
        ThemeModule,
        NgxSpinnerModule, 
        AuthenRoutingModule,
        NgxSliderModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: translateHttpLoaderFactory,
              deps: [HttpClient]
            }
          }),
          LeafletModule,
          AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCwS10i0pyL2gcX564hL3huMdPyJS2a4gE',
            libraries: ['places']
          }),
          SocialLoginModule,
          Angular2SignaturepadModule,
          ColorPickerModule,
          NgMultiSelectDropDownModule,
          HighchartsChartModule,
          ChartsModule,
          NgxPaginationModule
       
    ],
    providers: [  
      {
        provide: AuthServiceConfig,
        useFactory: getAuthServiceConfigs
      },
      ThemeService
    ], 
    declarations:[
       ...routedComponents
    ]
})

export class AuthenModule{}

