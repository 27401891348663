import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NbToastrService } from "@nebular/theme";
//FOR Social Login
import {
  GoogleLoginProvider,
  FacebookLoginProvider, 
  LinkedinLoginProvider,
  AuthService,
} from "angular-6-social-login";
import { SocialLoginModule, AuthServiceConfig } from "angular-6-social-login"; 
import { UserService } from "../../pages/company/company-details/users/user.service"


//FOR SERVICES
import { HttpClient } from "@angular/common/http";
import { CompanyService } from "../../pages/company/company.service";
import { loginservice } from "../authapi.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { UserData } from "../../@core/data/users";
import { GlobalConstants } from "../../global-constants";
import {Renderer2} from '@angular/core';
import { planservice } from "../../services/planapi.service";
import countryCodes  from './countrycode.json'; 

//=========================================================================================================
@Component({
  selector: "ngx-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
//==================================== class ==========================================================================
export class SignupComponent implements OnInit {
  countryCode=[];
  submitted: boolean;
  public companyAdminForm: FormGroup;
  edited = false;
  categorylist: any[];
  edited_fail: any;
  emailcheckk = false;
  fieldTextType: any;
  fieldTextType2: any;
  selectedlanguage: string;
  index: number;
  selectedflag = "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg";
  selectedphonecode = "91";
  companyCity:any;
  companyState:any;
  companyCountry:any;
  searchText:string;
  //Users: any = sampleData;

  //================================== constructor ==========================================================================
  constructor(
    private http: HttpClient,
    public fb: FormBuilder,
    private router: Router,
    private toastrService: NbToastrService,
    private CompanyService: CompanyService,
    private Authservice: loginservice,
    private SpinnerService: NgxSpinnerService,
    private translateService: TranslateService,
    private socialAuthService: AuthService,
    private renderer: Renderer2,
    private UserService: UserService,
    public service: planservice,
  ) {
    this.companyAdminForm = this.fb.group(
      {
        company_name: ["", [Validators.required, Validators.minLength(3)]],
        category_id: ["", Validators.required],
        company_email: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ),
          ],
        ],
        company_contact: [
          "",
          [
            //Validators.required,
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          ],
        ],
        manager_name: new FormControl("", [Validators.required]),
        password: ["", [Validators.required,Validators.minLength(6)]],//,Validators.pattern("/^\S*$/"),Validators.pattern("^([a-zA-Z0-9!@#$%^&*()_+-=<>?]{6,15})$"),]],
        confirm_password: ["", [Validators.required]],
        role_id: 2,
      },
      {
        validator: this.ConfirmedValidator("password", "confirm_password"),
      }
    );
  }
  //============================= ngOnInit ============================================================================

  renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    var v = document.createElement("script");
    v.type = "text/javascript";
    let url = `function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element'); }`
    v.innerHTML = url;
    this.renderer.appendChild(document.body,v);
    return script;
  }
  mobilecode : any;


  keyPressAlphanumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/^[a-zA-Z0-9 ]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  keyPressNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  
  ngOnInit() {
    console.log(countryCodes.countryCodes);
    this.mobilecode = countryCodes.countryCodes;
 //   console.log(this.countryCodes.country_codes,".....this.countryCodes");
  
    this.renderExternalScript('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit').onload = () => {
      console.log('Google API Script with full language loaded');
        }
    this.Displayaddcategory();
    if (
      sessionStorage.getItem("language") == null ||
      sessionStorage.getItem("language") == ""
    ) {
      this.selectedlanguage = "en";
    } else {
      this.selectedlanguage = sessionStorage.getItem("language");
    }
    this.switchLanguage(this.selectedlanguage);
    this.country();
  }
  //============================================== Country Fileds Data ================================================================
  country() {
 //   console.log(this.countryCodes,"sdigjk")
    // this.CompanyService.countryCode().subscribe(
    //   (response: any) => {
    //     console.log(response)
      // this.countryCodes = response;
         this.getCurrentIP();

    //   },
    //   (error) => console.log(error)
    // );
  }
  onKey(event){
 this.searchText = event.target.value
  }

  Phcode(flag, code) {
    //alert("Phcode" + code);
    this.selectedflag = flag;
    this.selectedphonecode = code;
  }
  //=========================================================================================================
  sucess_login() {
    sessionStorage.setItem("language", this.selectedlanguage);
   // window.location.href = GlobalConstants.web_domain + "/login";
     this.router.navigate(["/login"]);
  }
  //=========================================================================================================
  switchLanguage(language: string) {
    this.selectedlanguage = language;
    sessionStorage.setItem("language", language);
    this.translateService.use(language);
  }
  //=========================================================================================================
  Displayaddcategory() { 
    // alert("abc");
    this.Authservice.displaycategory().subscribe((data: any[]) => {
      this.categorylist = data; 
    });
    (error) => {
      console.log("no data found");
    };
  }
  //=========================================================================================================
  get f() {
    return this.companyAdminForm.controls;
  }
  //=========================================================================================================
  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  //=========================================================================================================
  privacyPolicyCheckedStatus = false;
  policyCheckedChange(checkedStatus){
    this.privacyPolicyCheckedStatus = checkedStatus;
  }
  submitForm() {
    this.submitted = true;
    // this.SpinnerService.show();
    //console.log(this.company_email);
    this.emailcheckk = false;
    this.error = null;
    this.signup();
    // this.CompanyService.emailcheck(
    // this.companyAdminForm.value.company_email
    // ).subscribe((response) => {
    // if (response["message"] == "Email already exist !") {
    // this.emailcheckk = true;
    // this.edited = false;
    // this.edited_fail = false;
    // // setTimeout(() => {
    // // this.emailcheckk = false;
    // // }, 30000);
    // } else {
    // this.signup();
    // }
    // });
  }
  //=========================================================================================================
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }
  //=========================================================================================================
  error = null;
   signup() {
     this.CompanyService.addCompany({
      company_name: this.companyAdminForm.value.company_name,
      company_email: this.companyAdminForm.value.company_email,
      company_contact: this.companyAdminForm.value.company_contact,
      country_code:this.selectedphonecode.toString(),
      admin_name: this.companyAdminForm.value.manager_name,
      category_id: this.companyAdminForm.value.category_id,
      password: this.companyAdminForm.value.password,
      company_city:this.companyCity?this.companyCity:null,
      company_state:this.companyState?this.companyState:null,
      company_country:this.companyCountry?this.companyCountry:null,
      login_type: "cviewsurvey",
      // notify: true,
    }).subscribe(
      (response: any) => {
      console.log(response,"=======================================");
       this.edited = true;
        // this.registration(response);
        this.error = null;
        this.submitted = false;
        // setTimeout(() => {
        //   //<<<---using ()=> syntax
        //   this.edited = true;
        // //  alert("registor Sucessfully");
        //   // alert("this.edited" + this.edited);
        // //  this.sucess_login();
        //   this.getloginData(this.companyAdminForm.value.company_email,this.companyAdminForm.value.password,response["data"].companyId)
        //   // this.SpinnerService.hide();
        // }, 1000);
       
      },
      (error: any) => {
        // this.SpinnerService.hide();
    // console.log(error.error,"error.error");
    // return;
        this.error =
          error && error.error && error.error.message
            ? error.error.message
            : "Someting wrong with the request!";
        // alert('please enter valid email and password');
        // this.edited_fail = true;
        // wait 3 Seconds and hide
        setTimeout(function () {
          this.edited_fail = false;
        }, 3000);
        console.log(error);
      }
    );
  }
  //=========================================================================================================
  userdata :any;
  registration(response) {
    const registration_data = {
      name: this.companyAdminForm.value.manager_name,
      email: this.companyAdminForm.value.company_email,
      mobile: this.companyAdminForm.value.company_contact,
      password: this.companyAdminForm.value.password,
      company_id: response.id,
      role_id: 2,
    };

      this.CompanyService.registrationdata(registration_data).subscribe(
      (response) => {
        //console.log();
        this.edited_fail = false;
        this.emailcheckk = false;
        this.edited = true;
         setTimeout(() => {
          //<<<---using ()=> syntax
          this.edited = true;
          // this.sucess_login();
          this.companyAdminForm.reset();
          this.SpinnerService.hide();
        }, 1000);
      },
      (error: any) => {
        this.SpinnerService.hide();
        this.edited = false;
        this.edited_fail = true;
        this.emailcheckk = false;
        setTimeout(() => {
          //<<<---using ()=> syntax
          this.edited_fail = false;
        }, 3000);

        // this.edited_fail = true;
        // this.edited = false;
        // // wait 3 Seconds and hide
        // setTimeout(function() {
        // this.edited_fail = false;
        // }, 3000);
        // console.log(error)
      }
    );
    // )
  }
  //====================================================================================================

  //====================================== SOCIAL LOGIN  ===========================================
  //====================================== Sign UP With GOOGLE  ===========================================
  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then((userData) => {
      console.log(socialPlatform + " sign in data : ", userData);
      // Now sign-in with userData
      let str = userData.name;
      let index = str.indexOf(" ");
      str.substring(0, index);
      // console.log( str.substring(0, index)+userData.id.substring(0, 4));
      let genratedPasswordForSocialLogin =
        str.substring(0, index) + userData.id.substring(0, 4);

      //  ======================== ADD Company By Social Login =======================================
      this.CompanyService.addCompany({
        company_name: userData.name + "'s Company",
        company_email: userData.email,
        admin_name: userData.name,
        category_id: 9,
        password: genratedPasswordForSocialLogin,
        social_login_id: userData.id,
        login_type: "Google",
      }).subscribe(
        (response: any) => {
          this.edited = true;
          this.error = null;
          setTimeout(() => {
            this.edited = true;
            this.getloginData(userData.email,genratedPasswordForSocialLogin,response["data"].companyId);
           
          }, 2000);
        },
        (error: any) => {
          this.SpinnerService.hide();
          if(error.error.message == "Company already registered with this email!"){
          //  this.getloginData(userData.email,genratedPasswordForSocialLogin,null);
           }
        }
      );
    });
  }

   getloginData(email,password,company_id){
 
    this.Authservice.loginUser({
      email: email,
      password: password,
      role_id: 2, //For Company admin only
      }).subscribe((response: any) => {
        
    // console.log(response);
    // console.log("==============> <br\>");
     sessionStorage.setItem('token', response["accessToken"]);
       localStorage.setItem('token', response["accessToken"]);
       this.getAllData(response["id"]);
     // this.genrateUsernameOnKeyUp(2,company_id,response);
      this.companyAdminForm.reset();
    }, error => {
    });
  }
  getAllData(response) {
     let id = response;
    this.Authservice.findAllUserData(id).subscribe((response) => {
      this.SpinnerService.hide();
      // console.log(response,"====================s");
      if (response["data"].role_id != 2 || response["data"].company.IsDeletedBy == 1) {
        this.edited_fail = true;
        this.edited = false;
      } else {
        var data = JSON.stringify(response["data"]);
        sessionStorage.setItem('userData', data);
        sessionStorage.setItem('isLoggedin', 'true');
        this.edited = true;
        this.edited_fail = false;
        this.sucess_gmail();
      //  this.activeplan();
      }
    })
  }
  
  sucess_gmail() {
     this.router.navigate(['/dashboard']);
   }
  //=========================================================================================================
    //========================================  getCurrentIP  ====================================================
    getCurrentIP() {
   //  console.log(this.mobilecode);
     this.service.getCurrentIPAddress().subscribe((result: any) => {
        console.log(result)
      this.CompanyService.getCurrentIPDetails(result.ip).subscribe((result: any) => {
        console.log(result,'after ip details');
        console.log(this.mobilecode,"{{ code.callingCodes }}")
        for(let i=0;i<=this.mobilecode.length;i++){
          if(this.mobilecode[i].name == result.geoplugin_countryName || this.countryCode[i].nativeName == result.geoplugin_countryName){
                this.selectedphonecode = this.mobilecode[i].number;
                this.selectedflag = this.countryCode[i].flag;
                this.companyCity = result.geoplugin_city;
                this.companyState = result.geoplugin_region;
                this.companyCountry = result.geoplugin_countryName;
                // console.log(this.selectedphonecode)        
                // console.log(this.selectedflag) 
                // console.log(this.companyCity) 
                // console.log(this.companyState) 
                // console.log(this.companyCountry) 
              }
          }

        

    //     // for(let i=0;i<=this.countryCode.length;i++){
    //     //   if(this.countryCode[i].name == result.geoplugin_countryName || this.countryCode[i].nativeName == result.geoplugin_countryName){
    //     //         this.selectedphonecode = this.countryCode[i].callingCodes[0];
    //     //         this.selectedflag = this.countryCode[i].flag;
    //     //         this.companyCity = result.geoplugin_city;
    //     //         this.companyState = result.geoplugin_region;
    //     //         this.companyCountry = result.geoplugin_countryName;
    //     //         console.log(this.selectedphonecode)        
    //     //         console.log(this.selectedflag) 
    //     //         console.log(this.companyCity) 
    //     //         console.log(this.companyState) 
    //     //         console.log(this.companyCountry) 
    //     //       }
    //     // }
    //     // console.log(result.geoplugin_city)
    //     // console.log(result.geoplugin_region)
    //     // this.countryCode.map((countryApiData)=>{
    //     //   if(countryApiData.name == result.geoplugin_countryName || countryApiData.nativeName == result.geoplugin_countryName){
    //     //     // console.log(countryApiData.name)
    //     //     // console.log(countryApiData.callingCodes[0])
    //     //     // console.log(countryApiData.flag);
    //     //     this.selectedphonecode = countryApiData.callingCodes[0]
    //     //     this.selectedflag = countryApiData.flag;
    //     //     this.companyCity = result.geoplugin_city;
    //     //     this.companyState = result.geoplugin_region;
    //     //     this.companyCountry = result.geoplugin_countryName;
    //     //     console.log(this.selectedphonecode)        
    //     //     console.log(this.selectedflag) 
    //     //     console.log(this.companyCity) 
    //     //     console.log(this.companyState) 
    //     //     console.log(this.companyCountry) 
    //     //   }
    //     // })
            
    //     // console.log(this.selectedphonecode)        
    //     // console.log(this.selectedflag)        
        
       }); //getCurrentIPAddress
     });
    //  this._getCurrentLocationDetails();
    }


//==============================|GENARATE UNIQUE USERNAME |===================================================

randomUsername;
twoDigitsFromMobileNumber :any;
async genrateUsernameOnKeyUp(length,company_id,responses){
  var result           = [];
  var characters       = '@#$&%';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result.push(characters.charAt(Math.floor(Math.random()*charactersLength)));
   }
 let randomSpcialCharString =  result.join('');
 if(this.companyAdminForm.value.company_contact == "" || this.companyAdminForm.value.company_contact == null  ){
  var random = Math.floor(Math.random() * 10000000000);
  this.twoDigitsFromMobileNumber =  String(random).substring(0, 4);
  }
  else{
  this.twoDigitsFromMobileNumber =  String(this.companyAdminForm.value.company_contact).substring(0, 4);
  }
 
 let str = this.companyAdminForm.value.manager_name;
 let nameTrimUptoBlankSpace = str.substring(0, str.indexOf(" "));
 if(nameTrimUptoBlankSpace == ''){
  nameTrimUptoBlankSpace = str;
 }
 let finalRandomUSername = nameTrimUptoBlankSpace + `_`+this.twoDigitsFromMobileNumber+randomSpcialCharString
 this.randomUsername = finalRandomUSername

 this.userdata = {
  name: this.companyAdminForm.value.manager_name,
  email: this.companyAdminForm.value.company_email,
  mobile: String(this.companyAdminForm.value.company_contact),
  userName: this.randomUsername,
  password: this.companyAdminForm.value.password,
  country_code: this.selectedphonecode,
  company_id: company_id,
  registorTypesignup : "usersignup"

};
   this.UserService.addusersignup(this.userdata).subscribe((response) => {
   this.getAllData(responses);
  },
  (error) => {
  }
);

}



  //=========================================================================================================
  _getCurrentLocationDetails(){
    // this.CompanyService.getCurrentLocationDetails().subscribe((result: any) => {console.log(result)})
  }
  //=========================================================================================================
  //=========================================================================================================
  //=========================================================================================================
  //=========================================================================================================
  //=========================================================================================================
  //=========================================================================================================
  //=========================================================================================================
}
