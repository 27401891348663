import { Component} from '@angular/core';

@Component({
  selector: 'ngx-authen',
  templateUrl: './authen.component.html',
  styleUrls: ['./authen.component.scss']
})
export class AuthenComponent {

  constructor() { }

  ngOnInit() {
  }

}
