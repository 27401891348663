import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private router:Router){}

  get t(): string | null {
    return localStorage.getItem('token');
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(this.t){
        // console.log("auth-if",this.t);
        return true;
      }else{
        // console.log("auth-else",this.t);
        this.router.navigate(['/login'],{ queryParams: { returnUrl: state.url }});
        return false;
        
      }
   
  }
  
}
