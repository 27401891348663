import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GlobalConstants } from "../../global-constants";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class GeofenceService {
  public server = GlobalConstants.apiURL;
  constructor(
    private http: HttpClient,
) {}

associated_survey_list(geoFenceId){
  return this.http.get(this.server + "api/geo-fencing/getAssociatedSurveyList/"+geoFenceId , {});
}

saveGeoFenceData(values){
  return this.http.post(this.server + `api/geo-fencing/create_survey_geofencing`,values );
}

updateGeoFenceData(geoFenceId,geoData){
  return this.http.post(this.server + `api/geo-fencing/update_survey_geofencing/`+geoFenceId,geoData );
}
//====================================== GET ADDRESS BY GEO LOACATION ==========================================================
geoAddress(lat,long){
  return this.http.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`);
}

getPosition(): Promise<any> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (resp) => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
      (err) => {
        reject(err);
      }
    );
  });
}
//=================================== GET ALL ACTIVE GEO FENCE DATA LIST ========================================
_getAllActiveGeoFencingList(){
  return this.http.get(this.server + `api/geo-fencing/getAllActiveGeoFence`);
}
//==================================== GET ALL GEO FENCING DATA ==================================================

_getAllGeoFencingList(){
  return this.http.get(this.server + `api/geo-fencing/getAllGeoFence`);
}

_getGeoFencesListByCompanyId(companyId){
  return this.http.get(this.server + `api/geo-fencing/getGeoFencesByCompanyId/`+companyId);
}

_getGeoFencingData(geoFenceId){
  return this.http.get(this.server + `api/geo-fencing/getGeoFenceByGeoid/`+geoFenceId);
}
//====================================== ASSIGN GEO FENCE TO SURVEYS =============================================
assignGeoFenceToSurveys(values){
  return this.http.post(this.server + `api/geo-fencing/assign-geo-fence-to-surveys`,values);
}
//====================================== UPDATE GEO FENCE STATUS ACTIVE/INACTIVE ================================
changeStatusOfGeoFence(data){
  return this.http.post(this.server + `api/geo-fencing/update-geo-fence-status`,data);
}
//========================================= DELETE GEO FENCE ===================================================
deleteGeoFence(id){
  return this.http.delete(this.server + `api/geo-fencing/delete/`+id);
}
//================================= Associated User with Geo Fence ===========================

associateSurvey(associateSurveyData){
return this.http.post(this.server + 'api/geo-fencing/associateSurvey',associateSurveyData)
}

removeSurvey(geoFenceId, surveyId){
  return this.http.delete(this.server + 'api/geo-fencing/removeAssociatedSurvey/surveyID/'+surveyId+'/geoFenceId/'+geoFenceId)
}

associateAllUser(geoFenceId, surveyID, selectedUsers){
  return this.http.post(this.server + 'api/geo-fencing/associateAllUsers/surveyID/'+surveyID+'/geoFenceId/'+geoFenceId, selectedUsers)
}

associateOneUser(userData){
  return this.http.post(this.server + 'api/geo-fencing/associateOneUser', userData)
}

removeAllUser(geoFenceId, surveyID){
  return this.http.post(this.server + 'api/geo-fencing/removeAllUsers/surveyID/'+surveyID+'/geoFenceId/'+geoFenceId, {})
}

removeOneUser(userData){
  return this.http.post(this.server + 'api/geo-fencing/removeOneUser', userData)
}

getAssociatedUserList(geoFenceId) {
  return this.http.get(this.server + `api/geo-fencing/getAssociatedUserByGeoid/`+geoFenceId);
}

allowOutsideFence(geoFenceId, surveyId){
  return this.http.post(this.server + 'api/geo-fencing/allowSurveyOutsideFence/surveyId/'+surveyId+'/geoFenceId/'+geoFenceId,{})
}

notAllowOutsideFence(geoFenceId, surveyId){
  return this.http.post(this.server + 'api/geo-fencing/notAllowSurveyOutsideFence/surveyId/'+surveyId+'/geoFenceId/'+geoFenceId,{})
}
getAllSurveyWithAssignedUser(companyId){
  return this.http.get(this.server + 'api/geo-fencing/getAllSurveyWithAssignedUser/'+companyId)
}
getAssociatedSurveyData(fenceID){
  return this.http.get(this.server + 'api/geo-fencing/getAssociatedSurveyData/'+fenceID)
}
getUserIsInsideFence(userData){
  // console.log("getUserIsInsideFence-117",userData)
  return this.http.post(this.server + 'api/geo-fencing/get-user-inside-fence-or-not', userData)
}
}
