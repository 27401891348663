import {  NgModule } from '@angular/core';
import {  ExtraOptions, RouterModule, Routes} from '@angular/router';
import { AppComponent } from './app.component';
import { APP_BASE_HREF } from '@angular/common';
// import {
//   NbAuthComponent,
//   NbLoginComponent,
//   NbLogoutComponent,
//   NbRegisterComponent,
//   NbRequestPasswordComponent,
//   NbResetPasswordComponent,
// } from '@nebular/auth';

const routes: Routes = [{
    path: '',
    component: AppComponent,
    children: [

          {
            path: '',
            loadChildren: () => import('../app/authen/authen.module')
              .then(m => m.AuthenModule),
          },
          {
            path: '',
            loadChildren: () => import('../app/pages/pages.module')
              .then(m => m.PagesModule),
          }
        ]
}]

const config: ExtraOptions = {
  useHash: false,
};

// @NgModule({
//   imports: [RouterModule.forRoot(routes,{
//     useHash: true
//   })],
//   exports: [RouterModule],
//   providers: [
//     { provide: APP_BASE_HREF, useValue: '!' }
//   ]
// })
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
