import { Component, OnInit } from '@angular/core';
import{ GlobalConstants } from './global-constants';
//LODER
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(
    private SpinnerService: NgxSpinnerService,
  ){ 
    //this.SpinnerService.show(); 
  }

  ngOnInit(): void {
     //this.SpinnerService.hide();
  }
  
}
