import { Injectable } from "@angular/core";
import { TemplateComponent } from "../template/template.component";
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GlobalConstants } from "../../global-constants";
import { TemplateListComponent } from "../template/template-list/template-list.component";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  public server = GlobalConstants.apiURL;
  private _refeshneeded$ = new Subject<void>();
  // private _refeshneeded$ =new Subject<void>();
  constructor(private http: HttpClient) {}

  getrefeshneeded$() {
    return this._refeshneeded$;
  }

  getdata(): Observable<TemplateComponent[]> {
    // for Display data of categeries :
    let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    // this.createAuthorizationHeader();
    return this.http.get<TemplateComponent[]>(
      this.server + "api/categories/getAll"
    );
  }
  createtemplateUrl = this.server + "api/templates/createTemplate";
  addtemplate(values) {
    console.log(values);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.createtemplateUrl, values, { headers });
    //  .pipe(
    //    tap(() => {
    //      this._refeshneeded$.next();
    //     })
  }
  // display add category
  template(is_nps=false): Observable<any[]> {
    // for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get<any[]>(
      this.server + "api/categories/getAllcategoryTemplates?is_nps="+is_nps,
      {
        headers,
      }
    );
  }

  templatedatanbyid(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    console.log(headers);
    return this.http.get(this.server + "api/templates/get/" + id, { headers });
  }

  edittemplate(value) {
    // console.log(value);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(
      this.server + "api/templates/update/" + value.id,
      value,
      { headers }
    );
  }

  questiontemplateUrl = this.server + "api/questions/createQuestion";
  question_template(values) {
    console.log(values);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.questiontemplateUrl, values, { headers }).pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }
  //  }

  answertemplateUrl = this.server + "api/answer/createMultipleAnswer";
  answer_template(values) {
    console.log(values);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.answertemplateUrl, values, { headers }).pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }

  deletetemplete(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .delete(this.server + "api/templates/delete/" + id, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }
  question_get(id) {
    console.log();
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/templates/templateGetAllData/" + id,
      { headers }
    );
  }

  answerdata() {
    // console.log();
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/answer/getAll", { headers });
  }

  deletequestion(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .delete(this.server + "api/questions/delete/" + id, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }

  editquestion_template(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/questions/get/" + id, { headers });
    //   )
  }

  editanswer_template(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(
      this.server + "api/questions/getAnswersOfQuestions/" + id,
      { headers }
    );
  }

  filter_templete(id, is_nps = false) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/categories/getonecategoryTemplates/" +  id + "?is_nps=" + is_nps, { headers });
  }

  design_list() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/themeDesign/getAll", {
      headers
    })
    .pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }

  Insert_design(id, value) {
    //  console.log(value);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(this.server + "api/templates/update/" + id, value, {
      headers,
    });
  }

  template_theme(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/templates/get/" + id, { headers });
  }

  survey_Insert_design(id, value) {
    console.log(value);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(this.server + "api/survey-data/updateDesign/" + id, value, {
      headers
    })
    .pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }

  update_question_template(id, values) {
    // console.log(value);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(this.server + "api/questions/update/" + id, values, {
      headers,
    });
  }

  deleteanswer(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.delete(
      this.server + "api/answer/deleteAnswerOfQuestion/" + id,
      { headers }
    );
  }

  deletephotoanswer(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.delete(this.server + "api/answer/delete/" + id, {
      headers,
    });
  }

  Templatelist(): Observable<TemplateComponent[]> {
    // for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get<TemplateComponent[]>(
      this.server + "api/templates/getAllData",
      {
        headers,
      }
    );
  }


  TemplateCount(){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + `api/templates/get-all-template-count` ,{ headers });
   }

   //================== insert customised theme in db =======================================
   CustomiseThemeCreate(values){
    // let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server + `api/custom_theme/customisetheme`,values  ,);
   }
 
 
   getThemeBySurveyId(id){
    return this.http.get(this.server + "api/custom_theme/getThemeDataForSurveyid/" + id);
   }


   
}
