import { Component, OnInit, Input ,ViewChild} from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { GlobalConstants } from "../../global-constants";
import { loginservice } from "../authapi.service";
import { planservice } from '../../services/planapi.service';
import * as CryptoJS from "crypto-js";
import * as moment from "moment";
import { SignaturePad } from 'angular2-signaturepad'; 
import { Renderer2 } from '@angular/core';
//=======================================================  Service Import =========================================================
import { NbDialogRef, NbDialogService, NbToastrService } from "@nebular/theme"; 
//======================================================== Form Builder ====================================================
import { 
  FormControl,   
  FormGroup, 
  FormBuilder,
  Validators,   
  ValidatorFn,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Options } from "@angular-slider/ngx-slider";
import { SurveyService } from '../../pages/company/company-details/create-survey/survey.service';
import { TemplateService } from '../../pages/template/template.service';
import { CompanyService } from '../../pages/company/company.service';
import Swal from "sweetalert2";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";

@Component({
  selector: 'ngx-choosetheme',
  templateUrl: './choosetheme.component.html',
  styleUrls: ['./choosetheme.component.scss']
})
export class ChoosethemeComponent implements OnInit {
  @ViewChild(SignaturePad ,{ static : false}) signaturePad: SignaturePad;

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 700,
    'canvasHeight': 500,
    'backgroundColor': 'rgba(255, 255, 255, 1)',
  };
  isSelected:boolean;
  defaultThemeSelectedId:number;
  SurveyID :any
  isSurveyContinue = false;
  noSurvey: any; //No survey found title
  noSurveySub: any; // No survey found sub title
  optionValue = ""; //Default Selected Blank state
  public server = GlobalConstants.apiURL;
  public social_key_expire_minutes = GlobalConstants.social_key_expire_minutes;
  public totalResponseCountByWebSharingLink = GlobalConstants.totalResponseCountByWebSharingLink;
  public totalResponseCountByIpAddressWebSharingLink = GlobalConstants.totalResponseCountByIpAddressWebSharingLink;
  
  // survey theme variables
  SurveyIDs :any;
  designlist: any[];
  design_id = true; 
  designImage:any;
  selectedDesign:any;
  public web_domain = GlobalConstants.web_domain;
  // --------------------
  value: number = 0;
  options: Options = {
    floor: 0,
    ceil: 100,
    showTicksValues: true,
    tickStep: 10,
    step: 1,
  };

  stars: number[] = [1, 2, 3, 4, 5];
  NPSS: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  scales: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  Surveylist: any[];
  questiondisplay: any;
  count: any;
  i: any;
  show1 = false;
  show2 = false;
  public matrixOptionsAnswerRecords = [];
  radio: any[];
  customerdata: any;
  surveyanswerdata: any;
  itemdata: {};
  index: number;
  companyId: number;
  itemIds: any;
  loading = false; 
  public show: boolean = false;
  public buttonName: any = "NEXT";
  sur_sel = "";
  selectedValue: number;
  selected: number;
  opinionscale: any;
  submitted: boolean;
  userID = 1;
  public stepindex: number = 0;
  select = true;
  public checkbox_array: any[];
  dependentquestion: Object;
  public depent_id: any;
  public is_depent: any;
  thankyou_response: any[];
  wel_show2 = false;
  welcome_response: any;
  wel_show1 = true;
  upload: any;
  npsvalue: any;
  selectedindexx = 0;
  scalevalue: any;
  message_sucesss = false;
  message_welcome = false;
  selectedsurveyid: any;
  error_custom_form = false;
  error_custom_valid = false;
  valid_error: any;
  invalidfile = false;
  acceptedExtensions: string[];
  public imagePath;
  public message: string;
  imgURL: any;
  theme_name: any;
  company_name: any;
  res_id: void;
  survey_name: any;
  displayBlock = false;
  displayNone = false;
  noselectsurvey = true;
  selectsurveyy = false;
  selecteddd: any;
  language: string;
  userMsgData: any;
  errormessage: string;
  replacedkeyByDollerAlpha: string;
  surveyId: any;
  company_image: any;
  imageName: string | ArrayBuffer;
  websharedata: any;
  theme_class;
  image_bg: any;
  previousQuestionID: number;
  selectedNps: any;
  textarea_value: any;
  seletedImage: any;
  selectedYesNo: any;
  selectedmutlipleanswers: any;
  selectedPicure: any;
  selecteddropdownoption = 4431;
  surveyLength: any;
  totalQuestion: any;
  percentage: any;
  pvalue: any;
  selecteddropdownvalue: any;
  keyexpire = false;
  previuorsQuestionRecords = [];
  selectedcheckboxvalue: any;
  checked_value: any;
  selectedQuantity = "4473";
  record_data: any;
  previours_dependent_question_id = null;
  isRequiredCustomFields = false;
  surveyActive = false;
  en: any;
  hi: any;
  ar: any;
  languageBox = true;
  //==================================================== constructor ==================================================================
  constructor(
    private SurveyService: SurveyService,
    private TemplateService: TemplateService,
    private activatedRoute: ActivatedRoute,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    public router: Router,
    private fb: FormBuilder,
    private loginservice: loginservice,
    private SpinnerService: NgxSpinnerService ,// private activatedRoute: ActivatedRoute,
    private   service : planservice,
    private renderer: Renderer2
  //protected ref: NbDialogRef<SurveyPreviewComponent>, 
  ) {
   }

   renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
     script.type = 'text/javascript';
     script.src = src;
     script.async = true;
     script.defer = true;
     this.renderer.appendChild(document.body, script);
     var v = document.createElement("script");
     v.type = "text/javascript";
 //    v.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'ar',includedLanguages: 'en,hi,ml,mt,am,sq' }, 'google_translate_element'); } ";
       let url = `function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'ar',includedLanguages:'${ this.surveylanguages }' }, 'google_translate_element'); }`
       console.log(url,"urll");
       v.innerHTML = url;
 
     this.renderer.appendChild(document.body,v);
     return script;
   }
   renderExternalScriptOnComponentDestroy(src: string): HTMLScriptElement {
     const script = document.createElement('script');
     script.type = 'text/javascript';
     script.src = src;
     script.async = true;
     script.defer = true;
     this.renderer.appendChild(document.body, script);
     var v = document.createElement("script");
     v.type = "text/javascript";
     v.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'ar'}, 'google_translate_element'); } ";
     this.renderer.appendChild(document.body,v);
     return script;
   }

  // ======================================== End of  constructor ===========================================================================
  ngOnInit() {  
    $(':root').css('--bg-image', '');  
    $(':root').css('--question-color', '');
    $(':root').css('--answer-color', '');
    $(':root').css('--bg-color',  '');
    $(':root').css('--button-color',  '');
    $(':root').css('--button-text', '');
    $(':root').css('--answer-text', '');
    
    this.companyId = this.activatedRoute.snapshot.params.companyId;
    this.surveyId = this.activatedRoute.snapshot.params.surveyId;
   // this.renderExternalScript('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit').onload = () => { }
     this.websurveydata(this.companyId, this.surveyId);
    this.getAllThemeData();
     }

  // ======================================= Form Submit SurveylistForm ===============================================================================
  surveylistForm = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.minLength(3)]),
    question_id: new FormControl(""),
    mobile: new FormControl("", [
      Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
    ]),
    email: new FormControl("", [
      Validators.required,
      Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/),
    ]),
    address: new FormControl("", [Validators.required]),
    age: new FormControl("", [Validators.required]),
    gender: new FormControl("", [Validators.required]),
    country: new FormControl("", [Validators.required]),
    zip: new FormControl("", [Validators.required]),
    // image: new FormControl(''),
    city: new FormControl("", [Validators.required]),
    state: new FormControl("", [Validators.required]),
    role_id: new FormControl("5"),
    company_id: new FormControl(),
    option: new FormControl([], [Validators.required]),
  }); 
  public surveyFields = [];

  get f() {
    return this.surveylistForm.controls;
  }
  // ============================================ Surveylist Form ================================================
  selectedDate = "";
   previousQuestion(indexvalue, dependent_question_id ,is_mandatory, question_id, question_type_id) {
     if(this.previours_dependent_question_id != ''){ 
      let previousQuestion  =   this.getPrevioursQuestionId(this.previours_dependent_question_id);
      // console.log("previousQuestion dependent id",previousQuestion.question_index);
       this.selectedindexx =  previousQuestion.question_index;
       this.previours_dependent_question_id  ='';
      }else{
         let previousQuestion  =  this.getIndexPrevioursQuestion(indexvalue-1);
        this.selectedindexx =  previousQuestion.question_index;
       }
 
       let previous_question  = this.getPrevioursQuestionByNoParams();
       this.selectedindexx =  previous_question.question_index;
     
      let attemptedQuestionInfo = this.getAttemptedQuestion(previous_question.question.question_id);

    if(!attemptedQuestionInfo.questiontype_id){
      alert("not foundd");
       return;
    }
    if (attemptedQuestionInfo.questiontype_id == 8) {
      let dateString = attemptedQuestionInfo.answer;
      let date = moment(dateString).format("YYYY-MM-DD");
      this.selectedDate = date;
    } else if (attemptedQuestionInfo.questiontype_id == 4) {
      this.selectedNps = attemptedQuestionInfo.answer;
      this.npsvalue = this.selectedNps;
    } else if (attemptedQuestionInfo.questiontype_id == 3) {
      this.selectedValue = attemptedQuestionInfo.answer;
    } else if (attemptedQuestionInfo.questiontype_id == 14) {
      this.selectedYesNo = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 6) {
      this.textarea_value = attemptedQuestionInfo.answer;
    } else if (attemptedQuestionInfo.questiontype_id == 12) {
      this.seletedImage = attemptedQuestionInfo.answer;
    } else if (attemptedQuestionInfo.questiontype_id == 2) {
      this.scalevalue = attemptedQuestionInfo.answer;
    } else if (attemptedQuestionInfo.questiontype_id == 1) {
      this.selectedmutlipleanswers = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 11) {
      this.selectedPicure = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 5) {
      this.selecteddropdownoption = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 5) {
      this.selecteddropdownvalue = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 10) {
      this.selectedcheckboxvalue = attemptedQuestionInfo.answer_id;
    }
  }
 // ===================================== Next Question Functionality ===================================================
 nextQuestion(
  questiontypeid,
  depenttt_id,
  is_depenttt_id,
  is_mandotory,
  question_id,
  isSurveySubmit,selectedIndex
  ) {
    this.bad = false;
    this.average = false;
    this.good = false;

   if(questiontypeid == 1 || questiontypeid == 5 ||  questiontypeid == 11 || questiontypeid == 14 || questiontypeid == 18 ){
      if(this.depent_id  == null && this.is_depent == null || this.depent_id  == "null" && this.is_depent == "null"){
      if(this.questiondisplay.survey_data_questions.length - 1 == this.selectedindexx){
        //isSurveySubmit = true;
        this.submitForm();
      }else{
        this.selectedindexx = this.selectedindexx + 1;
       }   
      }
      if(this.depent_id  == 0 && this.is_depent == 1){
         this.submitForm();
      }
       if (this.depent_id != 0 && this.is_depent == 1) {
          var index = this.questiondisplay.survey_data_questions.findIndex(
           (p) => p.id == this.depent_id
         );
          this.selectedindexx = index;
        console.log(this.selectedindexx,"question logic");
        } 
        if(this.depent_id  == 0 && this.is_depent == 1){
          this.submitForm();
          }
          //==============for testing purpose addedd==============================
          if(this.depent_id  == '' && this.is_depent == ''){
            this.selectedindexx = this.selectedindexx + 1;
            console.log(this.selectedindexx,"insideee no logic ans");
             }
   }
 else {
    
  if(depenttt_id  == 0 && is_depenttt_id == 1){
    isSurveySubmit = true;
  }else if(depenttt_id != 0 &&  is_depenttt_id  == 1){
    let dependentQuestion = this.getQuestionByDependent(depenttt_id); 
    this.selectedindexx = dependentQuestion.question_index;
  }else if (depenttt_id  == null && is_depenttt_id  == null){
     if(this.questiondisplay.survey_data_questions.length - 1 == this.selectedindexx){
      isSurveySubmit = true;
    }else{
      this.selectedindexx = this.selectedindexx + 1;
       }
  }
  if (isSurveySubmit) { 
    this.submitForm();
  } 
 }
    
    // console.log(this.selectedindexx,"outsideee.....");
  //===================================  Logic Skip Question Check =======================================================================================

  this.recordPreviousQuestions(question_id,questiontypeid,depenttt_id,selectedIndex);
  this.previousQuestionID = question_id;
  this.errormessage = "";
  this.scalevalue = null;
  this.selectedValue = null;
  this.npsvalue = null; 
  // =================================== Condition For Custom form =========================================================================
}
 
 
 //===============================================websurveydata ========================================================
    theme_design_image :any;
   // design_image:any;
    isImagePath = false;
    themeclass :any;
    design_default_theme :any;
    surveylanguages :any;
  async   websurveydata(company_id, survey_id) {
      this.SpinnerService.show();
      this.loading = true;
    let data = await this.loginservice.web_survey_data(company_id, survey_id).subscribe((surveywebdata: any) => {
      this.websharedata = surveywebdata["data"];
      this.surveylanguages =  this.websharedata.survey.languages;
      if(this.websharedata.survey.languages == null || this.websharedata.survey.languages == "null"){
        this.surveylanguages =  'en';
      }
      else {
        this.surveylanguages =  this.websharedata.survey.languages;
         }
       console.log(this.surveylanguages);
      this.renderExternalScript('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit').onload = () => {
        console.log('Google API Script loaded');
          }
    //this.design_image = this.websharedata.survey.design_image;
      this.design_default_theme = this.websharedata.survey.design_id;
      this.defaultThemeSelectedId = this.websharedata.survey.design_id;
      ///================================================for custom theme ============================= 
     if(this.websharedata.survey.design_id == null ){
        this.isImagePath = false;
         this.themeclass = "custom";
        $('.theming__box').attr("data-theme", "custom");  
     this.color  =   this.websharedata.customtheme.question_color;
     this.color_1 = this.websharedata.customtheme.answer_color;
     this.color_2 =   this.websharedata.customtheme.bg_color;
     this.color_3=   this.websharedata.customtheme.button_bg;
     this.color_4 = this.websharedata.customtheme.button_text;
     this.color_5 =  this.websharedata.customtheme.answer_text;
     $(':root').css('--question-color',this.websharedata.customtheme.question_color);
     $(':root').css('--answer-color', this.websharedata.customtheme.answer_color);
     $(':root').css('--bg-color', this.websharedata.customtheme.bg_color);
     $(':root').css('--button-color',  this.websharedata.customtheme.button_bg);
     $(':root').css('--button-text', this.websharedata.customtheme.button_text);
     $(':root').css('--answer-text', this.websharedata.customtheme.answer_text);
     this.Imagenames = this.websharedata.customtheme.bg_image;
     if(this.websharedata.customtheme.bg_image){
      let imageurl  = this.server+this.websharedata.customtheme.bg_image;
      var themeBackground = 'url(' + imageurl + ')';
       $(':root').css('--bg-image', themeBackground);
     }
      else{
        this.videourl  = this.server+this.websharedata.customtheme.bg_media;
        }
       }
         else if (this.websharedata.survey.custom_id == null){
          this.isImagePath = false;
          this.themeclass = this.websharedata.Theme.design_class;
          this.image_bg = this.websharedata.Theme.design_image;
         }
      //  else{
      //     //  this.isImagePath = true;
      //       // this.theme_design_image = this.server + this.websharedata.survey.design_image;
      //     }
          this.surveyActive = false;
          if(surveywebdata["data"].survey.is_active == 0){
            this.SpinnerService.hide();
            this.wel_show2 = false;
            this.message_welcome = false;
            this.surveyActive = true;
            return;
           } 
          else{
            this.SpinnerService.hide();
            this.surveyActive = false;
       //  this.image_bg =this.websharedata.survey.design_image;
       if (!this.websharedata.welcome) {
             this.selectedsurveyid = this.surveyId;
             this.wel_show2 = false;
             this.message_welcome = true;
           } else {
             this.selectedsurveyid = this.surveyId;
             this.selecteddd = true;
             this.message_welcome = false;
             this.wel_show2 = true;
           }
         }
         }) ;
         error => {
          this.SpinnerService.hide();
         // console.log("response data error = ",error);
        }
    }
//======================================== To Start Survey  =====================================================================
  continue(id) {
      this.languageBox = false;
      this.wel_show2 = false;
      this.message_welcome = false;
      this.loginservice.question_get(id).subscribe((result: any[]) => {
      this.selecteddd = false;
      this.questiondisplay = result["data"];
   //   console.log('result["data"]',result["data"].survey_data_questions)
//=================================== New array without signature question type =======================================
      this.surveyLength = result["data"].survey_data_questions.length;
      this.percentage = 100 / parseInt(this.surveyLength);
      this.pvalue = this.percentage.toFixed(0);
      this.message_welcome == false;
      this.survey_name = result["data"]["survey_title"];
    });
    this.getFormField();
  }

  // ================================= For custom form fields ===================================================================
  getFormField() {
    this.loginservice.getSurveyFields(this.surveyId).subscribe((response) => {
      this.surveyFields = response["data"];
    });
  }

  surveyFieldsExists(fieldName) {
    return !!this.surveyFields.find((f) => f.field_name === fieldName);
  }
  // ===========  Image Uploading functionality  ========================================================================
  preview(files, questionid) {
    if (files.length === 0) return;
    var mimeType = files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.imgURL = "";
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    this.message = "";
    this.upload = files[0]; 
    this.imgURL = files[0].name;
    this.imageName = reader.result;
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.uploadfile(questionid);
    };
  }
  // ====================================== Upload Images =======================================================================
  uploadfile(questionid) {
    const formData = new FormData();
    formData.append("images", this.upload);
    this.loginservice.uploadfile(formData).subscribe(
      (response) => {
        const uploadfiledata = {
          questiontype_id: 14,
          question_id: questionid,
          answer_id: null,
          answer: response["path"],
          survey_id: this.surveyId,
        };
        this.attemptedQuestion(questionid, uploadfiledata);
      },
      (error) => console.log(error)
    );
  }

 //=====================================  Submission of Response ===============================================================
  submitForm() {
    this.SpinnerService.show();
    this.router.navigate(['./survey-question/' + this.surveyId])
    }
  //========================== Submission of Response ========================================================


  
//========================================== Emoji Question type ==================================================
  bad = false;
  average = false;
  good = false;

emojiAnswers(
  questiontype_id,
  questionid,
  answerid,
  answer,
  dependent_question_id,
  is_dependent,
  emojianswers
) {

  if(emojianswers == "bad"){
    console.log(this.bad,"baddd")
  this.bad = true;
  this.average = false;
  this.good = false;
  }
  else if(emojianswers == "average"){
    this.bad = false;
    this.average = true;
    this.good = false;
  }
  else {
    this.bad = false;
    this.average = false;
    this.good = true;
  }
 //===================== condition ends ============= 
  this.depent_id = dependent_question_id;
  this.is_depent = is_dependent;
  const dataa = {
    questiontype_id: questiontype_id,
    question_id: questionid,
    answer_id: answerid,
    answer: answer,
    survey_id: this.surveyId,
  };
  this.attemptedQuestion(questionid, dataa);
}
//================================================================================================================


  countStar(
    questionid,
    answer,
    star,
    nps,
    check,
    dependent_question_id,
    is_dependent,
    question_type_id
  ) {
    this.selectedValue = star;
    this.npsvalue = nps;
    this.depent_id = dependent_question_id;
    this.is_depent = is_dependent;
    this.scalevalue = check;
  }

  selectedanswer(
    question_type_id,
    questionid,
    answerid,
    answer,
    dependent_question_id,
    is_dependent
  ) {
    if(answer == ''){
     return;
    }
    this.selectedValue = 0;
     }

  //================================== Thank You Page functionality ====================================================
  ThanyouPage(surveyId) {
    this.SpinnerService.hide();
    this.selectedindexx = -1;
    this.loginservice.thankyou_survey(surveyId).subscribe(
      (response: any[]) => {
        if (response.length == 0) {
          this.message_sucesss = true;
          this.select = false;
          this.SpinnerService.hide();
        } else {
          this.thankyou_response = response[0];
          this.SpinnerService.hide();
          this.surveylistForm.reset();
          this.loading = false;
          this.select = false;
          this.show2 = true;
        }
      },
      (error) => console.log(error)
    );
  }

  attemptedQuestionsArray = [];
  getAttemptedQuestion(question_id) {
    let foundAttemptedQuestion = undefined;
    for (let i = 0; i < this.attemptedQuestionsArray.length; i++) {
      if (this.attemptedQuestionsArray[i].question_id == question_id) {
        foundAttemptedQuestion = this.attemptedQuestionsArray[i];
        break;
      }
    }
    return foundAttemptedQuestion;
  }

  attemptedQuestion(question_id, attemptedInfo) {
    if (this.attemptedQuestionsArray.length == 0) {
      this.attemptedQuestionsArray.push(attemptedInfo);
    }
    let isQuestionAttempted = false;
    for (let i = 0; i < this.attemptedQuestionsArray.length; i++) {
      if (this.attemptedQuestionsArray[i].question_id == question_id) {
        isQuestionAttempted = true;
        let attemptedQuestion = this.attemptedQuestionsArray[i];
        this.attemptedQuestionsArray[i].question_id = attemptedInfo.question_id;
        this.attemptedQuestionsArray[i].answer_id = attemptedInfo.answer_id;
        this.attemptedQuestionsArray[i].answer = attemptedInfo.answer;
      }
    }
  if (isQuestionAttempted == false) {
      this.attemptedQuestionsArray.push(attemptedInfo);
    }
  }
   dropdown_values(question_id, event) {
    let values = event.target.value.split(",");
    console.log(values,"values");
    this.is_depent = values[1];
    this.depent_id = values[2];
   
  }

  getPreviousQuestion(question_id, question_index) {
   let question = this.previuorsQuestionRecords.filter((q) => {
      if (q.depenttt_id == question_id) {
        return q;
      }
    });
     return question[0]; // this.previuorsQuestionRecords[index];
  }

  recordPreviousQuestions(question_id, questiontypeid, depenttt_id ,questionIndex) {
    this.record_data = {
      questiontype_id: questiontypeid,
      question_id: question_id,
      question_index: questionIndex,
      depenttt_id:depenttt_id
    };
    var removeindex = this.previuorsQuestionRecords.findIndex(
      (p) => p.question_id == question_id
    );
    if (removeindex == -1) {
      this.previuorsQuestionRecords.push(this.record_data);
    }
     console.log("final Recordss ",this.previuorsQuestionRecords);
  }

  // ======================================== For Next Question Logic =====================================================
    getQuestionByQuestionId(questionId){
     let index = this.questiondisplay.survey_data_questions.findIndex(
      (p) => p.id ==  questionId)
       return { question : this.questiondisplay.survey_data_questions[index], question_index: index} ;     
     }

     getQuestionByDependent(depent_id){
      let index = this.questiondisplay.survey_data_questions.findIndex(
       (p) => p.id ==  depent_id)
       return { question : this.questiondisplay.survey_data_questions[index], question_index: index} ;       
    }

    getQuestionByIndex(idx){ 
      let index = this.questiondisplay.survey_data_questions.findIndex(idx);
      return { question : this.questiondisplay.survey_data_questions[index], question_index: index} ;      
    }
  // ================================== Get Previours Question from Index value =============================================
      getIndexPrevioursQuestion(index1){
       let index = this.previuorsQuestionRecords.findIndex(
        (p) => p.question_index ==  index1);
       // console.log("index getIndexPrevioursQuestion  index " ,index);
        return { question : this.previuorsQuestionRecords[index], question_index: index} ; 
     }
  

     getPrevioursQuestionId(questionId){
      let index = this.previuorsQuestionRecords.findIndex(
       (p) => p.question_id ==  questionId)
     //  console.log("index getPrevioursQuestionId index " ,index);
       return { question :  this.previuorsQuestionRecords[index], question_index: index} ; 
       // console.log();
     }

     getPrevioursQuestionByNoParams(){
     // console.log("getPrevioursQuestionByNoParams pre = " ,this.previuorsQuestionRecords);
       let questionsSize = this.previuorsQuestionRecords.length;
       let previousQuestion = this.previuorsQuestionRecords[questionsSize-1];
    //   console.log("getPrevioursQuestionByNoParams question = " ,previousQuestion);
       this.previuorsQuestionRecords.pop();
     // console.log("getPrevioursQuestionByNoParams after pop() = " ,this.previuorsQuestionRecords);

       return { question :  previousQuestion, question_index : previousQuestion.question_index} ; 
     }

   // ========================================== For matrix implimentation ==========================================================
   getMatrixOptionsArray(matrix_option_str) {
    // console.log(matrix_option_str);
    let matrix_options = matrix_option_str.split("|");
   // console.log("matrix_options",matrix_options);
    return matrix_options;
    }


 //=====================================Slider Question Type===============================================
 slider(question_type_id,questionid,answer){ 
}

  //================================================= Refresh survey ===========================================================================
  reload(){
  window.location.reload()
 }

  //================================================End Of Code ============================================================
  
 //============================================= close the survey and Bakc to Questions page ================================================
 dismiss() {
    this.router.navigate(["./survey"])
  }
 //================================================  Signature Functionality  ============================================================
 drawComplete(question_type_id, questionid ) {
   const dataa = {
    questiontype_id: question_type_id,
    question_id: questionid,
    answer_id: null,
    answer: this.signaturePad.toDataURL(),
    survey_id: this.surveyId,
  };
  this.attemptedQuestion(questionid, dataa);
}
   drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }
  clearSignaturePad(){
    this.signaturePad.clear();
  }
  surveyQuestion(){
    this.router.navigate(['./survey'])
  }
  
   getAllThemeData(){
     this.TemplateService.design_list().subscribe((result: any[]) => {
     this.designlist = result;
     console.log(this.designlist);
   });
   (error) => {
     console.log("no data found");
   };
 } 

 themeSelect(value,img,design_class){  
  this.isSelected = true;
  this.defaultThemeSelectedId = value;
    this.selected = value;
    // this.image_bg = img;
    this.isImagePath = false;
    this.themeclass = design_class;
    this.image_bg =img;
   }
  savedefaulttheme(){
    var updatedata = {
      design_id: this.selected,
      design_image: null,
      custom_id : null       
     };
     this.TemplateService.survey_Insert_design(this.surveyId,updatedata).subscribe((result: any[]) => { 
     this.sucessmessage();
     this.router.navigate(['./survey-question/' + this.surveyId])
    });
  }
  sucessmessage(){
    Swal.fire({
      icon: "success",
      title: "Updated Sucessfully",
  })
  }  
  Imagenames :any;
  imagePaths:any;
  uploads :any;
  designImg :any;
  messagecustomimage :any;
  previewImage(files) { 
    this.Imagenames = files[0].name;
      if (files.length === 0)
       return;
    var mimeType = files[0].type;
     if (mimeType.match(/image\/*/) == null) {
       this.imgURL = '';
       this.messagecustomimage = "Only images are supported.";
       return;
     }
     var reader = new FileReader();
     this.imagePaths = files;
     reader.readAsDataURL(files[0]);
     this.message = '';
     this.uploads = files[0];
     this.color_2 = '';
     this.videouploads = '';
     this.videourl = '';
    reader.onload = (_event) => {
       this.designImg = reader.result;
       var themeBackground = 'url(' + this.designImg + ')';
       $('.theming__box').attr("data-theme", "custom");
        $(':root').css('--bg-image', themeBackground);
    }
    }

    uploadImagesfile() {
    const formData = new FormData();
    formData.append("images", this.uploads);
    this.loginservice.uploadfile(formData).subscribe(
      (response) => {
        console.log("image responsese ",response);
         var updatedata = {
          design_image : response["path"], 
          design_id: null,  
          custom_id :null
         };
       
       this.TemplateService.survey_Insert_design(this.surveyId,updatedata).subscribe((result: any[]) => { 
        });
       },
      (error) => console.log(error)
    );
  }
   color : any;
   color_1 :any;
   color_2 :any;
   color_3 :any;
   color_4 :any;
   color_5 :any;
   newColorStr:any;
   colorcode(color,type){ 
     if(this.color_2 != null || this.color_2 != ''){
      this.uploads = '';
      this.videourl  = '';
      this.videouploads  = '';
     }
     this.Imagenames == '';
    $('.theming__box').attr("data-theme", "custom");
      if(type == "question"){
       let rgbString = this.rgbToString(color);
       this.color = rgbString;
      $(':root').css('--question-color', this.color);
      return;
     }
     else if(type == "answer_color") {
      this.color_1 = this.rgbToString(this.color_1)
      $(':root').css('--answer-color', this.color_1 );
      return;
     } 
     else if(type == "background") {
      this.color_2 = this.rgbToString(this.color_2);
      $(':root').css('--bg-color', this.color_2);
      $(':root').css('--bg-image', '');
 
      return;
     }
     else if(type == "button_color") {
      this.color_3 = this.rgbToString(this.color_3)
      $(':root').css('--button-color',  this.color_3);
      return;
     }
     else if(type == "button_text") {
      this.color_4 = this.rgbToString(this.color_4);
      $(':root').css('--button-text', this.color_4);
      return;
     }
     else if(type == "answer_text") {
      this.color_5  = this.rgbToString(this.color_5);
      $(':root').css('--answer-text', this.color_5);
      return;
     }
   }

   rgbToString(selectedColor){
    let str = selectedColor;
    let indexOfFirstBracket = str.lastIndexOf("(")+1 ;
    let indexOfLastBracket = str.lastIndexOf(")"); 
    let newColorStr = str.slice(indexOfFirstBracket,indexOfLastBracket);
    return newColorStr
   }
    themeimage :any;
   isImageUploaded : boolean
   videouploads :any;

  async  savecustomisetheme(){
    let updatedata = {};
    if(this.videouploads){
    this.videoupload();
    return;
    }
    if(this.uploads){
      const formData = new FormData();
      formData.append("images", this.uploads);
   //   formData.append("images", this.videourl);
       let respnse = await  this.loginservice.uploadfile(formData).subscribe(
        (response) => {
          this.themeimage =  response["path"];
          this.color_2  = null;
          this.videouploads = null;
          var updatedata = {
            survey_id :    this.surveyId,
            question_color : this.color,
            answer_color : this.color_1,
            bg_color     : null,
            bg_image :     this.themeimage,
            button_bg : this.color_3,
            button_text :  this.color_4,
            answer_text :  this.color_5 ,
            bg_media : null
           };
           this.TemplateService.CustomiseThemeCreate(updatedata).subscribe((result: any[]) => { 
            this.sucessmessage();
            this.router.navigate(['./survey-question/' + this.surveyId])
        })
      });
       }
      else {
         if(this.color_2 == null){
          this.videouploads = null;
          this.videourl = null;
          let updatedata = {
            survey_id :    this.surveyId,
            question_color : this.color,
            answer_color : this.color_1,
            bg_color     : this.color_2,
            bg_image   :   this.Imagenames?this.Imagenames:null,
            button_bg : this.color_3,
            button_text :  this.color_4,
            answer_text :  this.color_5,
            bg_media : null
           };
           this.TemplateService.CustomiseThemeCreate(updatedata).subscribe((result: any[]) => { 
            this.sucessmessage();
            this.router.navigate(['./survey-question/' + this.surveyId])
        })
         }
         else if(this.color_2 != null){ 
          let updatedata = {
            survey_id :      this.surveyId,
            question_color : this.color,
            answer_color :   this.color_1,
            bg_color     :   this.color_2,
            bg_image   :     null,
            button_bg :      this.color_3,
            button_text :    this.color_4,
            answer_text :    this.color_5,
            bg_media : null
           };
           this.TemplateService.CustomiseThemeCreate(updatedata).subscribe((result: any[]) => { 
            this.sucessmessage();
            this.router.navigate(['./survey-question/' + this.surveyId])
        })
         }
        // }
        } 
    }
   customisetab(){
    if(this.design_default_theme == null){
    $('.theming__box').attr("data-theme", "custom");
    }
 
   }
 // =================================== Redirection of last page ===========================================================================
  surveyQuestionpageNavigation(){
    this.router.navigate(['./survey-question/' + this.surveyId])
  }
 
  activeTheme: boolean = false;
  toggleTheme(){
    this.activeTheme = !this.activeTheme;
  }

  videourl;
  format;

  onSelectFile(event) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      this.videouploads = file; 
      }
     if(file.type.indexOf('video')> -1){
       //  this.format = 'video';
          reader.onload = (event) => {
       this.videourl = (<FileReader>event.target).result;
         // console.log( this.videourl);
       }
      }
      else if(file.type.indexOf('image')> -1){
        this.format = 'image';
        alert('image not accepted');
        this.videouploads = ''; 
      }
      
    }
    async videoupload(){
      const formData = new FormData();
    // formData.append("images", this.uploads);
      formData.append("images", this.videouploads);
       let respnse = await  this.loginservice.uploadfile(formData).subscribe(
        (response) => {
          var themevideo =  response["path"];
          var updatedata = {
            survey_id :    this.surveyId,
            question_color : this.color,
            answer_color : this.color_1,
            bg_color     : null,
            bg_image :     null,
            button_bg : this.color_3,
            button_text :  this.color_4,
            answer_text :  this.color_5,
            bg_media : themevideo
           };
           this.TemplateService.CustomiseThemeCreate(updatedata).subscribe((result: any[]) => { 
            this.sucessmessage();
            this.router.navigate(['./survey-question/' + this.surveyId])
        })
      });
    }
  
  
  }




