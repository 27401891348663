import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, pipe, Observer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalConstants } from '../../../../global-constants';
import { UsersComponent } from './users.component'

@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
 })

export class UserService {

  constructor(
    private http: HttpClient,
  ) { }
  public server = GlobalConstants.apiURL;
  private _refeshneeded$ = new Subject<void>();

  getrefeshneeded$() {
    return this._refeshneeded$;
  }
  //    service_list(){
  //      console.log(); //for display data of
  //       let headers: HttpHeaders = new HttpHeaders();
  //       headers = headers.append("x-access-token", sessionStorage.getItem('token'));
  //       console.log(headers);
  //      return this.http.get( this.server +'api/registrations/getAll',
  //      {
  //    headers
  //     });
  // }
  service_list(): Observable<UsersComponent[]> { //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get<UsersComponent[]>(this.server + "api/registrations/getAll", {
      headers
    });
  }
  //========================================================================================================
  getAllUserListOfComapnyByCompanyId(companyId){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get(this.server + "api/v2/registrations/users/company/"+companyId, {headers});
  }
  //========================================================================================================
  updateUserActiveOrInactiveStatus(userId){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.put(this.server + "api/registrations/active-inactive/user/"+userId,{}, {headers});
  }
  //========================================================================================================
  clearDeviceID(userId){
    return this.http.post(this.server + "api/auth/clearDevice/"+userId,{});
  }
  //========================================================================================================
  //========================================================================================================
  //========================================================================================================
  getCompanyUsersCountByCompanyId(companyId): Observable<UsersComponent[]> { //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get<UsersComponent[]>(this.server + "api/registrations/user-count/company/"+companyId, {
      headers
    });

  }

  user_month(company_id): Observable<UsersComponent[]> { //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get<UsersComponent[]>(this.server + "api/registrations/getuserMonthData/" + company_id, {
      headers
    });

  }

  customer_month(company_id): Observable<UsersComponent[]> { //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get<UsersComponent[]>(this.server + "api/registrations/getcustomerMonthData/" + company_id, {
      headers
    });

  }

  deleteUser(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.delete(this.server + 'api/registrations/delete/' + id,
      { headers }
    )
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })

      );
  }
  editservices(id): Observable<UsersComponent[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get<UsersComponent[]>(this.server + 'api/registrations/get/' + id,
      { headers })
  }

  //   postFile(fileToUpload: File){
  //     let headers: HttpHeaders = new HttpHeaders();
  //     headers = headers.append("x-access-token", sessionStorage.getItem('token'));
  //     const endpoint = '"../../../../assets/upload/"';
  //     const formData: FormData = new FormData();
  //     formData.append('fileKey', fileToUpload);
  //      return this.http.post(endpoint, formData, { headers: headers })
  //    // return  this.http.get<UsersComponent[]>(this.server +'api/registrations/get/'+id,
  //  //   { headers })
  //      }

  //   .map(() => { return true;
  //  })
  // .catch((e) => this.handleError(e)
  //  );
  //   }
  // edit functionaliy
  edituser_model(values,id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.put(this.server + 'api/registrations/update/' + id, values,{ headers }).pipe(
    // return this.http.post(this.server + 'api/v2/registrations/update-user-information/' + id, values,{ headers }).pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }
  chnagePasswordForUSer(values,id){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.post(this.server + 'api/v2/registrations/update-user-password/' + id, values,{ headers }).pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }
  _loginUrl = this.server + "api/auth/company/user/signup";
  addusers(value) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.post(this._loginUrl, value, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })

      );
  }


  addusersignup(value) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.post(this.server +  "api/auth/company/create-user-signup", value, { headers })
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })

      );
  }



  ///
  addUserBySuperAdmin(value) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.post(this.server + "api/auth/company/user/addUserBySuperAdmin", value, { headers })
      .pipe(tap(() => {this._refeshneeded$.next()})
      );
  }
}

//}
