import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CompanyComponent } from "./company.component";
import { of, pipe, Observer } from "rxjs";
import { tap } from "rxjs/operators";
import { GlobalConstants } from "../../global-constants";
import {CreateSurveyComponent} from './company-details/create-survey/create-survey.component';

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  constructor(private http: HttpClient) {}
  public server = GlobalConstants.apiURL;
  private _refeshneeded$ = new Subject<void>();

  getrefeshneeded$() {
    return this._refeshneeded$;
  }

  emailcheck(company_email) {
    return this.http.get(
      this.server + `api/registrations/findemail/` + company_email
    );
  }

  // display add category
  displaycategory(): Observable<CompanyComponent[]> {
    //for display data of
    //  let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get<CompanyComponent[]>(
      this.server + "api/categories/getAll"
    );
  }

  Companylist(): Observable<CompanyComponent[]> {
    //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get<CompanyComponent[]>(
      this.server + 'api/companies/getAllCompanyInfo',{headers}
    );
  }
  //==============================================================================================
  totalCompanylist(){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get<CompanyComponent[]>(
      this.server + 'api/v2/sadmin/companies',{headers});
  }
  //==============================================================================================
  totalTenSurveyResponseCompanylist(){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get<CompanyComponent[]>(
      this.server + 'api/v2/sadmin/surveyresponsecompanies',{headers});
  }
    //==============================================================================================
    getCompanyDetails(companyID){
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append("x-access-token", sessionStorage.getItem('token'));
      return this.http.get<CompanyComponent[]>(
        this.server + 'api/v2/sadmin/company-details/'+ companyID,{headers});
    }
    //==============================================================================================
  //  let headers: HttpHeaders = new HttpHeaders();
  //  headers = headers.append("x-access-token", sessionStorage.getItem('token'));
  //    return this.http.get("http://localhost:8082/api/categories/getAll", { headers })
  //  .pipe(
  //    tap(() => {
  //      this._refeshneeded$.next();
  //     })

  //   );
  // }

  //for adding a company calling from add-company-dialog componentns
  _createcompanyUrl = this.server + "api/auth/company/signup";
  addCompany(value) {
    let headers: HttpHeaders = new HttpHeaders();
    //  headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.post(this._createcompanyUrl, value, { headers });
    //  .pipe(
    //    tap(() => {
    //      this._refeshneeded$.next();
    //     })
  }

  _registrationUrl = this.server + "api/registrations/create";
  registrationdata(value) {
    //
    let headers: HttpHeaders = new HttpHeaders();
    //  headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.post(this._registrationUrl, value, { headers }).pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }

  // end of add company
  // company list api
  // _getcategoriesUrl = "/api/categories/get/ +:id";
  //  category_list(cat_ids){
  //
  //  let headers: HttpHeaders = new HttpHeaders();
  //  headers = headers.append("x-access-token", sessionStorage.getItem('token'));
  //   return this.http.get('http://localhost:8082/api/categories/get/' +cat_ids, { headers });

  category_list(id) {
    //  //for display data of
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    //
    return this.http.get(this.server + "api/categories/get/" + id, {
      headers,
    });
  }

  public_profile(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/companies/get/" + id, {
      headers,
    });
  }

  Account(): Observable<any[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    //
    return this.http.get<[]>(this.server + "api/registrations/getAll", {
      headers,
    });
  }

  profile_update(values, id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(this.server + "api/companies/update/" + id, values, {
      headers,
    });
  }
  //====================================================================================================
  tyMessageUpdate(msgData,companyId){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(this.server + "api/companies/ty-message-update/" + companyId, msgData, {headers}).pipe(
          tap(() => {
            this._refeshneeded$.next();
           })
    
         );;
  }
  //=====================================================================================================
  account_update(values) {
    //
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(
      this.server + "api/registrations/update/" + values.id,
      values,
      { headers }
    );
  }

  uploadfile(formData) {
    console.log("servicefileee", formData);
    return this.http.post(this.server + "api/uploadfile", formData);
  }

  multiuploadfile(formData) {
    console.log("servicefileee", formData);
    return this.http.post(this.server + "api/multipleUploadFiles", formData);
  }

  deleteCompanylist(id, value): Observable<CompanyComponent[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .put<CompanyComponent[]>(
        this.server + "api/companies/companydelete/" + id,
        value,
        { headers }
      )
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }

  deleteCompanyuser(id, value): Observable<CompanyComponent[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http
      .put<CompanyComponent[]>(
        this.server + "api/registrations/companyupdate/" + id,
        value,
        { headers }
      )
      .pipe(
        tap(() => {
          this._refeshneeded$.next();
        })
      );
  }

  Question_type_list(id) {
    return this.http.get(
      this.server + "api/survey-data-questions/getallquestionlist/" + id
    );
  }

  multiple_type_list(id){
    return this.http.get(
      this.server + "api/survey-data-customer-response/getAllmultiplechoice/" + id
    );
  }

  multipleLabels(id){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/survey-data-questions/getAnswersOfQuestions/" + id , {headers});
  }

  multiple_type_between_dates(questionId?, startDate?, endDate?): Observable<CreateSurveyComponent[]> {
    return this.http.get<CreateSurveyComponent[]>
    (`${this.server}api/survey-data-customer-response/question/${questionId}/date/${startDate}/${endDate}`);
  }

  matrix_type_between_dates(questionId?, startDate?, endDate?): Observable<CreateSurveyComponent[]> {
    return this.http.get<CreateSurveyComponent[]>
    (`${this.server}api/survey-data-customer-response/question/matrixType/${questionId}/date/${startDate}/${endDate}`);
  }

  multipleRatingBetweenDates(questionId?, startDate?, endDate?): Observable<CreateSurveyComponent[]> {
    return this.http.get<CreateSurveyComponent[]>
    (`${this.server}api/survey-data-customer-response/question/multipleRating/${questionId}/date/${startDate}/${endDate}`);
  }

  multipleRatingResponseWithFilter(filterData): Observable<CreateSurveyComponent[]> {
    return this.http.post<CreateSurveyComponent[]>
    (`${this.server}api/survey-data-customer-response/question/multipleRatingResponseWithFilter`,filterData);
  }

  matrix_response(questionId?): Observable<CreateSurveyComponent[]> {
    return this.http.get<CreateSurveyComponent[]>
    (`${this.server}api/survey-data-customer-response/question/matrixType/${questionId}`);
  }

  matrixResponseWithFilters(filterData) {
    return this.http.post(`${this.server}api/survey-data-customer-response/matrixResponseWithFilters`, filterData,{ });
  }

 //--------------------API to send messgae to user on survey completion--------------------------------

 sendMsgOnSurveyCompletion(msg_data) {
  return this.http.post(this.server + `api/survey-data/send-msg-to-user-on-survey-completion/`,msg_data);
}


//----------------------------------- API --------------------------------------
getTotalResponseCountOFSurvey(id){
  return this.http.get(this.server + `api/survey-data-customer-response/response-count-by-survey-id/`+id);
 }
 //=============================================== Country Code Api =====================================================
 countryCode(){
   //http://api.countrylayer.com/v2/all?access_key=74ded9383ac520f5d5304324dbdf8d35
  return this.http.get(`http://api.countrylayer.com/v2/all?access_key=74ded9383ac520f5d5304324dbdf8d35`);
   }

   getCurrentIPDetails(ip){
    return this.http.get("http://www.geoplugin.net/json.gp?ip="+ip+"");
   }
   getCurrentLocationDetails(){
    return this.http.get(`http://www.geoplugin.net/json.gp`);
   }
  
//=============================================================================================
_getAllActiveCompaniesList(){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(this.server + "api/v2/companies/get-all-active-inactive-companies", {headers});
}
//=============================================================================================
_getAllPaidCompaniesList(){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token"));
  return this.http.get(this.server + "api/v2/companies/get-all-paid-companies", {headers});
}

}