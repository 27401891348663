
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbToastrService,
} from "@nebular/theme";
import { Router } from "@angular/router";
import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SocketService } from "../../../@core/services/socket.service";
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { planservice } from '../../../services/planapi.service';
import { isNgTemplate } from "@angular/compiler";
import {Renderer2} from '@angular/core';
declare const require: any;
declare var $;

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  
  mybutton;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userdata: any;
  allNotifications: any;
  notificationLength: any;
  allNotificationCount = 0;
  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  currentTheme = "default";


  userMenu = [
    //  { title: 'Profile' },
    { title: "Log out", link: "/login" },
  ];
  selectedlanguage: any;
  isActivePlanFree = false;
  adminRole = false;
  activePlanDetails: any;
  lang_superadmin: string;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private planservice: planservice,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private router: Router,
    private breakpointService: NbMediaBreakpointsService,
    private socketService: SocketService,
    private toastrService: NbToastrService,
    private translateService: TranslateService,
    private renderer: Renderer2
    // private ref : ElementRef
  ) { }
  showHeader = false;
  companyID: any;

  renderExternalScriptOnComponentLoad(src: string): HTMLScriptElement {
    // const script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.src = src;
    // script.async = true;
    // script.defer = true;
    // this.renderer.appendChild(document.body, script);
    // return script;
    let translateElementScript = document.createElement("script");
    translateElementScript.type = "text/javascript";
    translateElementScript.src = src;
    translateElementScript.async = true;
    translateElementScript.defer = true;
    translateElementScript.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element'); } ";
    console.log('Google API Script loaded with all languages');
    this.renderer.appendChild(document.body,translateElementScript);
    return translateElementScript;
  }


  ngOnInit() {
    this.renderExternalScriptOnComponentLoad('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit').onload = () => {
      // do something with this library
    }
    this.userdata = sessionStorage.getItem("userData");
    this.companyID = JSON.parse(this.userdata).company_id;
    if (JSON.parse(this.userdata).role_id == 1) {
      this.adminRole = true;
      this.lang_superadmin = 'en';
      this.translateService.use(this.lang_superadmin);
    }
    this.selectedlanguage = sessionStorage.getItem('language');
    this.switchLanguage(this.selectedlanguage);
    this.preLoadedData();  

    //Get the button
    this.mybutton = document.getElementById("myBtn");
    // When the user scrolls down 20px from the top of the document, show the button
    
    window.onscroll = function () { 
      scrollFunction() 
    };
    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            this.mybutton.style.display = "block";
        } else {
            this.mybutton.style.display = "none";
        }
    };

  }
// function for scroll on top
  topFunction() {
    alert("Clicked on this button")
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  preLoadedData() {
    /**
    * @for socketService call
    * @for socket
    */
   
    this.socketService.initSocket();
    // this.socketService.onEvent("getNotificationSuperAdmin").subscribe((response) => {
    //   this.socketService.setNotifications(response);
    //  // try {
    //  //  if (this.socketService.userData.id === response.to_id) {
    //  //     this.showToast(response.notification_msg, "top-right");
    //  //   }
    //  // } catch (error) { }
    // });

    if (JSON.parse(this.userdata).role_id == 1) {
      this.allNotifications = []
      this.socketService.onEvent("getNotificationSuperAdmin").subscribe((response) => {
        this.allNotificationCount = 0;
        this.allNotifications = []
        for (let item of response) {
          if (item.is_readStatus == 0) {
            this.allNotificationCount++;
            this.allNotifications.push(item)
          }
        }
        this.notificationLength = this.allNotifications.length;
        //this.socketService.setNotifications(response, true);
      });
      // setTimeout(() => {
      // this.socketService.emitEvent("getNotificationSuperAdmin", {});
      // console.log("Set Timeout Running");
      //  }, 500);
    } else if (JSON.parse(this.userdata).role_id == 2) {
      this.socketService.onEvent("onCompanyDelete").subscribe((response)=> {
        console.log(response);
        if(response == this.companyID){this.logout();}
      })

      this.socketService.onEvent("getNotification").subscribe((response) => {
        if (response.length != 0) {
          if(response[0].company_id == this.companyID){
            this.allNotificationCount = 0;
            this.allNotifications = []
            for (let item of response) {
              if (item.is_readStatus == 0) {
                this.allNotificationCount++;
                this.allNotifications.push(item)
              }
            }
            this.notificationLength = this.allNotifications.length;
          } else {
            this.allNotifications = this.allNotifications;
            this.notificationLength = this.allNotifications.length;
          }
        } else {
          this.notificationLength = 0;
            this.allNotifications = []
        }
        // this.socketService.setNotifications(response, true);
      });

      // setTimeout(() => {
      // this.socketService.emitEvent("getNotification", {});
      //  }, 500);
    }

    this.currentTheme = this.themeService.currentTheme;

    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.admin));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

  }


  upgrade_btn() {
    this.router.navigate(["./plan-pricing"]);
  }
 
  toggle() {
      this.showHeader = !this.showHeader;
  }

  changeReadStatus() {
     this.allNotificationCount = 0;
    let company_ID;
    if (JSON.parse(this.userdata).role_id == 1) {
      company_ID = 1;
    } else if (JSON.parse(this.userdata).role_id == 2) {
      company_ID = this.companyID;
    }
    this.socketService.updateReadStatus(company_ID).subscribe((data:any) => {});
    
  }

  get notifications() {
    return this.socketService.notifications;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    // this.menuService.navigateHome();
    // return false;
    this.router.navigate(["./dashboard"]);

  }

  allNotificationNavigate(){
    this.router.navigate(["./all-notification"]);
  }

  async logout() {
    this.userdata = sessionStorage.getItem("userData");
    if (JSON.parse(this.userdata).role_id == 2) {
      await this.planservice.updateLogoutDeatailsOfCompany(this.companyID).subscribe((data: any) => {
      });
     // window.location.href = './';
       this.router.navigate(["./login"]);

    } else {
      await this.planservice.updateLogoutDeatailsOfSuperAdmin().subscribe((data: any) => {
      });
      this.router.navigate(["./adminlogin"]);
    }
    sessionStorage.clear();
    localStorage.clear();

    return false;
  }



  showToast(msg, position) {
    this.toastrService.show(status || "Success", msg, {
      position,
      status: "info",
    });
  }
  switchLanguage(language) {
    this.selectedlanguage = language;
    this.translateService.use(language);
    sessionStorage.setItem("language", language);
  }

  upgradeToFree() {
    let userdata = sessionStorage.getItem("userData");
    let company_id = JSON.parse(userdata).company_id;
    let data = {
      planId: 1,
      companyId: company_id,
    }
    this.planservice.freeAndTrialPlan(data).subscribe((data: any) => {
      // console.log('Free And Trial Plan:', data.data)
      this.isActivePlanFree = true;
      });
      (error) => {
        console.log('API Error',error);
      };
   }
   
  ngAfterViewInit() {
    //We loading the player script on after view is loaded
    require('../../../../assets/translate.js');
  }


  languageBarShow:boolean = false;
  showLanguageTransaltorBar(){
    this.languageBarShow = true;
    $("#google_translate_element").click(function(e){
      e.stopPropagation();
   });

  }
  }







