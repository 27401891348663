import { Injectable } from "@angular/core";
import * as socketIo from "socket.io-client";
import { GlobalConstants } from "../../global-constants";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SocketService {
  public socket;
  public SERVER_URL = GlobalConstants.apiURL;
  public notifications = [];

  constructor(private http: HttpClient) {}

  public initSocket(): void {
    this.socket = socketIo(this.SERVER_URL, {
      query: { token: localStorage.token },
    });
  }

  public emitEvent(action, data) {
    this.socket.emit(action, data);
  }

  public onEvent(action) {
    return new Observable<any>((observer) => {
      this.socket.on(action, (data) => observer.next(data));
      this.socket.emit(action, {});
    });
  }

  setNotifications(notification, all = false) {
    if (all) {
      notification.forEach((not) => {
        this.setNotifications(not);
      });
    } else {
      if (this.userData.id === notification.to_id) {
        this.notifications = this.getUpdatedValue(
          this.notifications,
          notification
        );
      }
    }
    this.sort();
  }

  updateReadStatus(id){
    return this.http.post(this.SERVER_URL + "api/notifications/changeNotificationReadStatus/"+ id, {});
  }
  
  updateReadStatusSuperAdminDash(id){
    return this.http.post(this.SERVER_URL + "api/notifications/changeNotificationReadStatusSuperAdminDash/"+ id, {});
  }

  deleteSingleNotification(id) {
    return this.http.delete(this.SERVER_URL + "api/notifications/deleteSingleNotification/" + id, { });
  }

  deleteAllNotifications(companyID) {
    return this.http.delete(this.SERVER_URL + "api/notifications/deleteAllNotification/"+companyID, { });
  }

  sort() {
    this.notifications.sort((a, b) => {
      return (
        new Date(b["createdAt"]).valueOf() - new Date(a["createdAt"]).valueOf()
      );
    });
  }
  get userData() {
    try {
      return JSON.parse(sessionStorage.userData);
    } catch (error) {
      console.log("error: ", error);
      return {};
    }
  }

  getUpdatedValue(arr, obj) {
    let values = arr.slice();
    let hasObj = false;
    values = values.map((val) => {
      if (val.id === obj.id) {
        val = obj;
        hasObj = true;
      }
      return val;
    });
    if (!hasObj) values.push(obj);
    return values;
  }
}
