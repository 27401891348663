import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModelDeletetempleteComponent } from "./model-deletetemplete.component";
import { NbSpinnerModule } from "@nebular/theme";

@NgModule({
  declarations: [ModelDeletetempleteComponent],
  imports: [CommonModule, NbSpinnerModule],
  exports: [ModelDeletetempleteComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalDeleteTemplateModule {}
