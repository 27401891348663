import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { loginservice } from '../authapi.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'ngx-n-otp',
  templateUrl: './n-otp.component.html',
  styleUrls: ['./n-otp.component.scss']
})
export class NOtpComponent implements OnInit {
  ResponseResetForm: FormGroup;
  errorMessage: string;
  successMessage: string;
  user_secret: null;
  CurrentState: any;
  IsResetFormValid = true;
  edited = false;
  selectedlanguage: string;
  fieldTextType: any;
  fieldTextType2: any;

  constructor(private router:Router,
      private authService: loginservice,
      private route: ActivatedRoute,
      private fb: FormBuilder ,
      private translateService: TranslateService,
    ) { 
      this.ResponseResetForm = this.fb.group(
        {
          user_secret: [this.user_secret],
          n_psw: ['', [Validators.required, Validators.minLength(6)]],
          confirm_password: ['', [Validators.required]],
        },
        {
          validator: this.ConfirmedValidator('n_psw', 'confirm_password')
        }
      );
    }

  ngOnInit() {
    this.switchLanguage('en') 
  }

  get f() {
    return this.ResponseResetForm.controls;
  }


  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  // Validate(passwordFormGroup: FormGroup) {
  //   const new_password = passwordFormGroup.controls.newPassword.value;
  //   const confirm_password = passwordFormGroup.controls.confirmPassword.value;

  //   if (confirm_password.length <= 0) {
  //     return null;
  //   }

  //   if (confirm_password !== new_password) {
  //     return {
  //       doesNotMatch: true
  //     };
  //   }

  //   return null;
  // }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }



  ResetPassword(form) {
    if (form.valid) {
      this.IsResetFormValid = true;
      this.authService.resetPassword({
        n_psw: this.ResponseResetForm.value.n_psw,
        user_secret: this.route.snapshot.params.token,
      }).subscribe(
        data => {
          this.ResponseResetForm.reset();
     // this.edited_fail = false;
       this.edited = true;
      setTimeout(()=>{                           //<<<---using ()=> syntax
       this.edited = false;
       this.router.navigate(['login']);
       }, 2000);
          // this.successMessage = data;
          //  setTimeout(() => {
          //   this.successMessage = null;
          //   
          // }, 3000);
        },  
        err => {  
          if (err.error.message) {
            this.errorMessage = err.error.message;
          }
        }
      );
    } else { this.IsResetFormValid = false; }
  }


  switchLanguage(language: string) {
    this.selectedlanguage = language;
    sessionStorage.setItem('language', language);
    this.translateService.use(language);
   } 
   
}

