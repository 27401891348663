import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../pages/category/category.service';
import { CompanyService } from '../../pages/company/company.service';
import { SurveyService } from '../../pages/company/company-details/create-survey/survey.service';
import { UserService } from '../../pages/company/company-details/users/user.service';
import { TemplateService } from '../../pages/template/template.service';
import { NgxSpinnerService } from "ngx-spinner";
import { loginservice } from '../authapi.service';
import { CvDashboardService } from '../../pages/cv-dashboard/cv-dashboard.service';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { Subscription, timer } from 'rxjs';
import { map, share, takeUntil } from 'rxjs/operators'
import { SocketService } from '../../@core/services/socket.service';
import { planservice } from '../../services/planapi.service';
import { Subject } from "rxjs";
import Speech from 'speak-tts';

import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbToastrService,
} from "@nebular/theme";
import { UserData } from '../../@core/data/users';

declare var $;

@Component({
  selector: 'ngx-public-dashboard-superadmin',
  templateUrl: './public-dashboard-superadmin.component.html',
  styleUrls: ['./public-dashboard-superadmin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PublicDashboardSuperadminComponent implements OnInit, OnDestroy {
  categorylistt: any;
  companylists: any;
  templatelists: any;
  createsurey: any;
  Userlist: any;
  count = '';
  userdata: any;
  public parentID: string;
  companydata: any[];
  surveyresponsecompanydata: any[];
  templatedata: any[];
  sureydata: any[];
  companyListDropdownSettings;
  showCompanyData = false;
  companyDataCount: any;
  page: number = 1;
  p: number = 1;

  
  time = new Date();
  rxTime = new Date();
  intervalId: any;
  subscription: Subscription;
  browserDetails:any;
  
  companyID: any;
  
  userPictureOnly: boolean = false;
  
  allNotifications: any;
  notificationLength: any;
  allNotificationCount = 0;
  
  user: any;
  audio: any;
  
  private destroy$: Subject<void> = new Subject<void>();
  currentTheme = "default";

  speech: any;
  speechData: any;

  constructor(
    private router: Router,
    activatedRoute: ActivatedRoute,
    private CategoryService: CategoryService,
    private CompanyService: CompanyService,
    private TemplateService: TemplateService,
    private SurveyService: SurveyService,
    private themeService: NbThemeService,
    private socketService: SocketService,
    private planservice: planservice,    
    private breakpointService: NbMediaBreakpointsService,
    private UserService: UserService,
    private userService: UserData,
    private Loginservice: loginservice,
    private CvDashboardService: CvDashboardService,
    private SpinnerService: NgxSpinnerService,
  ) {
    this.parentID = "";
    this.parentID = activatedRoute.parent.snapshot.paramMap.get("id");


    this.speech = new Speech() // will throw an exception if not browser supported
    if(this.speech.hasBrowserSupport()) { // returns a boolean
        console.log("speech synthesis supported");

        this.speech.init({
          'volume': 1,
          'lang': 'en-UK',
          'rate': 1,
          'pitch': 1,
          'voice':'Google UK English Female',
          'splitSentences': true,
          'listeners': {
              'onvoiceschanged': (voices) => {
                  console.log("Event voiceschanged", voices)
              }
                  }
          }).then((data) => {
              // The "data" object contains the list of available voices and the voice synthesis params
              console.log("Speech is ready, voices are available", data)
              this.speechData = data;
              data.voices.forEach(voice => {
                  console.log(voice.name + " "+ voice.lang)
                  });
              }).catch(e => {
                  console.error("An error occured while initializing : ", e)
              });
    } else {
      alert("Speech synthesis does not supported in your browser.");
    }

  }


  ngOnDestroy(): void {
    //throw new Error('Method not implemented.');
    clearInterval(this.intervalId);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.userdata = sessionStorage.getItem('userData');
    this.companyID = JSON.parse(this.userdata).company_id;
    if (!this.userdata) {
      this.router.navigate(['./adminlogin']);
    }
    this.Loginservice.verifyLoginToken('/adminlogin');

    
      
    // this.browserDetails = this.myBrowser();
    // this.autologin();

    this.SpinnerService.show();
    
    this.responsesurveycompanylist();
    this.planPopularityChart();

    //this.categoryList();
    //this.templateLists();
    this.userlist();
    this.surveylist();
    setInterval(() => {
      this.companylist();
      this.responsesurveycompanylist();
      this.getDashboardData();
    },5000);
    this.demoBookingsChart('allBookings');
    this.preLoadedData();

    setTimeout(() => {
      this.SpinnerService.hide();
    }, 1000);

    // Company List Dropdown Settings
    this.companyListDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'company_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Select All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    // Using Basic Interval
    this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1000);

    // Using RxJS Timer
    this.subscription = timer(0, 1000).pipe(map(() => new Date()), share()).subscribe(time => {
      this.rxTime = time;
    });
  }

  preLoadedData() {
    /**
    * @for socketService call
    * @for socket
    */
   
    this.socketService.initSocket();
    // this.socketService.onEvent("getNotificationSuperAdmin").subscribe((response) => {
    //   this.socketService.setNotifications(response);
    //  // try {
    //  //  if (this.socketService.userData.id === response.to_id) {
    //  //     this.showToast(response.notification_msg, "top-right");
    //  //   }
    //  // } catch (error) { }
    // });

    if (JSON.parse(this.userdata).role_id == 1) {
      // alert("before notification");
      this.allNotifications = []
      this.socketService.onEvent("getNotificationSuperAdminUnread").subscribe((response) => {
        this.allNotificationCount = 0;
        this.allNotifications = [];
        
        for (let index = 0; index < response.length; index++) {
          const item = response[index];
          if (item.is_SA_readStatus == 0) {
            this.allNotificationCount++;
            this.allNotifications.push(item);
            const myArray = item.notification_msg.split(" ");

            // console.log("-0-0-0-0-0-0-0-0-0---0-0---0-0-0-->");
            // console.log("-0-0-0-0-0-0-0-0-0---0-0---0-0-0-->");
            // console.log(item.notification_msg);
            // console.log("-0-0-0-0-0-0-0-0-0---0-0---0-0-0-->");
            // console.log("-0-0-0-0-0-0-0-0-0---0-0---0-0-0-->");
            
            if(myArray[0]=="A" && myArray[1]=="new" && myArray[2]=="company"){

              // alert("company");
                  
                // console.log(" ---------------> company");

                let to_id = 1;
                  this.socketService.updateReadStatusSuperAdminDash(to_id).subscribe((data:any) => {
                  });
                  this.speech.speak({
                      text: item.notification_msg,
                  }).then(() => {
                      console.log("Success !")
                  }).catch(e => {
                      console.error("An error occurred :", e) 
                  });

            } else if(myArray[0]=="You" && myArray[1]=="have" && myArray[2]=="Published"){
                // alert("Published");

                // console.log(" ----------------> Published");
                
                let to_id = 1;
                  this.socketService.updateReadStatusSuperAdminDash(to_id).subscribe((data:any) => {
                  });
                  this.speech.speak({
                      text: item.notification_msg,
                  }).then(() => {
                      console.log("Success !")
                  }).catch(e => {
                      console.error("An error occurred :", e) 
                  });
            }
            else if(myArray[0]=="A" && myArray[1]=="new" && myArray[2]=="user"){
                              
                              // alert("user");
        
                    // console.log(" ----------------> user");
                  
                    let to_id = 1;
                    this.socketService.updateReadStatusSuperAdminDash(to_id).subscribe((data:any) => {
                    });
                    this.speech.speak({
                        text: item.notification_msg,
                    }).then(() => {
                        console.log("Success !")
                    }).catch(e => {
                        console.error("An error occurred :", e) 
                    });
              }
          }
        }
       
        
        this.notificationLength = this.allNotifications.length;
        //this.socketService.setNotifications(response, true);
      });



      // setTimeout(() => {
      // this.socketService.emitEvent("getNotificationSuperAdmin", {});
      // console.log("Set Timeout Running");
      //  }, 500);
    } else if (JSON.parse(this.userdata).role_id == 2) {

      // this.allNotifications = []
      // this.socketService.onEvent("getNotificationSuperAdminUnread").subscribe((response) => {
      //   this.allNotificationCount = 0;
      //   this.allNotifications = [];
        
      //   for (let index = 0; index < response.length; index++) {
      //     const item = response[index];
      //     if (item.is_SA_readStatus == 0) {
      //       this.allNotificationCount++;
      //       this.allNotifications.push(item);
      //       const myArray = item.notification_msg.split(" ");

      //       console.log("-0-0-0-0-0-0-0-0-0---0-0---0-0-0-->");
      //       console.log("-0-0-0-0-0-0-0-0-0---0-0---0-0-0-->");
      //       console.log(item.notification_msg);
      //       console.log("-0-0-0-0-0-0-0-0-0---0-0---0-0-0-->");
      //       console.log("-0-0-0-0-0-0-0-0-0---0-0---0-0-0-->");
            
      //       if(myArray[0]=="A" && myArray[1]=="new" && myArray[2]=="company"){

      //         // alert("company");
                  
      //           console.log(" ---------------> company");

      //             let company_ID = 1;
      //             this.socketService.updateReadStatusSuperAdminDash(company_ID).subscribe((data:any) => {
      //             });
      //             this.speech.speak({
      //                 text: item.notification_msg,
      //             }).then(() => {
      //                 console.log("Success !")
      //             }).catch(e => {
      //                 console.error("An error occurred :", e) 
      //             });

      //       } else if(myArray[0]=="You" && myArray[1]=="have" && myArray[2]=="Published"){
                  
      //         // alert("Published");

      //         console.log(" ----------------> Published");
            
      //         let company_ID = 2;
      //         this.socketService.updateReadStatusSuperAdminDash(company_ID).subscribe((data:any) => {
      //         });
      //         this.speech.speak({
      //             text: item.notification_msg,
      //         }).then(() => {
      //             console.log("Success !")
      //         }).catch(e => {
      //             console.error("An error occurred :", e) 
      //         });
      //     } else if(myArray[0]=="A" && myArray[1]=="new" && myArray[2]=="user"){
                              
      //                 // alert("Published");

      //                 console.log(" ----------------> user");
                    
      //                 let company_ID = 2;
      //                 this.socketService.updateReadStatusSuperAdminDash(company_ID).subscribe((data:any) => {
      //                 });
      //                 this.speech.speak({
      //                     text: item.notification_msg,
      //                 }).then(() => {
      //                     console.log("Success !")
      //                 }).catch(e => {
      //                     console.error("An error occurred :", e) 
      //                 });
      //           }
      //     }
      //   }
       
        
      //   this.notificationLength = this.allNotifications.length;
      //   //this.socketService.setNotifications(response, true);
      // });

      // setTimeout(() => {
      // this.socketService.emitEvent("getNotification", {});
      //  }, 500);
    }

    this.currentTheme = this.themeService.currentTheme;

    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.admin));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

  }


  totalUserList: any;
  // totalActiveUser:any;
  // totalInActiveUser:any;
  analyticsData: any;
  companyData: any;
  demoBookingsCount: any;
  inActiveClients: any;
  responsesCount: any;
  userDataCount: any;
  activeCompaniesList:any


  
  autologin() {
    let loginData = {
      email:'cviewsuper@gmail.com',
      password:'Cview@12345',
      role_id:1,
      browser_details:this.browserDetails
    }
    // console.log("Before login ",loginData);
    this.SpinnerService.show();
    this.Loginservice.loginUser(loginData).subscribe(response => {
          // console.log("After login ",response);
          if(response.success == true){
            sessionStorage.setItem('token', response["accessToken"]);
            localStorage.setItem('token', response["accessToken"]);
            this.getAllData(response);
            // this.router.navigate(['/cvdashboard']);
          }else{
            this.router.navigate(['/adminlogin']);
          }

          // this.getAllData(response);
          
        },
        error => {
          this.SpinnerService.hide();
          console.log("After login error = ",error);
        }
      )
  };

  getAllData(response) {
    let id = response.id
    //console.log(response);
    this.Loginservice.findAllUserData(id).subscribe((response) => {
       console.log("findAllUserData:",response);
      if (response["data"].role_id != 1) {
        this.SpinnerService.hide();
        // this.edited_fail = true;
        // this.edited = false;
      }else {
        var data = JSON.stringify(response["data"]);
        sessionStorage.setItem('userData', data);
        sessionStorage.setItem('isLoggedin', 'true');
        // this.edited = true;
        // this.edited_fail = false;
        // this.router.navigate(['/cvdashboard']);
        this.SpinnerService.hide();
        
      }
    })
  }
  getDashboardData() {
    this.CvDashboardService.getAllUserList().subscribe((userData: any) => {
      this.totalUserList = userData.data
    })

    this.CvDashboardService.getAllPaidCompanyCounts().subscribe((data: any) => {
      this.companyDataCount = data.data;
    })

    this.CvDashboardService.getUserPlanData().subscribe((data:any)=>{
      this.userDataCount = data.data;
    })

    // let startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
    this.CvDashboardService.getDemoBookingsCount().subscribe((data: any) => {
      this.demoBookingsCount = data.bookingsCount
    })
    // this.CvDashboardService.getInActiveClients().subscribe((data: any) => {
    //   this.inActiveClients = data.inActive;
    // })
    this.CompanyService._getAllActiveCompaniesList().subscribe((data:any)=>{
      this.inActiveClients = data.inActiveCompaniesCount
      this.activeCompaniesList = data.totalActiveCompaniesList;
    })
    this.CvDashboardService.getTotalResponses().subscribe((data: any) => {
      this.responsesCount = data;
    })
  }

  //******************************************* PLAN GRAPH ***********************************************************
  public planBarChartLabels = [];
  public planBarChartLegend = false;
  public planBarChartData = [{
    data: [],
    backgroundColor: ['rgb(197 0 11)', 'rgb(0 69 134)', 'rgb(255 230 0)', 'rgb(255 149 14)', 'rgb(87 157 28)'],
    hoverBackgroundColor: ['rgb(197 0 11)', 'rgb(0 69 134)', 'rgb(255 230 0)', 'rgb(255 149 14)', 'rgb(87 157 28)'],
    label: "Plan Chart"
  }];

  public planBarChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    animationEnabled: true,
    exportEnabled: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepValue: 1,
            suggestedMin: 0,
            suggestedMax: 10,
          },
        },
      ],
      xAxes: [{
        ticks: {
          autoSkip: false
        }
      }]
    },
  };



  planPopularityChart() {
    let count = []
    this.planBarChartLabels = [];
    this.planBarChartData = [{
      data: [],
      backgroundColor: ['rgb(197 0 11)','rgb(0 69 134)','rgb(255 230 0)','rgb(255 149 14)','rgb(87 157 28)','rgb(217 67 78)','rgb(216 112 173)'],
      hoverBackgroundColor: ['rgb(197 0 11)','rgb(0 69 134)','rgb(255 230 0)','rgb(255 149 14)','rgb(87 157 28)','rgb(217 67 78)','rgb(216 112 173)'],
      label: "Plan Chart"
    }];
    //this.planBarChartLabels = ["Free", "Trial", "Basic", "Pro", "Premium"]
    this.CvDashboardService.getPlanCompanies().subscribe((data: any) => {
      var planData = data.data;
      this.planBarChartLabels = planData.map((data)=>data.name)
      count = planData.map((data)=>data.companyCount)
      // count.push(planData.freePlan)
      // count.push(planData.trialPlan)
      // count.push(planData.basicPlan)
      // count.push(planData.proPlan)
      // count.push(planData.premiumPlan)

      this.planBarChartData = [{
        data: count,
        backgroundColor: ['rgb(197 0 11)','rgb(0 69 134)','rgb(255 230 0)','rgb(255 149 14)','rgb(87 157 28)','rgb(217 67 78)','rgb(216 112 173)'],
        hoverBackgroundColor: ['rgb(197 0 11)','rgb(0 69 134)','rgb(255 230 0)','rgb(255 149 14)','rgb(87 157 28)','rgb(217 67 78)','rgb(216 112 173)'],
        label: "Plan Chart"
      }];

    });
  }

  //======================================================================================================================

  //******************************************* DEMO GRAPH ***********************************************************

  public demoBarChartLabels = [];
  public demoBarChartLegend = false;
  public demoBarChartData = [{
    data: [],
    backgroundColor: ['rgb(197 0 11)', 'rgb(0 69 134)', 'rgb(255 230 0)', 'rgb(255 149 14)', 'rgb(87 157 28)'],
    hoverBackgroundColor: ['rgb(197 0 11)', 'rgb(0 69 134)', 'rgb(255 230 0)', 'rgb(255 149 14)', 'rgb(87 157 28)'],
    label: "Demo Bookings Chart"
  }];

  public demoBarChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    animationEnabled: true,
    exportEnabled: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepValue: 1,
            suggestedMin: 0,
            suggestedMax: 10,
          },
        },
      ],
      xAxes: [{
        ticks: {
          autoSkip: false
        }
      }]
    },
  };

  timeFilter = 'allBookings';
  demoBookingsChart(filterType) {
    let dataArray = []
    this.demoBarChartLabels = [];
    this.demoBarChartData = [{
      data: [],
      backgroundColor: ['rgb(197 0 11)', 'rgb(0 69 134)', 'rgb(255 230 0)', 'rgb(255 149 14)', 'rgb(87 157 28)'],
      hoverBackgroundColor: ['rgb(197 0 11)', 'rgb(0 69 134)', 'rgb(255 230 0)', 'rgb(255 149 14)', 'rgb(87 157 28)'],
      label: "Demo Bookings Chart"
    }];
    let startDate, endDate;
    if (filterType == 'lastSevenDays') {
      startDate = moment().subtract(6, 'days').format('YYYY-MM-DD') + ' 00:00:00'
      endDate = moment().add(1, 'days').format('YYYY-MM-DD') + ' 00:00:00'
    } else if (filterType == 'allBookings') {
      startDate = '2010-01-01' + ' 00:00:00'
      endDate = moment().add(1, 'days').format('YYYY-MM-DD') + ' 00:00:00'
    } else if (filterType == 'lastTwelveMonths') {
      startDate = moment().subtract(364, 'days').format('YYYY-MM-DD') + ' 00:00:00'
      endDate = moment().add(1, 'days').format('YYYY-MM-DD') + ' 00:00:00'
    }

    this.CvDashboardService.getDemoBookings(startDate, endDate).subscribe((data: any) => {
      let bookings = data.data
      let newArr = [];
      if (filterType == 'lastSevenDays') {
        for (let i = 0; i < bookings.length; i++) {
          newArr.push(moment(bookings[i].createdAt).format('dddd'));
        }
      } else if (filterType == 'allBookings') {
        for (let i = 0; i < bookings.length; i++) {
          newArr.push(moment(bookings[i].createdAt).format('MMMM'));
        }
      } else if (filterType == 'lastTwelveMonths') {
        for (let i = 0; i < bookings.length; i++) {
          newArr.push(moment(bookings[i].createdAt).format('MMMM'));
        }
      }

      let count = {};
      for (let j = 0; j < newArr.length; j++) {
        count[newArr[j]] = 1 + (count[newArr[j]] || 0);
      }

      this.demoBarChartLabels = Object.keys(count);
      //this.demoBarChartLabels.splice(0, 0, " ");
      let dataArray = [];
      dataArray = Object.values(count);
      this.demoBarChartData = [{
        data: dataArray,
        backgroundColor: ['rgb(197 0 11)', 'rgb(0 69 134)', 'rgb(255 230 0)', 'rgb(255 149 14)', 'rgb(87 157 28)'],
        hoverBackgroundColor: ['rgb(197 0 11)', 'rgb(0 69 134)', 'rgb(255 230 0)', 'rgb(255 149 14)', 'rgb(87 157 28)'],
        label: "Demo Bookings Chart"
      }];
      // dataArray.splice(0, 0, 0);
    });
  }

  //======================================================================================================================

  cvdashboard() {
    this.router.navigate(['./cvdashboard']);
  }

  login() {
    this.router.navigate(['/adminlogin']);
  }

  // get slicedComponentData() {
  //   return this.companydata && this.companydata.slice ? this.companydata.slice(0, 10) : [];
  // }

  // get slicedSurverData() {
  //   return this.sureydata && this.sureydata.slice ? this.sureydata.slice(0, 5) : [];
  // }

  categoryList() {
    // counttt ='';
    this.CategoryService.getdata().subscribe((data: any[]) => {
        let counttt = data.length;
        this.categorylistt = data.length;
      });
    error => {
      console.log('no data found', error);
    };
  }

  companylist() {
    const itemIds = [];
    this.CompanyService.totalCompanylist().subscribe((data: any) => {
        // console.log('--------------------->');
        // console.log('--------------------->');
        // console.log('--------------------->');
        // console.log(data.data);
        // console.log('--------------------->');
        // console.log('--------------------->');
        // console.log('--------------------->');
        
      this.companydata = data.data;
      this.companylists = data.data.length;
      // console.log(this.companydata);
      // alert('data => ' + this.companydata);
    });
  }

  responsesurveycompanylist() {
    this.CompanyService.totalTenSurveyResponseCompanylist().subscribe((data: any) => {
      this.surveyresponsecompanydata = data;
    });
  }
  //======================================================================================================
  companyUserData: any;

  onCompanySelect(data) {
    this.showCompanyData = true;
    //this.userListData.push(data.id);
    this.CvDashboardService.getCompanyUserData(data.id).subscribe((data: any) => {
      let userData = data.data;
      let totalUsers = userData.length;
      let inActiveUsers = 0;
      let activeUsers = 0;
      if (userData.length != 0) {
        for (let item of userData) {
          if (item.active == 1) { activeUsers++ }
          else if (item.active == 0) { inActiveUsers++ }
        }
      }
      this.companyUserData = {
        totalCompanyUsers: totalUsers,
        companyActiveUsers: activeUsers,
        companyInActiveUsers: inActiveUsers,
      }
    })
  }

  onCompanyDeSelect(data) {
    this.showCompanyData = false;
  }

  //=======================================================================================================
  companyResponseData: any;
  showCompanyResponse = false;
  onCompanySelectForResponse(data) {
    this.showCompanyResponse = true;
    this.CvDashboardService.getCompanyResponses(data.id).subscribe((data: any) => {
      this.companyResponseData = {
        totalCompanyResponse: data.totalResponses,
        lastSevenDaysResponse: data.lastSevenDays,
        lastThirtyDaysResponse: data.lastThirtyDays,
        lastTwelveMonthsResponse: data.lastTwelveMonths
      }
    })
  }

  onCompanyDeSelectForResponse(data) {
    this.showCompanyResponse = false;
  }
  //=======================================================================================================

  templateLists() {
    const templateIds = [];
    this.TemplateService.Templatelist()
      .subscribe((data) => {
        for (let i = 0; i < data.length; i++) {
          templateIds.push(data[i]);
        }
        this.templatedata = templateIds;
        this.templatelists = templateIds.length;
      });
  }

  Survey() {
    //this.router.navigate(["/survey"])
    this.router.navigate([
      "/company/details/" + this.parentID + "/create-survey",
    ]);
  }

  Company(id=null) {
    if(id==null){
      this.router.navigate(["/company"])
    } else {
      this.router.navigate(["/company",id])
    }
    // this.router.navigate([
    //   "/company/details/" + this.parentID + "/create-survey",
    // ]);
  }

  Categories() {
    this.router.navigate(["/category"])
  }

  Templates() {
    this.router.navigate(["/template"])
  }

  surveylist() {
    const surveyy = [];
    this.SurveyService.survey_list()
      .subscribe((data: any[]) => {
        for (let i = 0; i < data.length; i++) {
          if (this.companydata.some && this.companydata.some((item) => item.id == data[i]['company_id'])) {
            surveyy.push(data[i]);
          }
        }
        this.sureydata = surveyy;
        this.createsurey = surveyy.length;
      });
  }

  userlist() {
    const user = [];
    this.UserService.service_list()
      .subscribe((data: any[]) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i]['role_id'] == 4 && this.companydata.some && this.companydata.some((item) => item.id == data[i]['company_id'])) {
            let a = data[i];
            user.push(data[i]);
          }
        }
        this.Userlist = user.length;
      });
  }

   numFormatter(num) {
    if(num > 999 && num < 1000000 && num < 1000000000 ){return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
    }else if(num >= 1000000 && num <= 1000000000 ){return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
    }else if(num >= 1000000000){return (num/1000000000).toFixed(1) + 'B'; // convert to B for number from > 1000 million 
    }else if(num <= 999){return num;}  
}


myBrowser() { 

  var navUserAgent = navigator.userAgent;
  var browserName  = navigator.appName;
  var browserVersion  = ''+parseFloat(navigator.appVersion); 
  var majorVersion = parseInt(navigator.appVersion,10);
  var tempNameOffset,tempVersionOffset,tempVersion;
  
  
  if ((tempVersionOffset=navUserAgent.indexOf("Opera"))!=-1) {
   browserName = "Opera";
   browserVersion = navUserAgent.substring(tempVersionOffset+6);
   if ((tempVersionOffset=navUserAgent.indexOf("Version"))!=-1) 
     browserVersion = navUserAgent.substring(tempVersionOffset+8);
  } else if ((tempVersionOffset=navUserAgent.indexOf("MSIE"))!=-1) {
   browserName = "Microsoft Internet Explorer";
   browserVersion = navUserAgent.substring(tempVersionOffset+5);
  } else if ((tempVersionOffset=navUserAgent.indexOf("Chrome"))!=-1) {
   browserName = "Chrome";
   browserVersion = navUserAgent.substring(tempVersionOffset+7);
  } else if ((tempVersionOffset=navUserAgent.indexOf("Safari"))!=-1) {
   browserName = "Safari";
   browserVersion = navUserAgent.substring(tempVersionOffset+7);
   if ((tempVersionOffset=navUserAgent.indexOf("Version"))!=-1) 
     browserVersion = navUserAgent.substring(tempVersionOffset+8);
  } else if ((tempVersionOffset=navUserAgent.indexOf("Firefox"))!=-1) {
   browserName = "Firefox";
   browserVersion = navUserAgent.substring(tempVersionOffset+8);
  } else if ( (tempNameOffset=navUserAgent.lastIndexOf(' ')+1) < (tempVersionOffset=navUserAgent.lastIndexOf('/')) ) {
   browserName = navUserAgent.substring(tempNameOffset,tempVersionOffset);
   browserVersion = navUserAgent.substring(tempVersionOffset+1);
   if (browserName.toLowerCase()==browserName.toUpperCase()) {
    browserName = navigator.appName;
   }
  }
  
  // trim version
  if ((tempVersion=browserVersion.indexOf(";"))!=-1)
     browserVersion=browserVersion.substring(0,tempVersion);
  if ((tempVersion=browserVersion.indexOf(" "))!=-1)
     browserVersion=browserVersion.substring(0,tempVersion);  
  // alert("BrowserName = " + browserName + "\n" + "Version = " + browserVersion);
  let browserData = ("BrowserName="+browserName+ ","+"Version="+browserVersion);
  return  browserData

}

async logout() {
  this.userdata = sessionStorage.getItem("userData");
  if (JSON.parse(this.userdata).role_id == 2) {
    await this.planservice.updateLogoutDeatailsOfCompany(this.companyID).subscribe((data: any) => {
    });
   // window.location.href = './';
     this.router.navigate(["./login"]);

  } else {
    await this.planservice.updateLogoutDeatailsOfSuperAdmin().subscribe((data: any) => {
    });
    this.router.navigate(["./adminlogin"]);
  }
  sessionStorage.clear();
  localStorage.clear();

  return false;
}

}
