import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor{

  constructor() { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // throw new Error('Method not implemented.');
    const token = localStorage.getItem("token")?localStorage.getItem("token"):false; // you probably want to store it in localStorage or something
    // console.log("auth-token",token)
    const skipIntercept = req.headers.has('x-access-token')?req.headers.has('x-access-token'):false;
    if (!token) {
      // console.log("No-token")
      return next.handle(req);
    }else if(skipIntercept){
      // console.log("skipIntercept")
      return next.handle(req);
    }else{
      // console.log("yes-set-token")
      const req1 = req.clone({
        headers: req.headers.set('x-access-token', `${token}`),
      });
  
      // return next.handle(req1);
      return next.handle(req1);
    }
  }
}
