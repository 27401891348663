import { environment } from "../environments/environment";

export class GlobalConstants {
   //FOR LIVE

   public static social_key_expire_minutes = 43200;
   public static web_domain: string = 'https://beta.cviewsurvey.com/';
   public static apiURL: string = 'https://beta.cviewsurvey.com:8090/';
   public static stripe = "pk_live_51I9oSIKOJLzyXAxQ9qhFlqHxJEoUAHkTlYhg8obnaVV0JacBuKLOaYyRU9Viy2emutfHsiLeJymJiwP0mzAmExRw00oWgqetUH";
   public static googleClientID = "311497152508-aegaalk137h8mh65sfqod3623o20tdaa.apps.googleusercontent.com";
   public static totalResponseCountByWebSharingLink = 100;
   public static totalResponseCountByIpAddressWebSharingLink = 10;
   public static ccavenueurl = "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
   public static accesscode = 'AVSW14IH27AH82WSHA';	
   public static merchant_id = 320950;
   public static googlecontactapi_key = 'AIzaSyAB0eAe6sMvr1_WPGUkExPJwA5vwFxwU7M';
   public static googlecontactclient_id = '311497152508-j3ium12rjmmjtsad7p85ofrvpn48529k.apps.googleusercontent.com';


  // FOR Local System 

  //  public static social_key_expire_minutes = 43200;
  //  public static web_domain: string = 'https://cviewsurvey.coworrk.com/';
  //  public static apiURL: string = 'https://cviewsurvey.coworrk.com:8090/';
  //  public static stripe = "pk_test_51I9oSIKOJLzyXAxQxP4r3QGhVKC43ykEOgbkT87Zk3vnFJvGgZ7QTLF0KcuXCJk50thMeHq22O3KEFfAsxjYPjpL00PcTVZnYL";
  //  public static googleClientID = "311497152508-g3lu2ddttu0qedp674abvldd9flpd9lm.apps.googleusercontent.com";
  //  public static totalResponseCountByWebSharingLink = 100;
  //  public static totalResponseCountByIpAddressWebSharingLink = 10;
  //    //  **********************************************  CCavenue **************************************************************8
  //  public static ccavenueurl = "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
  //  public static accesscode = 'AVMK03IH87AK94KMKA';
  //  public static merchant_id = 320950;
  //   //  For Google Contact Import 
  //  public static googlecontactapi_key = 'AIzaSyAB0eAe6sMvr1_WPGUkExPJwA5vwFxwU7M';
  //  public static googlecontactclient_id = '311497152508-bo7736f3pd3idhi9uoo8dknef2tdffli.apps.googleusercontent.com';
  //   // AIzaSyAB0eAe6sMvr1_WPGUkExPJwA5vwFxwU7M311497152508-bo7736f3pd3idhi9uoo8dknef2tdffli.apps.googleusercontent.com
   
  // FOR Local System 

//    public static social_key_expire_minutes = 43200;
//    public static web_domain: string = 'http://localhost:4200/';
//    public static apiURL: string = 'http://localhost:8090/';
//    public static stripe = "pk_test_51I9oSIKOJLzyXAxQxP4r3QGhVKC43ykEOgbkT87Zk3vnFJvGgZ7QTLF0KcuXCJk50thMeHq22O3KEFfAsxjYPjpL00PcTVZnYL";
//    public static googleClientID = "311497152508-6pkof3krnneujcmkfomr79s4ea96tmgq.apps.googleusercontent.com";
//    public static totalResponseCountByWebSharingLink = 100;
//    public static totalResponseCountByIpAddressWebSharingLink = 10;
//   //**********************************************  CCavenue **************************************************************
//    public static ccavenueurl = "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
//    public static accesscode = 'AVFZ03IG86CC60ZFCC';
//    public static merchant_id = 320950;
//    //***************************************************** For Google Contact Import *********************************** * */
//    public static googlecontactapi_key = 'AIzaSyAB0eAe6sMvr1_WPGUkExPJwA5vwFxwU7M';
//    public static googlecontactclient_id = '311497152508-bmp5j4287rh6a87iptpqnc8kuc125vls.apps.googleusercontent.com';


   

}
