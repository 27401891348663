import { Component, OnInit, Input, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { GlobalConstants } from "../../global-constants";
import { loginservice } from "../authapi.service";
import * as CryptoJS from "crypto-js";
import * as moment from "moment"; 
import { Renderer2 } from "@angular/core";
import Swal from "sweetalert2";
//=======================================================  Service Import =========================================================
import { SignaturePad } from "angular2-signaturepad";
// ======================================================== Form Builder ====================================================
import {
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Options } from "@angular-slider/ngx-slider";
import {GeofenceService} from "../../pages/assign-geofence/geofence.service"
@Component({
  selector: "ngx-survey-sharing",
  templateUrl: "./survey-sharing.component.html",
  styleUrls: ["./survey-sharing.component.scss"],
})
//  Api  calling
export class SurveySharingComponent implements OnInit,AfterViewInit {
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  value: number = 0;
  options: Options = {
    floor: 0,
    ceil: 100,
    showTicksValues: true,
    tickStep: 10,
    step: 1,
  };
  private signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 800,
    canvasHeight: 450,
  };

  isSurveyContinue = false;
  noSurvey: any; //No survey found title
  noSurveySub: any; // No survey found sub title
  optionValue = ""; //Default Selected Blank state
  public server = GlobalConstants.apiURL;
  public social_key_expire_minutes = GlobalConstants.social_key_expire_minutes;
  public totalResponseCountByWebSharingLink = GlobalConstants.totalResponseCountByWebSharingLink;
  public totalResponseCountByIpAddressWebSharingLink =
    GlobalConstants.totalResponseCountByIpAddressWebSharingLink;
  stars: number[] = [1, 2, 3, 4, 5];
  NPSS: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  scales: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  questiondisplay: any;
  count: any;
  i: any;
  show1 = false;
  show2 = false;
  public matrixOptionsAnswerRecords = [];
  radio: any[];
  customerdata: any;
  surveyanswerdata: any;
  itemdata: {};
  index: number;
  companyId: number;
  itemIds: any;
  loading = false;
  public show: boolean = false;
  sur_sel = "";
  selectedValue: number;
  selected: number;
  opinionscale: any;
  submitted: boolean;
  userID = 1;
  public stepindex: number = 0;
  select = true;
  public checkbox_array: any[];
  dependentquestion: Object;
  public depent_id: any;
  public is_depent: any;
  thankyou_response: any[];
  wel_show2 = false;
  welcome_response: any;
  wel_show1 = true;
  upload: any;
  npsvalue: any;
  selectedindexx = 0;
  scalevalue: any; 
  message_sucesss = false;
  message_welcome = false;
  selectedsurveyid: any;
  error_custom_form = false;
  error_custom_valid = false;
  valid_error: any;
  invalidfile = false;
  acceptedExtensions: string[];
  public imagePath;
  public message: string;
  imgURL: any;
  theme_name: any;
  company_name: any;
  survey_name: any;
  displayBlock = false;
  displayNone = false;
  selecteddd: any;
  userMsgData: any;
  errormessage: string;
  surveyId: any;
  company_image: any;
  imageName: string | ArrayBuffer;
  websharedata: any;
  image_bg: any;
  previousQuestionID: number;
  selectedNps: any;
  textarea_value: any;
  seletedImage: any;
  selectedYesNo: any;
  selectedmutlipleanswers: any;
  selectedPicure: any;
  selecteddropdownoption :any;
  surveyLength: any;
  percentage: any;
  pvalue: any;
  selecteddropdownvalue: any;
  keyexpire = false;
  keyCompanyExpire = false;
  keySurveyExpire = false;
  previuorsQuestionRecords = [];
  selectedcheckboxvalue: any;
  checked_value: any;
  languageBox = true;
  isReloadButtonvisible = false;
  isSignatureCleared = false;
  record_data: any;
  previours_dependent_question_id = null;
  isRequiredCustomFields = false;
  surveyActive = false;
  videourl :any;
  responsetype: any;
  // ===================================================== constructor ==================================================================
  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private loginservice: loginservice,
    private SpinnerService: NgxSpinnerService, // private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private geofenceService:GeofenceService
  ) {}
  // ======================================== End of  constructor ===========================================================================



  ngOnInit() {
  
    let key = this.activatedRoute.snapshot.params.key;
    if (!key) {
      alert("key not found");
      alert("Enter Wrong");
      // ==========================  404 page redirect  ========================================================================================
      return;
    }
    try {
      let replacedkey = key.split("xMl3Jk").join("+");
      replacedkey = replacedkey.split("Por21Ld").join("/");
      replacedkey = replacedkey.split("Ml32").join("=");
     const secreteCode = "Cview-Survey-data-code";
      let decryptedKey = CryptoJS.AES.decrypt(
        replacedkey,
        secreteCode
      ).toString(CryptoJS.enc.Utf8);
      var data = JSON.parse(decryptedKey);
      this.companyId = data.companyId;
      this.surveyId =  data.surveyId;
      this.responsetype = data.responseType;
      
      this.websurveydata(this.companyId,this.surveyId)

      // this.geolocation();
      if(this.signaturePad){
        this.signaturePad.set("minWidth", 5); // set szimek/signature_pad options at runtime 
        this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
      }
    } catch (error) {
      console.log(error);
    }
  }


  renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    var v = document.createElement("script");
    v.type = "text/javascript";
    let url = `function googleTranslateElementInit() { new google.translate.TranslateElement({includedLanguages:'en,${this.surveylanguages}' }, 'google_translate_element'); }`;
    v.innerHTML = url;

    this.renderer.appendChild(document.body, v);
    return script;
  }
  ngAfterViewInit() {
    // this.handleSignatureCanvasSize(); //handle the resizing of signature pad canvas on different screens
    this.geolocation();
  }

  // ======================================= Form Submit SurveylistForm ===============================================================================
  surveylistForm = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.minLength(3)]),
    question_id: new FormControl(""),
    mobile: new FormControl("", [
      Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
    ]),
    email: new FormControl("", [
      Validators.required,
      Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/),
    ]),
    address: new FormControl("", [Validators.required]),
    age: new FormControl("", [Validators.required]),
    gender: new FormControl("", [Validators.required]),
    country: new FormControl("", [Validators.required]),
    zip: new FormControl("", [Validators.required]),
    // image: new FormControl(''),
    city: new FormControl("", [Validators.required]),
    state: new FormControl("", [Validators.required]),
    role_id: new FormControl("5"),
    company_id: new FormControl(),
    option: new FormControl([], [Validators.required]),
  });
  public surveyFields = [];

  get f() {
    return this.surveylistForm.controls;
  }
  // ============================================ Surveylist Form ================================================
  selectedDate = "";
  previousQuestion(
    indexvalue,
    dependent_question_id,
    is_mandatory,
    question_id,
    question_type_id
  ) {
    if (this.previours_dependent_question_id != "") {
      let previousQuestion = this.getPrevioursQuestionId(
        this.previours_dependent_question_id
      );
      this.selectedindexx = previousQuestion.question_index;
      this.previours_dependent_question_id = "";
    } else {
      let previousQuestion = this.getIndexPrevioursQuestion(indexvalue - 1);
      this.selectedindexx = previousQuestion.question_index;
    }

    let previous_question = this.getPrevioursQuestionByNoParams();
    this.selectedindexx = previous_question.question_index;

    let attemptedQuestionInfo = this.getAttemptedQuestion(
      previous_question.question.question_id
    );
    if (!attemptedQuestionInfo.questiontype_id) {
      alert("not foundd");
      return;
    }
    if (attemptedQuestionInfo.questiontype_id == 8) {
      let dateString = attemptedQuestionInfo.answer;
      let date = moment(dateString).format("YYYY-MM-DD");
      this.selectedDate = date;
    } else if (attemptedQuestionInfo.questiontype_id == 4) {
      this.selectedNps = attemptedQuestionInfo.answer;
      this.npsvalue = this.selectedNps;
    } else if (attemptedQuestionInfo.questiontype_id == 3) {
      this.selectedValue = attemptedQuestionInfo.answer;
    } else if (attemptedQuestionInfo.questiontype_id == 14) {
      this.selectedYesNo = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 6) {
      this.textarea_value = attemptedQuestionInfo.answer;
    } else if (attemptedQuestionInfo.questiontype_id == 12) {
      this.seletedImage = attemptedQuestionInfo.answer;
    } else if (attemptedQuestionInfo.questiontype_id == 2) {
      this.scalevalue = attemptedQuestionInfo.answer;
    } else if (attemptedQuestionInfo.questiontype_id == 1) {
      this.selectedmutlipleanswers = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 11) {
      this.selectedPicure = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 5) {
      this.selecteddropdownoption = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 5) {
      this.selecteddropdownvalue = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 10) {
      this.selectedcheckboxvalue = attemptedQuestionInfo.answer_id;
    } else if (attemptedQuestionInfo.questiontype_id == 7) {
      this.value = attemptedQuestionInfo.answer;
    }
  }
  // ===================================== Next Question Functionality ===================================================
  nextQuestion(
    questiontypeid,
    depenttt_id,
    is_depenttt_id,
    is_mandotory,
    question_id,
    isSurveySubmit,
    selectedIndex
  ) {
    this.value = 0;
    this.bad = false;
    this.average = false;
    this.good = false;
    if (is_mandotory == 1 && questiontypeid != 9) {
      let is_visited = this.verify_mandotory_question(
        question_id,
        questiontypeid
      );
      if (is_visited == false) {
        this.errormessage = "Answer is required";
        is_visited == false;
        return;
      }
    } else if (questiontypeid == 9 && is_mandotory == 1) {
      this.isRequiredCustomFields = true;
      this.depent_id = depenttt_id;
      this.is_depent = is_depenttt_id;
      var name = this.surveylistForm.value.name;
      var mobile = this.surveylistForm.value.mobile;
      var email = this.surveylistForm.value.email;
      var address = this.surveylistForm.value.address;
      var city = this.surveylistForm.value.city;
      var state = this.surveylistForm.value.state;
      var age = this.surveylistForm.value.age;
      var gender = this.surveylistForm.value.gender;
      var zip = this.surveylistForm.value.zip;
      var country = this.surveylistForm.value.country;
      if (this.surveyFieldsExists("Name") && name == "") {
        this.error_custom_form = true;
        this.isRequiredCustomFields = true;
        return;
      }
      if (this.surveyFieldsExists("Email") && email == "") {
        this.error_custom_form = true;
        this.isRequiredCustomFields = true;
        return;
      }
      if (this.surveyFieldsExists("Email") && email != "") {
        var emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
        if (!emailPattern.test(email)) {
          this.error_custom_form = false;
          this.error_custom_valid = true;
          this.isRequiredCustomFields = true;
          this.valid_error = "Email is invalid.";
          return;
        } else {
          this.error_custom_form = false;
          this.error_custom_valid = false;
          this.isRequiredCustomFields = true;
        }
      }
      if (this.surveyFieldsExists("Phone") && mobile != "") {
        var phonePattern = /^((\\+91-?)|0)?[0-9]{10}$/;
        if (!phonePattern.test(mobile)) {
          this.error_custom_form = false;
          this.isRequiredCustomFields = true;
          this.error_custom_valid = true;
          this.valid_error = "Phone number is invalid.";
          return;
        } else {
          this.error_custom_form = false;
          this.isRequiredCustomFields = true;
          this.error_custom_valid = false;
        }
      }

      if (this.surveyFieldsExists("Phone") && mobile == "") {
        this.error_custom_form = true;
        this.isRequiredCustomFields = true;
        return;
      }
      if (this.surveyFieldsExists("Address") && address == "") {
        this.error_custom_form = true;
        this.isRequiredCustomFields = true;
        return;
      }
      if (this.surveyFieldsExists("Age") && age == "") {
        this.error_custom_form = true;
        this.isRequiredCustomFields = true;
        return;
      }
      if (this.surveyFieldsExists("Gender") && gender == "") {
        this.error_custom_form = true;
        this.isRequiredCustomFields = true;
        return;
      }
      if (this.surveyFieldsExists("Zip") && zip == "") {
        this.error_custom_form = true;
        this.isRequiredCustomFields = true;
        return;
      }
      if (this.surveyFieldsExists("City") && city == "") {
        this.error_custom_form = true;
        this.isRequiredCustomFields = true;
        return;
      }
      if (this.surveyFieldsExists("State") && state == "") {
        this.error_custom_form = true;
        this.isRequiredCustomFields = true;
        return;
      }
      if (this.surveyFieldsExists("Country") && country == "") {
        this.error_custom_form = true;
        this.isRequiredCustomFields = true;
        return;
      }
      if (isSurveySubmit) {
        this.submitForm();
      } else if (this.depent_id != 0 && this.is_depent == 1) {
        var index = this.questiondisplay.survey_data_questions.findIndex(
          (p) => p.id == this.depent_id
        );
        this.selectedindexx = index;
      }
    }

    if (
      questiontypeid == 1 ||
      questiontypeid == 5 ||
      questiontypeid == 11 ||
      questiontypeid == 14 ||
      questiontypeid == 18
    ) {
      if (
        (this.depent_id == null && this.is_depent == null) ||
        (this.depent_id == "null" && this.is_depent == "null")
      ) {
        //==========================  if its occurs Last Question ========================================
        if (
          this.questiondisplay.survey_data_questions.length - 1 ==
          this.selectedindexx
        ) {
          this.submitForm();
        } else {
          this.selectedindexx = this.selectedindexx + 1;
        }
      }
      if (this.depent_id == 0 && this.is_depent == 1) {
        this.submitForm();
      }
      if (this.depent_id != 0 && this.is_depent == 1) {
        var index = this.questiondisplay.survey_data_questions.findIndex(
          (p) => p.id == this.depent_id
        );
        this.selectedindexx = index;
      }

      //==============for testing purpose addedd==============================
    } else {
      if (depenttt_id == 0 && is_depenttt_id == 1) {
        isSurveySubmit = true;
      } else if (depenttt_id != 0 && is_depenttt_id == 1) {
        let dependentQuestion = this.getQuestionByDependent(depenttt_id);
        this.selectedindexx = dependentQuestion.question_index;
      } else if (depenttt_id == null && is_depenttt_id == null) {
        if (
          this.questiondisplay.survey_data_questions.length - 1 ==
          this.selectedindexx
        ) {
          isSurveySubmit = true;
        } else {
          this.selectedindexx = this.selectedindexx + 1;
        }
      }
      if (isSurveySubmit) {
        this.submitForm();
      }
    }

    console.log(this.selectedindexx, "Outside the Loop Indexing Value .....");
    //===================================  Logic Skip Question Check =======================================================================================

    this.recordPreviousQuestions(
      question_id,
      questiontypeid,
      depenttt_id,
      selectedIndex
    );
    this.previousQuestionID = question_id;
    this.errormessage = "";
    this.scalevalue = null;
    this.selectedValue = null;
    this.npsvalue = null;
    // =================================== Condition For Custom form =========================================================================
  }

  //====================================================  websurveydata ==================================================
  theme_design_image: any;
  design_image: any;
  languages: any;
  isImagePath = false;
  themeclass: any;
  surveylanguages: any;
  websurveydata(company_id, survey_id) {

    this.SpinnerService.show(); 

    this.loginservice
      .web_survey_data(company_id, survey_id)
      .subscribe((surveywebdata: any) => {
        
        
        if(!surveywebdata["data"]){
          this.SpinnerService.hide();
          if(surveywebdata['message']=='Company not found.'){
            this.keyCompanyExpire = true;
            setTimeout(() => {
              window.location.href = "https://cviewsurvey.com";
            }, 5000);
          } else if(surveywebdata['message']=='Survey not found.'){
            this.keySurveyExpire = true;
            setTimeout(() => {
              window.location.href = "https://cviewsurvey.com";
            }, 5000);
          }
          return;
        }
        this.websharedata = surveywebdata["data"];
        
        if(surveywebdata["data"].survey.is_expired == 1){
          this.SpinnerService.hide();
          this.wel_show2 = false;
          this.message_welcome = false;
          this.keyexpire = true;
          return;
         } 


        this.surveylanguages = this.websharedata.survey.languages;
        if (
          this.websharedata.survey.languages == null ||
          this.websharedata.survey.languages == "null"
        ) {
          this.surveylanguages = "en";
        } else {
          this.surveylanguages = this.websharedata.survey.languages;
        }
        this.renderExternalScript(
          "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        ).onload = () => {};
        //  this.design_image = this.websharedata.survey.design_image;
     

        if (this.websharedata.survey.design_id == null) {
          this.isImagePath = false;
          this.themeclass = "custom";
          $(".theming__box").attr("data-theme", "custom");
          $(":root").css(
            "--question-color",
            this.websharedata.customtheme.question_color
          );
          $(":root").css(
            "--answer-color",
            this.websharedata.customtheme.answer_color
          );
          $(":root").css("--bg-color", this.websharedata.customtheme.bg_color);
          $(":root").css(
            "--button-color",
            this.websharedata.customtheme.button_bg
          );
          $(":root").css(
            "--button-text",
            this.websharedata.customtheme.button_text
          );
          $(":root").css(
            "--answer-text",
            this.websharedata.customtheme.answer_text
          );
          if(this.websharedata.customtheme.bg_image){
            let imageurl  = this.server+this.websharedata.customtheme.bg_image;
            var themeBackground = 'url(' + imageurl + ')';
             $(':root').css('--bg-image', themeBackground);
           }
            else{
              this.videourl  = this.server+this.websharedata.customtheme.bg_media;
              }
            


        } else if (this.websharedata.survey.custom_id == null) {
          this.isImagePath = false;
          this.themeclass = this.websharedata.Theme.design_class;
          this.image_bg = this.websharedata.Theme.design_image;
        }
        //  else{
        //       this.isImagePath = true;
        //        this.theme_design_image = this.server + this.websharedata.survey.design_image;
        //     }
        this.surveyActive = false;
        if (surveywebdata["data"].survey.is_active == 0) {
          this.SpinnerService.hide();
          this.wel_show2 = false;
          this.message_welcome = false;
          this.surveyActive = true;
          return;
        } else {
          this.SpinnerService.hide();
          this.surveyActive = false;

          if (!this.websharedata.welcome) {
            this.selectedsurveyid = this.surveyId;
            this.wel_show2 = false;
            this.message_welcome = true;
          } else {
            this.selectedsurveyid = this.surveyId;
            this.selecteddd = true;
            this.message_welcome = false;
            this.wel_show2 = true;
          }
        }
      });
    (error) => {
      console.log("response data error = ",error);
      this.SpinnerService.hide();
    };
  }
  //======================================== To Start Survey  =====================================================================
  continue(id) {
    this.languageBox = false;
    this.isReloadButtonvisible = true;
    this.wel_show2 = false;
    this.message_welcome = false;
    this.loginservice.question_get(id).subscribe((result: any[]) => {
      this.selecteddd = false;
      this.questiondisplay = result["data"];
      //=================================== New array without signature question type =======================================
      this.surveyLength = result["data"].survey_data_questions.length;
      this.percentage = 100 / parseInt(this.surveyLength);
      this.pvalue = this.percentage.toFixed(0);
      this.message_welcome == false;
      this.survey_name = result["data"]["survey_title"];
    });
    this.getFormField();
  }

  // ================================= For custom form fields ===================================================================
  getFormField() {
    this.loginservice.getSurveyFields(this.surveyId).subscribe((response) => {
      this.surveyFields = response["data"];
    });
  }

  surveyFieldsExists(fieldName) {
    return !!this.surveyFields.find((f) => f.field_name === fieldName);
  }
  // ===========  Image Uploading functionality  ========================================================================
  preview(files, questionid) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.imgURL = "";
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    this.message = "";
    this.upload = files[0];
    this.imgURL = files[0].name;
    this.imageName = reader.result;
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.uploadfile(questionid);
    };
  }
  // ====================================== Upload Images =======================================================================
  uploadfile(questionid) {
    const formData = new FormData();
    formData.append("images", this.upload);
    this.loginservice.uploadfile(formData).subscribe(
      (response) => {
        const uploadfiledata = {
          questiontype_id: 14,
          question_id: questionid,
          answer_id: null,
          answer: response["path"],
          survey_id: this.surveyId,
        };
        this.attemptedQuestion(questionid, uploadfiledata);
      },
      (error) => console.log(error)
    );
  }

  verify_mandotory_question(question_id, questiontypeid) {
    let is_visited = false;
 
    // =========================================== Check box Mandatory Question  =====================================================//
    if (questiontypeid == 10) {
      if (this.checkboxdata) {
        if (this.checkboxdata.question_id == question_id) {
          if (this.checkboxdata.answer) {
            is_visited = true;
            return is_visited;
          }
        } else {
          is_visited = false;
          return is_visited;
        }
      }
    }
    // =============================================== Matrix mandatory Question  =====================================================//
    if (questiontypeid == 15) {
      let question = this.questiondisplay.survey_data_questions.filter((q) => {
        if (q.id == question_id) {
          return q;
        }
      });
      let matrixanswerCount = this.matrixOptionsAnswerRecords.filter((q) => {
        if (q.question_id == question_id) {
          return q;
        }
      });
      if (matrixanswerCount.length == question[0].survey_data_answers.length) {
        is_visited = true;
        return is_visited;
      }
    }
    // =============================================== Rating  mandatory Question  =====================================================//
    if (questiontypeid == 17) {
      let question = this.questiondisplay.survey_data_questions.filter((q) => {
        if (q.id == question_id) {
          return q;
        }
      });
      let ratinganswerCount = this.ratingOptionArray.filter((q) => {
        if (q.question_id == question_id) {
          return q;
        }
      });
      if (ratinganswerCount.length == question[0].survey_data_answers.length) {
        is_visited = true;
        return is_visited;
      }
    }

    // =============================================== Textarea Grid  mandatory Question  =====================================================//
    if (questiontypeid == 16) {
      let question = this.questiondisplay.survey_data_questions.filter((q) => {
        if (q.id == question_id) {
          is_visited = false;
          return q;
        }
      });
      console.log(this.textAreaOptionArray, "textAreaOptionArray");
      let textaanswerCount = this.textAreaOptionArray.filter((q) => {
        if (q.question_id == question_id) {
          return q;
        }
      });
      if (textaanswerCount.length == question[0].survey_data_answers.length) {
        is_visited = true;
        return is_visited;
      }
    }

    // ================================================= For Mutiple choice ,Picture upload , Yesno , Dropdown  Mandatory Question  ================================
    for (let i = 0; i <= this.attemptedQuestionsArray.length - 1; i++) {
      if (this.attemptedQuestionsArray[i].question_id == question_id) {
        if (
          questiontypeid == 1 ||
          questiontypeid == 5 ||
          questiontypeid == 11 ||
          questiontypeid == 14 ||
          questiontypeid == 18
        ) {
          if (this.attemptedQuestionsArray[i].answer_id) {
            is_visited = true;
            return is_visited;
          }
        } else {
          if (this.attemptedQuestionsArray[i].answer) {
            is_visited = true;
            return is_visited;
          }
        }
      }
    }
    // ===============================   End OF Mandatory Question logic ============================================================
    return is_visited;
  }

  //=====================================  Submission of Response ===============================================================
  submitForm() {
    this.SpinnerService.show();
    this.userMsgData = {
      name: this.surveylistForm.value.name
        ? this.surveylistForm.value.name
        : false,
      mobile: this.surveylistForm.value.mobile
        ? this.surveylistForm.value.mobile
        : false,
    };

    this.sur_sel = "";
    this.loading = true;
    this.customerdata = {
      name: this.surveylistForm.value.name,
      mobile: this.surveylistForm.value.mobile,
      email: this.surveylistForm.value.email,
      address: this.surveylistForm.value.address,
      city: this.surveylistForm.value.city,
      role_id: this.surveylistForm.value.role_id,
      state: this.surveylistForm.value.state,
      age: this.surveylistForm.value.age,
      gender: this.surveylistForm.value.gender,
      zip: this.surveylistForm.value.zip,
      country: this.surveylistForm.value.country,
      company_id: this.companyId,
      survey: this.companyId,
      added_by: this.userID,
      updated_by: this.userID,
      lat: this.lat,
      lng: this.lng,
    };
    console.log(this.customerdata);
    this.loginservice.customerregistration(this.customerdata).subscribe(
      (response: any) => {
        this.answersubmit(response.data);
        this.surveylistForm.reset();
        this.loading = false;
      },
      (error) => console.log(error)
    );
  }

  answersubmit(response) {
    if (this.matrixOptionsAnswerRecords.length > 0) {
      for (let i = 0; i <= this.matrixOptionsAnswerRecords.length; i++) {
        this.attemptedQuestionsArray.push(this.matrixOptionsAnswerRecords[i]);
      }
    }
    if (this.ratingOptionArray.length > 0) {
      for (let i = 0; i <= this.ratingOptionArray.length; i++) {
        this.attemptedQuestionsArray.push(this.ratingOptionArray[i]);
      }
    }
    if (this.textAreaOptionArray.length > 0) {
      for (let i = 0; i <= this.textAreaOptionArray.length; i++) {
        this.attemptedQuestionsArray.push(this.textAreaOptionArray[i]);
      }
    }
    var data = [];
    var isquestion = true;
    var registrationid = response.id;
    console.log(this.responsetype);
    let submitSurveyData = {
      customer_id: registrationid,
      surveyResponseDataList: this.attemptedQuestionsArray,
      response_type: this.responsetype,
      survey_id: this.surveyId,
      lng: this.lng,
      lat: this.lat,
    };
    this.loginservice.answer_survey(submitSurveyData).subscribe(
      (response: any) => {
        this.SpinnerService.hide();
        this.ThanyouPage(this.surveyId);
      },
      (error) => console.log("submitSurveyData Error ", error)
    );
  }
  extraParam: any;
  checkboxdata: any;

  selectedcheckbox(
    event,
    questionid,
    answer_id,
    answer,
    questiontype_id,
    extraParam
  ) {
    const checked = event.target.checked;
    var textareavalue = extraParam;
    let attemptedQuestion = this.getAttemptedQuestion(questionid);
    if (!attemptedQuestion) {
      this.checkboxdata = {
        questiontype_id: questiontype_id,
        question_id: questionid,
        answer_id: answer_id,
        survey_id: this.surveyId,
        answer: answer,
      };
      this.attemptedQuestion(questionid, this.checkboxdata);
    } else {
      let checkbox_ids_str = attemptedQuestion.answer;
      if (checked) {
        checkbox_ids_str = attemptedQuestion.answer + "," + answer;

        this.checkboxdata = {
          questiontype_id: questiontype_id,
          question_id: questionid,
          answer_id: answer_id,
          survey_id: this.surveyId,
          answer: checkbox_ids_str,
          // answer: checkbox_ids_str + ","  + textareavalue,
        };
        this.attemptedQuestion(questionid, this.checkboxdata);
      } else {
        let checkbox_ids = attemptedQuestion.answer.split(",");
        checkbox_ids = checkbox_ids.filter((checkbox_id) => {
          if (checkbox_id != String(answer)) {
            return checkbox_id;
          }
        });

        this.checkboxdata = {
          questiontype_id: questiontype_id,
          question_id: questionid,
          answer_id: answer_id,
          survey_id: this.surveyId,
          answer: checkbox_ids,
        };
      }
      this.attemptedQuestion(questionid, this.checkboxdata);
    }
  }

  multipleanswer(
    questiontype_id,
    questionid,
    answerid,
    answer,
    dependent_question_id,
    is_dependent
  ) {
    this.selectedValue = 0;
    this.depent_id = dependent_question_id;
    this.is_depent = is_dependent;
    const dataa = {
      questiontype_id: questiontype_id,
      question_id: questionid,
      answer_id: answerid,
      answer: answer,
      survey_id: this.surveyId,
    };
    this.attemptedQuestion(questionid, dataa);
    this.selectedValue = 0;
  }
  countStar(
    questionid,
    answer,
    star,
    nps,
    check,
    dependent_question_id,
    is_dependent,
    question_type_id
  ) {
    this.selectedValue = star;
    this.npsvalue = nps;
    this.depent_id = dependent_question_id;
    this.is_depent = is_dependent;
    this.scalevalue = check;
    if (check != undefined) {
      const stardata = {
        questiontype_id: question_type_id,
        question_id: questionid,
        answer_id: answer,
        answer: check,
        survey_id: this.surveyId,
      };

      this.attemptedQuestion(questionid, stardata);
    } else if (star >= 0) {
      const stardata = {
        questiontype_id: question_type_id,
        question_id: questionid,
        answer_id: answer,
        answer: star,
        survey_id: this.surveyId,
      };
      this.attemptedQuestion(questionid, stardata);
    }

    if (nps != undefined) {
      const stardata = {
        questiontype_id: question_type_id,
        question_id: questionid,
        answer_id: answer,
        answer: nps,
        survey_id: this.surveyId,
      };
      this.attemptedQuestion(questionid, stardata);
    }
  }

  //========================================== Emoji Question type ==================================================
  bad = false;
  average = false;
  good = false;

  emojiAnswers(
    questiontype_id,
    questionid,
    answerid,
    answer,
    dependent_question_id,
    is_dependent,
    emojianswers
  ) {
    if (emojianswers == "bad") {
      this.bad = true;
      this.average = false;
      this.good = false;
    } else if (emojianswers == "average") {
      this.bad = false;
      this.average = true;
      this.good = false;
    } else {
      this.bad = false;
      this.average = false;
      this.good = true;
    }
    //===================== condition ends =============
    this.depent_id = dependent_question_id;
    this.is_depent = is_dependent;
    const dataa = {
      questiontype_id: questiontype_id,
      question_id: questionid,
      answer_id: answerid,
      answer: answer,
      survey_id: this.surveyId,
    };
    this.attemptedQuestion(questionid, dataa);
  }
  //================================================================================================================

  selectedanswer(
    question_type_id,
    questionid,
    answerid,
    answer,
    dependent_question_id,
    is_dependent
  ) {
    if (answer == "") {
      return;
    }
    this.selectedValue = 0;
    const dataa = {
      questiontype_id: question_type_id,
      question_id: questionid,
      answer_id: answerid,
      answer: answer,
      survey_id: this.surveyId,
    };

    this.attemptedQuestion(questionid, dataa);
    this.selectedValue = 0;
  }

  //================================== Thank You Page functionality ====================================================
  ThanyouPage(surveyId) {
    this.SpinnerService.hide();
    this.selectedindexx = -1;
    this.loginservice.thankyou_survey(surveyId).subscribe(
      (response: any[]) => {
        if (response.length == 0) {
          this.message_sucesss = true;
          this.select = false;
          this.SpinnerService.hide();
        } else {
          this.thankyou_response = response[0];
          this.SpinnerService.hide();
          this.surveylistForm.reset();
          this.loading = false;
          this.select = false;
          this.show2 = true;
        }
      },
      (error) => console.log(error)
      // this.SpinnerService.hide();
    );
  }

  attemptedQuestionsArray = [];
  getAttemptedQuestion(question_id) {
    let foundAttemptedQuestion = undefined;
    for (let i = 0; i < this.attemptedQuestionsArray.length; i++) {
      if (this.attemptedQuestionsArray[i].question_id == question_id) {
        foundAttemptedQuestion = this.attemptedQuestionsArray[i];
        break;
      }
    }
    return foundAttemptedQuestion;
  }

  attemptedQuestion(question_id, attemptedInfo) {
    if (this.attemptedQuestionsArray.length == 0) {
      this.attemptedQuestionsArray.push(attemptedInfo);
    }
    let isQuestionAttempted = false;
    for (let i = 0; i < this.attemptedQuestionsArray.length; i++) {
      if (this.attemptedQuestionsArray[i].question_id == question_id) {
        isQuestionAttempted = true;
        let attemptedQuestion = this.attemptedQuestionsArray[i];
        this.attemptedQuestionsArray[i].question_id = attemptedInfo.question_id;
        this.attemptedQuestionsArray[i].answer_id = attemptedInfo.answer_id;
        this.attemptedQuestionsArray[i].answer = attemptedInfo.answer;
      }
    }
    if (isQuestionAttempted == false) {
      this.attemptedQuestionsArray.push(attemptedInfo);
    }
  }
  dropdown_values(question_id, event) {
    let values = event.target.value.split(",");
    this.is_depent = values[1];
    this.depent_id = values[2];

    const dropdowndata = {
      questiontype_id: 5,
      question_id: question_id,
      answer_id: values[0],
      survey_id: this.surveyId,
    };

    this.attemptedQuestion(question_id, dropdowndata);
  }

  getPreviousQuestion(question_id, question_index) {
    let question = this.previuorsQuestionRecords.filter((q) => {
      if (q.depenttt_id == question_id) {
        return q;
      }
    });
    return question[0]; // this.previuorsQuestionRecords[index];
  }

  recordPreviousQuestions(
    question_id,
    questiontypeid,
    depenttt_id,
    questionIndex
  ) {
    this.record_data = {
      questiontype_id: questiontypeid,
      question_id: question_id,
      question_index: questionIndex,
      depenttt_id: depenttt_id,
    };

    console.log("record_data Recordss ", this.record_data);
    var removeindex = this.previuorsQuestionRecords.findIndex(
      (p) => p.question_id == question_id
    );
    if (removeindex == -1) {
      this.previuorsQuestionRecords.push(this.record_data);
    }
    console.log("final Recordss ", this.previuorsQuestionRecords);
  }

  // ======================================== For Next Question Logic =====================================================
  getQuestionByQuestionId(questionId) {
    let index = this.questiondisplay.survey_data_questions.findIndex(
      (p) => p.id == questionId
    );
    return {
      question: this.questiondisplay.survey_data_questions[index],
      question_index: index,
    };
  }

  getQuestionByDependent(depent_id) {
    let index = this.questiondisplay.survey_data_questions.findIndex(
      (p) => p.id == depent_id
    );
    return {
      question: this.questiondisplay.survey_data_questions[index],
      question_index: index,
    };
  }

  getQuestionByIndex(idx) {
    let index = this.questiondisplay.survey_data_questions.findIndex(idx);
    return {
      question: this.questiondisplay.survey_data_questions[index],
      question_index: index,
    };
  }
  // ================================== Get Previours Question from Index value =============================================
  getIndexPrevioursQuestion(index1) {
    let index = this.previuorsQuestionRecords.findIndex(
      (p) => p.question_index == index1
    );
    return {
      question: this.previuorsQuestionRecords[index],
      question_index: index,
    };
  }

  getPrevioursQuestionId(questionId) {
    let index = this.previuorsQuestionRecords.findIndex(
      (p) => p.question_id == questionId
    );
    return {
      question: this.previuorsQuestionRecords[index],
      question_index: index,
    };
  }

  getPrevioursQuestionByNoParams() {
    let questionsSize = this.previuorsQuestionRecords.length;
    let previousQuestion = this.previuorsQuestionRecords[questionsSize - 1];
    this.previuorsQuestionRecords.pop();
    return {
      question: previousQuestion,
      question_index: previousQuestion.question_index,
    };
  }

  // ========================================== For matrix implimentation ==========================================================
  getMatrixOptionsArray(matrix_option_str) {
    let matrix_options = matrix_option_str.split("|");
    return matrix_options;
  }

  //====================================== Matrix Response ===============================================================
  matrixResponse(
    question_type_id,
    question_id,
    answer_id,
    rowValue,
    columnValue
  ) {
    const Matrix_data = {
      questiontype_id: question_type_id,
      question_id: question_id,
      answer_id: answer_id,
      answer: columnValue,
      survey_id: this.surveyId,
    };
    this.matrixOptionArray(Matrix_data, answer_id);
  }
  //=============================================  MatrixOptionArray ====================================================================================//

  matrixOptionArray(Matrix_data, answer_id) {
    if (this.matrixOptionsAnswerRecords.length == 0) {
      this.matrixOptionsAnswerRecords.push(Matrix_data);
    } else {
      let isQuestionAttempted = false;
      for (let i = 0; i < this.matrixOptionsAnswerRecords.length; i++) {
        if (this.matrixOptionsAnswerRecords[i].answer_id == answer_id) {
          isQuestionAttempted = true;
          this.matrixOptionsAnswerRecords[i].question_id =
            Matrix_data.question_id;
          this.matrixOptionsAnswerRecords[i].answer_id = Matrix_data.answer_id;
          this.matrixOptionsAnswerRecords[i].answer = Matrix_data.answer;
          this.matrixOptionsAnswerRecords[i].survey_id = Matrix_data.survey_id;
        }
      }
      if (isQuestionAttempted == false) {
        this.matrixOptionsAnswerRecords.push(Matrix_data);
      }
    }
  }
  //================================================= Refresh survey ===========================================================================
  reload() {
    window.location.reload();
  }
  //================================================  Signature Functionality  ============================================================
  drawComplete(question_type_id, questionid) {
    const secreteCode = "cview120";
    const dataa = {
      questiontype_id: question_type_id,
      question_id: questionid,
      answer_id: null,
      answer: this.signaturePad.toDataURL(),
      survey_id: this.surveyId,
    };
    this.attemptedQuestion(questionid, dataa);
  }
  drawStart() {
    console.log("begin drawing");
  }
  clearSignaturePad(question_type_id, questionid) {
    this.signaturePad.clear();
    const dataa = {
      questiontype_id: question_type_id,
      question_id: questionid,
      answer_id: null,
      answer: null,
      survey_id: this.surveyId,
    };
    this.attemptedQuestion(questionid, dataa);
    this.isSignatureCleared = true;
  }
  //=====================================Slider Question Type===============================================
  slider(question_type_id, questionid, answer) {
    const dataa = {
      questiontype_id: question_type_id,
      question_id: questionid,
      answer_id: null,
      answer: answer,
      survey_id: this.surveyId,
     };
   
    this.attemptedQuestion(questionid, dataa);

  }

  //=============================================  RatingOptionArray ====================================================================================//
  ratingOptionArray = [];
  id = [];
  answerid: any;
  star: any;
  ratingGrid(question_type_id, questionid, answerid, value) {
    this.selectedValue = value;
    this.answerid = answerid;
    const rating_data = {
      questiontype_id: question_type_id,
      question_id: questionid,
      answer_id: answerid,
      answer: this.selectedValue,
      survey_id: this.surveyId,
    };
    this.ratingresponse(rating_data, answerid);
  }
  //=============================================  RatingOptionArray == 17 ====================================================================================//
  ratingresponse(rating_data, answerid) {
    if (this.ratingOptionArray.length == 0) {
      let isQuestionAttempted = true;
      this.ratingOptionArray.push(rating_data);
    } else {
      let isQuestionAttempted = false;
      for (let i = 0; i < this.ratingOptionArray.length; i++) {
        if (this.ratingOptionArray[i].answer_id == answerid) {
          isQuestionAttempted = true;
          this.ratingOptionArray[i].question_id = rating_data.question_id;
          this.ratingOptionArray[i].answer_id = rating_data.answer_id;
          this.ratingOptionArray[i].answer = rating_data.answer;
          this.ratingOptionArray[i].survey_id = rating_data.survey_id;
        }
      }
      if (isQuestionAttempted == false) {
        this.ratingOptionArray.push(rating_data);
      }
    }
  }

  //=============================================  TextareaGrid question type == 16 ====================================================================================//
  textAreaOptionArray = [];
  TextareaGrid(question_type_id, questionid, answerid, answer) {
    if (answer == "" || answer == null) {
      return;
    }
    var Answer = answer.trim();
    const textarea_response = {
      questiontype_id: question_type_id,
      question_id: questionid,
      answer_id: answerid,
      answer: Answer,
      survey_id: this.surveyId,
    };
    this.Textarearesponse(textarea_response, answerid);
  }
  //=============================================  TextareaOptionArray == 16 ====================================================================================//
  Textarearesponse(textarea_response, answerid) {
    if (this.textAreaOptionArray.length == 0) {
      let isAttempted = true;
      this.textAreaOptionArray.push(textarea_response);
    } else {
      let isAttempted = false;
      for (let i = 0; i < this.textAreaOptionArray.length; i++) {
        if (this.textAreaOptionArray[i].answer_id == answerid) {
          isAttempted = true;
          this.textAreaOptionArray[i].question_id =
            textarea_response.question_id;
          this.textAreaOptionArray[i].answer_id = textarea_response.answer_id;
          this.textAreaOptionArray[i].answer = textarea_response.answer;
          this.textAreaOptionArray[i].survey_id = textarea_response.survey_id;
        }
      }
      if (isAttempted == false) {
        this.textAreaOptionArray.push(textarea_response);
      }
    }

    console.log(
      this.textAreaOptionArray,
      "this.textAreaOptionArraythis.textAreaOptionArray"
    );
  }
  //=====================================End of the Survey Sharing =====================================================================
  addClass(star) {
    let ab = "";
    for (let i = 0; i < star; i++) {
      ab = "starId" + i;
      document.getElementById(ab).classList.add("selected");
    }
  }
  removeClass(star) {
    let ab = "";
    for (let i = star - 1; i >= this.selectedValue; i--) {
      ab = "starId" + i;
      document.getElementById(ab).classList.remove("selected");
    }
  }
  lat: any;
  lng: any;
  geolocation() {
    // console.log("ngAfter-geolocation")
    // this.getGeoFancing()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position) {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
            console.log("position-lat",this.lat, "/lng",this.lng);
            let userData = {
              lat:this.lat,
              lng:this.lng,
              user_id:this.userID,
              survey_id:this.surveyId
            }
            this.geofenceService.getUserIsInsideFence(userData).subscribe((res)=>{
              console.log("geolocation-isuserInside-res-1448",res['message'])
              alert(res['message'])
            })
          }
        },
        (error) => console.log(error)
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
}
