import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { loginservice } from '../authapi.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstants } from '../../global-constants';
import {Renderer2} from '@angular/core';


@Component({
  selector: 'ngx-n-forget',
  templateUrl: './n-forget.component.html',
  styleUrls: ['./n-forget.component.scss']
})
export class NForgetComponent implements OnInit {
  public forgotForm: FormGroup;
  public edited_fail = false;
  submitted = false;
  not_registred = false;
  unregistred: any;
  mail_sent = false;
  loading= false;
  selectedlanguage: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: loginservice,
    private translateService: TranslateService,
    private renderer: Renderer2
  ) {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    });
  }
 
  renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    return script;
  }


  ngOnInit() {
    this.renderExternalScript('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit').onload = () => { }

    this.submitted = false;
    if(sessionStorage.getItem('language') == null || sessionStorage.getItem('language') == ''){
      this.selectedlanguage ='en';
    }
   else{
    this.selectedlanguage =sessionStorage.getItem('language');
   }
    this.switchLanguage(this.selectedlanguage);
  }

  forgot() {

    this.submitted = true;
   // stop here if form is invalid
    if (this.forgotForm.invalid) {
        return;
    }
    this.authService.forgot({
      email: this.forgotForm.value.email
    }).subscribe(
      (response) =>   {
        this.loading= false;
        this.submitted = false;
        console.log(response.message.message);
            this.mail_sent= true;
           this.not_registred = false;
           setTimeout(()=>{                           //<<<---using ()=> syntax
             this.mail_sent= false;
            this.router.navigate(['/login']);
            }, 3000);
        //   },
       //   }, 3000);
      //   this.login();
       // this.showToast('top-right', 'success');
       // this.dismiss();
     },
      (error) => {
        console.log(error.error.error);
         this.unregistred = error.error.error;
         this.not_registred = true;
      }
    )
        // if (response) {
        //   // this.toastr.success(response['message']);
        //  
        // } else {
        //   this.edited_fail = true;
        //   setTimeout(function () {
        //     this.edited_fail = false;
        //   }, 3000);
          // this.toastr.error(response['message']);
     //   }
       //  (error) => console.log(error);
  //    }
    //  )
  }

  switchLanguage(language: string) {
    this.selectedlanguage = language;
    sessionStorage.setItem('language', language);
    this.translateService.use(language);
   } 
  otp() {
    this.router.navigate(['/otp']);
  }

  login() {
  
   this.router.navigate(['./login']);
  }

}
