import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GlobalConstants } from "../global-constants";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class planservice {
  public _refeshneeded$ = new Subject<void>();
  constructor(private http: HttpClient) { }
  public server = GlobalConstants.apiURL;

  //---------------------------------Get Refesh Needed API--------------------------------------------

  getrefeshneeded$() {
    return this._refeshneeded$;
  }
  //---------------------------------Get Module service API--------------------------------------------
  getModuleTypeWithServices() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/plans-pricing/module-types-with-services/", { headers })
  }


  //---------------------------------Create Plan API---------------------------------------------------
  createPlan(plandata) {
    // for token
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server + "api/sadmin/plans-pricing/create-plan", plandata, { headers });
  }

  //---------------------------------Get Plan with all services API---------------------------------------------------
  planWithServices() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    //get plan API
    return this.http.get(this.server + "api/sadmin/plans-pricing", { headers });
  }

  //---------------------------------Active Plan API---------------------------------------------------
  approvePlanService(plan_id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(this.server + "api/sadmin/plans-pricing/active-plan/" + plan_id, {}, { headers }).pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }
  //---------------------------------After Active Plan Refresh data API---------------------------------------------------
  refresh() {
    return this._refeshneeded$;
  }
  //---------------------------------Get Plan By Id API---------------------------------------------------
  getPlanById(planId) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/sadmin/plans-pricing/plan/" + planId, { headers })
  }

  //---------------------------------Update Plan API---------------------------------------------------
  updatePlan(plandata) {
    //for token
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(this.server + "api/sadmin/plans-pricing/update-plan", plandata, { headers });
  }

  //---------------------------------Redirect to Payment API---------------------------------------------------
  pay(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server + `api/payment/stripe/create-transaction`, data, { headers });
  }

  //---------------------------------Get All Plan Deatails API---------------------------------------------------
  getAllPlanDeatailsOnPricingPage() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/plans-and-pricing", { headers })
  }
  //---------------------------------Webhook Trigger API---------------------------------------------------
  webhookTrigger(sid) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server + `api/payment/response/webhook/trigger`, { sessionId: sid }, { headers });
  }
  //---------------------------------CCAvvenue txn check Trigger API---------------------------------------------------
  CCAvvenueTxnTrigger(txnId) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + `api/payment/response/ccavvenue-txn-sync/`+txnId, { headers });
  }
  //---------------------------------Transaction Details API---------------------------------------------------
  transactionDetails(company_id) {

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/payment/transactions/company/" + company_id, { headers })
  }

  //---------------------------------Transaction Details API---------------------------------------------------
  pricing(countryCode) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    headers = headers.append("country-code",countryCode);
    // return this.http.get(this.server + "api/plans-and-pricing", { headers });
    return this.http.get(this.server + "api/plans-and-pricing", { headers });
  }

  //---------------------------------Dynamic Currency change API---------------------------------------------------
  currency() {
    return this.http.get("https://free.currconv.com/api/v7/convert?q=INR_USD&compact=ultra&apiKey=e7958e6e816cc18e7211");
  }

  //---------------------------------Discount API---------------------------------------------------
  discount() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/plans-and-pricing/discounts", { headers });

  }
  //---------------------------------Plan Info In Add User API [For Applying Check]--------------------------------------
  planInfoInAddUser() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/user-services/verify-user-count", { headers });

  }
  //---------------------------------Plan Info In Create Survey API [For Applying Check]--------------------------------------
  planInfoInCreateSurvey() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/user-services/verify-survey-count", { headers });

  }
  //---------------------------------Plan Info In Survey Response Count API [For Applying Check]--------------------------------------
  planInfoInStartSurveyForSurveyResponse() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/user-services/verify-survey-response-count", { headers });

  }
  //---------------------------------Plan Info In Survey Response Count API [For Applying Check]--------------------------------------
  planInfoInCreateSurveyForSurveyQuestion() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/user-services/verify-survey-question-count", { headers });

  }
  //---------------------------------Free And Trial Plant API [For Applying Check]--------------------------------------
  freeAndTrialPlan(data) {
    let token =  window.sessionStorage.getItem("token");
    // console.log(token)
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
   
    return this.http.post(this.server + "api/payment/transaction/free-trial/create-transaction",data,{ headers });

  }

  //--------------------------------- API--------------------------------------
  activePlanInfo() {
    let token =  window.sessionStorage.getItem("token");
    // console.log(token)
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
   
    return this.http.get(this.server + "api/user-services/active-plan-info",{ headers });

  }
  //--------------------------------- API--------------------------------------
  currencyDetailsByPlanId(planId){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/cadmin/get-plan-currency-information/plan/"+planId,{ headers });

  }

  //--------------------------------- API--------------------------------------
  
  verifyPayment(txn_id, session_id) {
    // let token =  window.sessionStorage.getItem("token");
    // console.log(token)
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.put(this.server + "api/payment/sync-transaction",{txn_id:txn_id,session_id : session_id},{ headers });

  }
  //---------------------------------Verify Expired Plan Info API--------------------------------------
  verifyExpiredPlanInfo(){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/user-services/verify-expired-plan-info",{ headers });
  }
 
  //---------------------------------Get Custom Plan Info API--------------------------------------
  getCustomPlan(company_id){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + 'api/plans-and-pricing/custom-plans/company/'+company_id, { headers });
  }
  //--------------------------------- API--------------------------------------
  getAllActiveCompany(customPlanId){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + 'api/companies/active-companies/plan/'+customPlanId, { headers });
  }
  //--------------------------------- API--------------------------------------
  ///api/sadmin/custom-plan/assign-company-list/plan/:id
  getAllAssignCustomPlanCompany(plan_id){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + 'api/sadmin/custom-plan/assign-company-list/plan/'+plan_id, { headers });
  }
  //--------------------------------- API--------------------------------------
  assignCustomPlanApi(data){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.post(this.server + 'api/sadmin/custom-plan/assign-for-company',data,{ headers }).pipe(
      tap(() => {
        this._refeshneeded$.next();
      })
    );
  }
  //--------------------------------- API--------------------------------------
  getAllActiveAndAvailableUserApi(companyId){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + 'api/user-services/company/'+companyId+'/active-user-counts/', { headers }); 
  }
  //--------------------------------- API--------------------------------------
  getCurrentIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
    }
  //--------------------------------- API--------------------------------------
  getContryCodeByIP(ipJson) {
  return this.http.post<any>(this.server + `api/auth/country-code-by-ip`, ipJson);
  }
  //--------------------------------- API--------------------------------------
  getPlanPriceAccordingToCountry(currentCountryCode){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
   
    return this.http.get(this.server + "api/cadmin/get-plan-price-according-to-country/"+currentCountryCode,{ headers });

  }
  //--------------------------------- API--------------------------------------
  PlanCurrencyDetails(planId){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
    return this.http.get(this.server + "api/sadmin/get-plan-currency-details/plan/"+planId,{ headers });
  }
  //--------------------------------- API--------------------------------------
  deleteCurrencyDetailsById(id){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
    return this.http.get(this.server + "api/sadmin/delete-plan-currency-details/"+id,{ headers });
  }
  //--------------------------------- API--------------------------------------
  getAllCountries(){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
    return this.http.get(this.server + "api/sadmin/get-plan-countries-information/",{ headers });
  }

  //--------------------------------- API--------------------------------------
  getAllCurencies(){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
    return this.http.get(this.server + "api/sadmin/get-plan-currencies-information/",{ headers });
  }
  //--------------------------------- API--------------------------------------
AllPublicServices(){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.get(this.server + "api/plans-and-pricing/all-services",{ headers });
}
//----------------------------------- API ------------------------------------

getAllServiceTypes(){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.get(this.server + "api/sadmin/plans-pricing/services-types",{ headers });
}
//----------------------------------- API ------------------------------------

getAllServices(){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.get(this.server + "api/sadmin/plans-pricing/survey-services",{ headers }).pipe(
    tap(() => {
      this._refeshneeded$.next();
    })
  );
}
//----------------------------------- API ------------------------------------

createService(serviceObj){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.post(this.server + "api/sadmin/plans-pricing/create-survey-service",serviceObj,{ headers });
}
//----------------------------------- API ------------------------------------

getServiceByServiceId(serviceId){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.get(this.server + "api/sadmin/plans-pricing/survey-services/service/"+serviceId,{ headers });
}
//----------------------------------- API ------------------------------------

updateService(serviceObj){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.put(this.server + "api/sadmin/plans-pricing/update-survey-service",serviceObj,{ headers }).pipe(
    tap(() => {
      this._refeshneeded$.next();
    })
  );
}
//----------------------------------- API ------------------------------------

getServiceByServiceTypeId(serviceTypeId){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.get(this.server + "api/sadmin/plans-pricing/survey-services/service-type/"+serviceTypeId,{ headers });
}
//----------------------------------- API ------------------------------------

activeInactiveService(serviceId){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.put(this.server + "api/sadmin/plans-pricing/active-inactive/survey-service/"+serviceId,{},{ headers });
}
//----------------------------------- API ------------------------------------
planServiceRestriction(){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.get(this.server + "api/plans-and-pricing/all-services/with-only-active-plan-services",{ headers });
}

planServiceRestrictionForContactImport(id){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.get(this.server + "api/plans-and-pricing/get-plan-information-for-imports/" + id,{ headers });
}


//----------------------------------- API ------------------------------------
planServiceRestrictionInSurveySharing(company_id){
  return this.http.get(this.server + "api/plans-and-pricing/all-services/with-only-active-plan-services-by-companyId/"+company_id);
}
//----------------------------------- API ------------------------------------
//--------------------------------- API-------------------------------------- 
  //----------------------------------- API ------------------------------------
  _getPlanModulesWithServices(){
  return this.http.get(this.server + `api/plans-pricing/module-types-with-services-by-reordering`); 
  }
  //----------------------------------- API ------------------------------------
  getAllQuestionTypes() {
    return this.http.get(this.server + "api/questionType/get-all-question-types");
  }
//----------------------------------- API ------------------------------------
getAllCountryFrom(){
  return this.http.get(`https://restcountries.eu/rest/v2/all`);

  
}

//----------------------------------- API ------------------------------------
updateLogoutDeatailsOfCompany(company_id){
  return this.http.get(this.server +`api/login-logs/update-date-time-on-company-logout/${company_id}`);
}
//----------------------------------- API ------------------------------------
updateLogoutDeatailsOfSuperAdmin(){
  return this.http.get(this.server +`api/login-logs/update-date-time-on-super-admin-logout`);
}
//----------------------------------- API ------------------------------------
encryptdata(orderParams){
  let data = {
  request : orderParams
  }
   return this.http.post(this.server +`api/handleccavenueresponse`, {params:data});
}
//----------------------------------- API ------------------------------------
CreatePayementTransationforCCAvenue(orderParams) {
  console.log(orderParams);
  return this.http.post(this.server + `api/CreatePayementTransationforCCAvenue`, orderParams);
  }
//----------------------------------- API ------------------------------------
getcompanyDetails(id){
  let headers: HttpHeaders = new HttpHeaders();
  headers = headers.append("x-access-token", sessionStorage.getItem("token")); 
  return this.http.get(this.server + "api/companies/get/"+id,{ headers });
}
//----------------------------------- API ------------------------------------
}