import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GlobalConstants } from "../global-constants";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class loginservice {
  public server = GlobalConstants.apiURL;
  private _loginUrl = this.server + "api/auth";
  user: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private SpinnerService: NgxSpinnerService) {}

  verifyLoginToken(linktoredirect) {
   
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this._loginUrl + '/verify-login-token',{headers})
    .pipe(
      // catchError(err => {
      //     console.log('Handling error locally and rethrowing it...', err);
      //     return throwError(err);
      // })
      
  )
  .subscribe(
    (result:any) =>{
        console.log('HTTP response', result);
        if(result.success == false){
            this.SpinnerService.hide();
            this.logout();
            this.router.navigate([linktoredirect]);
        }
        return result;
      },
      err => {
        console.log('HTTP Error', err)
        this.SpinnerService.hide();
        this.logout();
        this.router.navigate([linktoredirect]);
      },
      () => console.log('HTTP request completed.')
  );
    }

  loginUser(user) {
    return this.http.post<any>(this._loginUrl + `/web/signin`, user);
  }
  SuperAdminLoginAsACompany(data){
    return this.http.post<any>(this.server + `api/auth/sadmin/authentication-company-signin`, data);
  }
  loginUserByGoogle(userData){
    return this.http.post<any>(this._loginUrl + `/web/google/signin`, userData);

  }

  logout(){
    // remove user from local storage to log user out
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    sessionStorage.getItem("userData");
    sessionStorage.getItem("token");

    sessionStorage.clear();
    localStorage.clear();
  }

  displaycategory() {
    return this.http.get(this.server + "api/categories/getAll");
  }

  // calling forgot password user API
  forgot(params: any): Observable<any> {
    return this.http.post(this._loginUrl + `/forgotPassword`, params);
  }

  resetPassword(params: any) {
    return this.http.post(this._loginUrl + `/generate_pass`, params);
  }

  findAllUserData(id) {
    return this.http.get(this.server + `api/registration/getUserCompany/` + id);
  }

  changePassword(params: any): Observable<any> {
    return this.http.post(this._loginUrl + `/changePassword`, params);
  }
  question_get(id) {
    return this.http.get(
      this.server + "api/survey-data/surveyGetAllData/" + id
    );
  }
  getSurveyFields(id) {
    // let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    return this.http.get(this.server + "api/survey_fields/survey/" + id);
  }

  customerregistration(value) {
    return this.http.post(this.server + "api/registrations/create", value);
  }

  answer_survey(value) {
   return this.http.post(
      this.server + "api/survey-data-customer-response/save-webshare-response",
      value,
    );
  }
  uploadfile(formData) {
    return this.http.post(this.server + "api/uploadfile", formData);
  }

  welcome_survey(id) {
    return this.http.get(this.server + "api/welcome/getbysurvey/" + id);
  }
  web_survey_data(company_id,survey_id) {
    return this.http.get(this.server + "api/companies/survey-web-share/survey/" + survey_id + "/company/" + company_id);
  } 
  thankyou_survey(id){
    return this.http.get(this.server + "api/thankyou/getbysurvey-web-share/" + id);
  }
  linkedInLogin(){
    return this.http.get(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id= 78bb3n4vca5asx &redirect_uri=${this.server}%2Fauth%2Flinkedin%2Fcallback&state=fooobar&scope=r_liteprofile%20r_emailaddress%20w_member_social`);
  }
  //============================================ Ip addresss ===============================================================
   ipaddressResonseCount(id,postdata){
    return this.http.post(this.server + "api/auth/survey-response-count-by-ip/survey/" + id, postdata);
  }
//============================================Response Count survey =============================================================
  ResponseCountforSharing(id){
    return this.http.get(this.server + "api/survey-data-customer-response/response-count/survey/" + id);
  }

getGeoData(surveyID){
  console.log(surveyID);
  return surveyID;
}

saveGeoData(geoData){
  console.log(geoData);
  return geoData;
}
getThemeBySurveyId(id){
  return this.http.get(this.server + "api/custom_theme/getThemeDataForSurveyid/" + id);
 }

}
