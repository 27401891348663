import { Component, OnInit, ElementRef, ViewChild, AfterViewInit,NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import * as L from 'leaflet';
//import {latLng, MapOptions, tileLayer} from 'leaflet';
import {latLng, MapOptions, tileLayer, Map, Marker, icon} from 'leaflet';


import { loginservice } from '../../authapi.service';


@Component({
  selector: 'ngx-cview-geo-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  drawAreaList = [{id:1 , name : "Circle"},{id:2 , name : "Plolygon"},{id:3 , name : "Plolygon Lines"},{id:4 , name : "Rectangle"}];
  public selectedDrawmapid = 3;
  circleRadiusList = [
    0.2,0.5,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20
  ];
  public selectedCircleRadius = 1; //1km
  
  map: Map;
  mapOptions: MapOptions;

  latitude = 22.71159;
  longitude = 75.87433;

  //coordinates = [this.latitude, this.longitude];
  polygonLineArray : any[];

  constructor(private router: Router,private Loginservice : loginservice) {
    this.polygonLineArray = [];
  }


  ngOnInit() {
    this.mapInit(); 
    //this.initializeMapOptions(); 
    this.getGeoData(123); 
  }

  getGeoData(surveyId){
    this.Loginservice.getGeoData(surveyId).subscribe((response) => {
      console.log("getGeoData = > ",response);
    })
  }

  saveGeoData(geoData){
    this.Loginservice.saveGeoData(geoData).subscribe((response) => {
      console.log("saveGeoData = > ",response);
    })
  }
  /********************************************************************************************************* */

  private initializeMapOptions() {
    this.mapOptions = {
      center: latLng(this.latitude, this.longitude),
      zoom: 5,
      layers: [
        tileLayer(
          'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            maxZoom: 18,
            attribution: 'Map data © OpenStreetMap contributors'
          })
      ],
    };
  }

  private addSampleMarker() {
    const marker = new Marker([this.latitude, this.longitude])
      .setIcon(
        icon({
          iconSize: [25, 41],
          iconAnchor: [13, 41],
          iconUrl: 'assets/marker-icon.png'
        }));
    marker.addTo(this.map);
  }

  onMapReady(map: Map) {
    this.map = map;
    this.addSampleMarker();
  }

  /********************************************************************************************************* */

  private mapInit(){
    //this.polygonLineArray = [];
    const provider = new OpenStreetMapProvider();

    /*const searchControl = new GeoSearchControl({
      provider: provider,
    });*/
    const searchControl =  GeoSearchControl({
      provider: provider, // required
      showMarker: true, // optional: true|false  - default true
      showPopup: false, // optional: true|false  - default false
      marker: {
        // optional: L.Marker    - default L.Icon.Default
        icon: new L.Icon.Default(),
        draggable: true,
      },
      popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label,
      resultFormat: ({ result }) => result.label, // optional: function    - default returns result label
      maxMarkers: 1, // optional: number      - default 1
      retainZoomLevel: true, // optional: true|false  - default false
      animateZoom: true, // optional: true|false  - default true
      autoClose: false, // optional: true|false  - default false
      searchLabel: 'Enter address', // optional: string      - default 'Enter address'
      keepResult: false, // optional: true|false  - default false
      updateMap: true, // optional: true|false  - default true
    });

    const mymap = new L.Map('mapid').setView([this.latitude, this.longitude], 5);;
    mymap.addControl(searchControl);

    
     //var mymap = L.map('mapid').setView([this.latitude, this.longitude], 2);
    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
     // zoom: 12,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1Ijoia2FtbGVzaGdvdXIxMjMiLCJhIjoiY2tsZ3Z0ZnkyMXg1czJ3czR6NjVlcDcwNiJ9.2DDzFxJfIPO_BrWbGXHFRg'
    }).addTo(mymap);


    var marker = L.marker([this.latitude, this.longitude]).addTo(mymap);

    //this.polygonLineArray = [];

    marker.bindPopup("<b>Hello !</b><br>This is current loaction.").openPopup();

    /*
    //standalone popup for given location
    var popup = L.popup()
      .setLatLng([51.5, -0.09])
      .setContent("I am a standalone popup.")
      .openOn(mymap);
    */
    
    
   mymap.on('click', onMapClick);

   
    // let boundedArea = [
    //   [ 65.94647177615741, 146.95312500000003],
    //   [ 67.33986082559097,  62.57812500000001],
    //   [ 41.77131167976407, 43.94531250000001],
    //   [33.137551192346145, 105.11718750000001],
    //   [ 65.65827451982663, 148.00781250000003]
    // ]
    // let isInside = this.isPointInPolygon(50.73646, 93.16406, boundedArea);
    // console.log("isInside = ",isInside);
    

  let polygonLineArray = []
/*
 // Attribution options
  var attrOptions = {
    prefix: 'CviewSurvey sample'
  };

  // Creating an attribution
  var attr = L.control.attribution(attrOptions);
  // Adding attribution to the map
  attr.addTo(mymap);
*/
  
  // let latlngs : number[][] =  [[18.739046, 80.505755], [15.892787, 77.236081]];
  // var rectOptions = {color: 'Red', weight: 1}
  // var rectangle = L.rectangle(latlngs, rectOptions);
  // rectangle.addTo(mymap);

  
  function markerDrag(e){
    alert("You dragged to: " + e.latlng);
  }
  
  let coordiNates = [[
    [19.394067895396613, 79.9370726197958],
    [19.311143355064655, 78.57476793229581],
    [18.97902595325528, 78.1792601197958],
    [18.437924653474408, 78.00347886979581],
    [17.853290114098012, 77.82769761979581],
    [17.09879223767869, 77.73980699479581],
    [16.636191878397664, 77.73980699479581],
    [16.256867330623454, 77.73980699479581],
    [16.29905101458183, 78.53082261979581],
    [16.846605106396304, 79.2778929322958],
    [17.09879223767869, 80.33258043229581],
    [17.308687886770034, 80.99176011979581],
    [17.895114303749143, 80.33258043229581],
    [18.312810846425457, 79.67340074479581],
    [18.812717856407776, 79.8931273072958],
    [19.311143355064655, 79.8931273072958],
  ],
  [
    [30.183121842195515, 74.13629136979581],
    [30.06909396443887, 74.48785386979581],
    [30.03105542654023, 75.14703355729581],
    [29.916852233070173, 75.54254136979581],
    [29.99300228455108, 76.06988511979581],
    [30.183121842195515, 76.2456663697958],
    [30.41078179084589, 76.42144761979581],
    [30.486550842588485, 76.68511949479581],
    [30.637912028341123, 76.72906480729581],
    [30.86451022625836, 76.50933824479581],
    [31.052933985705163, 76.15777574479581],
    [31.353636941500987, 75.85015855729581],
    [31.615965936476076, 75.54254136979581],
    [31.80289258670676, 75.19097886979581],
    [31.80289258670676, 74.92730699479581],
    [31.39115752282472, 74.75152574479581],
    [30.86451022625836, 74.79547105729581],
    [30.524413269923986, 74.48785386979581],
    [30.29701788337205, 74.13629136979581]
  ]
]

  function fetchGeoJsonForDrawOnMap(companyId, surveyId, userId){
    let url = `http://localhost:8090/api/geo-fencing/get_survey_geofencing/123`
    fetch(url)
    .then(function(response) {
      return response.json();
    })
    .then(function(json) {
      console.log("fetchGeoJsonForDrawOnMap = ",json);
      //json.type  = "Polygon";
      //json.coordinates = json.coordinates;
      //let geojsonFeature = json //{type : "Polygon", coordinates: json.coordinates};
      var geojsonFeature : [number, number][]  = JSON.parse(json).coordinates[0].points;
      
      console.log("fetchGeoJsonForDrawOnMap geojsonFeature = ",typeof geojsonFeature);
      //L.geoJSON(geojsonFeature).addTo(mymap);
      
      var latlngs: [number, number][]  = [[19.394067895396613,79.9370726197958],[19.311143355064655,78.57476793229581],[18.97902595325528,78.1792601197958],[18.437924653474408,78.00347886979581],[17.853290114098012,77.82769761979581],[17.09879223767869,77.73980699479581],[16.636191878397664,77.73980699479581],[16.256867330623454,77.73980699479581],[16.29905101458183,78.53082261979581],[16.846605106396304,79.2778929322958],[17.09879223767869,80.33258043229581],[17.308687886770034,80.99176011979581],[17.895114303749143,80.33258043229581],[18.312810846425457,79.67340074479581],[18.812717856407776,79.8931273072958],[19.311143355064655,79.8931273072958]]
      var polylines = L.polyline(geojsonFeature,{color: 'red'}).addTo(mymap);
      polylines.bindPopup("I am a polylines.");
    }).catch((err)=>{
      console.log("fetchGeoJsonForDrawOnMap err",err);
    })
  }

 
fetchGeoJsonForDrawOnMap(12,14,1333);
let geoData = {
  area_name : "indore palasia",
  coordinates : coordiNates,
  user_id : 12,
  survey_id : 123,
  company_id : 123,
  geo_data_type : "survey-geo-data",  
  geo_draw_type : "polygon-lines"
}
//saveGeoData(geoData);
function saveGeoData(geoData) {

    let url = `http://localhost:8090/api/geo-fencing/create_survey_geofencing`
    fetch(url,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(geoData)
    }).then(function(response) {
        return response.json();
    }).then(function(json) {
        console.log("saveGeoData",json);  
    }).catch((err)=>{
      console.log("saveGeoData err",err);
    })

}

  function onMapClick(e) {
    /*
    var marker = new L.Marker(e.latlng, {draggable:true});
    marker.bindPopup("<strong>"+e.latlng+"</strong>").addTo(mymap);
    marker.on('dragend', markerDrag);
    */

   

    console.log("onMapClick = ",e.latlng);  

    console.log("onMapClick lat = ",e.latlng.lat);  
    console.log("onMapClick lng = ",e.latlng.lng);  
    let lat = e.latlng.lat;
    let lng = e.latlng.lng;

    

    let popup = L.popup();

    //this.polygonLineArray.push(e.latlng);
    polygonLineArray.push([lat,lng]);
    console.log("polygonLineArray =  ",polygonLineArray);

    popup
      .setLatLng(e.latlng)
      .setContent("You clicked the map at " + e.latlng.toString())
      .openOn(mymap); 
      
    //polygonLineArray.push([lat, lng]);  
    let drawId = this.selectedDrawmapid ? this.selectedDrawmapid : 3 ;
    console.log("Draw drawId .......",drawId);
    if(drawId == 1){
      var circle = L.circle([lat, lng], {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
        radius: 10000 * this.selectedCircleRadius ? this.selectedCircleRadius : 1
      }).addTo(mymap);
      circle.bindPopup("I am a circle.");
    }else if(drawId == 2){
      var polygon = L.polygon(polygonLineArray,{color: 'red'}).addTo(mymap);
      polygon.bindPopup("I am a polygon.");

    }else if(drawId == 4){
      var rectOptions = {color: 'Red', weight: 1}
      var rectangle = L.rectangle(polygonLineArray,rectOptions).addTo(mymap);
      rectangle.bindPopup("I am a rectangle.");
      
    }else if(drawId == 3){
      //console.log("polyline ");
      var polylines = L.polyline(polygonLineArray,{color: 'red'}).addTo(mymap);
      polylines.bindPopup("I am a polylines.");
    }

      
      /**************************************Private function*******************************************/
      

  }
}
  
 
  drawOnMap(mymap,latitude,longitude){
    
    console.log("drawOnMap polygonLineArray =  ",this.polygonLineArray);

    let drawId = 1; //this.selectedDrawmapid ? this.selectedDrawmapid : 3 ;
    console.log("Draw drawId .......",drawId);
    if(drawId == 1){
      var circle = L.circle([latitude, longitude], {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
        radius: 10000 * this.selectedCircleRadius ? this.selectedCircleRadius : 1
      }).addTo(mymap);
      circle.bindPopup("I am a circle.");
    }else if(drawId == 2){
      var polygon = L.polygon(this.polygonLineArray).addTo(mymap);
      polygon.bindPopup("I am a polygon.");

    }else if(drawId == 4){
      var rectangle = L.rectangle(this.polygonLineArray).addTo(mymap);
      rectangle.bindPopup("I am a rectangle.");
      
    }else if(drawId == 3){
      var polylines = L.polyline(this.polygonLineArray).addTo(mymap);
      polylines.bindPopup("I am a polylines.");
    }

  }

  isPointInPolygon(latitude, longitude, polygon) {

    if (typeof latitude !== 'number' || typeof longitude !== 'number') {
      throw new TypeError('Invalid latitude or longitude. Numbers are expected')
    } else if (!polygon || !Array.isArray(polygon)) {
      throw new TypeError('Invalid polygon. Array with locations expected')
    } else if (polygon.length === 0) {
      throw new TypeError('Invalid polygon. Non-empty Array expected')
    }
  
    const x = latitude; const y = longitude
  
    let inside = false
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0]; const yi = polygon[i][1]
      const xj = polygon[j][0]; const yj = polygon[j][1]
  
      const intersect = ((yi > y) !== (yj > y)) &&
              (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
      if (intersect) inside = !inside
    }
  
    return inside
  };

  isMarkerInsidePolygon(marker, poly) {
    var polyPoints = poly.getLatLngs();       
    var x = marker.getLatLng().lat, y = marker.getLatLng().lng;

    var inside = false;
    for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
        var xi = polyPoints[i], yi = polyPoints[i];
        var xj = polyPoints[j], yj = polyPoints[j];

        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
  };

  onDrawAreaChange(drawID){
    console.log("onDrawAreaChange ",drawID);
    this.selectedDrawmapid = drawID;
  }

  onCircleRadiusChange(radiusInKM){
    console.log("onCircleRadiusChange ",radiusInKM);
    this.selectedCircleRadius = radiusInKM;
  }

  saveBoundry(){
    console.log("saveBoundry...");
  }
} 


