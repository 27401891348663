import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import crypto from 'crypto-js';
import { NgxSpinnerService } from "ngx-spinner";
import { loginservice } from '../authapi.service';
import { planservice } from '../../services/planapi.service';
import {Renderer2} from '@angular/core';

//FOR Social Login 
import { GoogleLoginProvider, FacebookLoginProvider, LinkedinLoginProvider, AuthService } from 'angular-6-social-login';
import { SocialLoginModule, AuthServiceConfig } from 'angular-6-social-login';
import { GlobalConstants } from '../../global-constants';
import { CompanyService } from '../../pages/company/company.service';

@Component({
  selector: 'ngx-c-login',
  templateUrl: './c-login.component.html',
  styleUrls: ['./c-login.component.scss']
})
export class CLoginComponent implements OnInit {
  public loginForm: FormGroup;
  submitted = false;
  form: FormGroup;
  response: any;
  edited = false;
  public edited_fail = false;
  edited_fail_text:string='';
  fieldTextType: any;
  selectedlanguage: string;
  browserDetails:any;
  loginFormHide:boolean=false;
  rootURL:string= GlobalConstants.web_domain
  sendVerificationLink:boolean=false
  public constructor(
    private loginService: loginservice,
    private planservice: planservice,
    private router: Router,
    private http: HttpClient,
    public formbuilder: FormBuilder,
    private toastr: NbToastrService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private SpinnerService: NgxSpinnerService,
    private socialAuthService: AuthService,
    private renderer: Renderer2,
    private CompanyService: CompanyService,

  ) {
    this.loginForm = this.formbuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ["", [Validators.required,Validators.minLength(6)]],
      // password: ["", [Validators.required,Validators.pattern("^([a-zA-Z0-9!@#$%^&*()_+-=<>?]{6,15})$"),]],//  Validators.minLength(6),,Validators.pattern("/^\S*$/"),
      role_id: [2],
    });
    translateService.setDefaultLang('en');
  }


  ngOnInit() {
    this.deleteCookie('googtrans')
    this.setCookie('googtrans','/en',100,'')
    this.renderExternalScript('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit').onload = () => {
      console.log('Google API Script with full language loaded');
    }

    let key = this.activatedRoute.snapshot.queryParamMap.get('key');
    let accessKeyForSuperAdmin = this.activatedRoute.snapshot.queryParamMap.get('accessKey');
    if (key) {
      // let key = this.activatedRoute.snapshot.params.key;
      console.log('key from query params:', key);
      let replacedkey = key.split('xMl3Jk').join('+');
      replacedkey = replacedkey.split('Por21Ld').join('/');
      replacedkey = replacedkey.split('Ml32').join('=');

      console.log('replaced key', replacedkey);
      window.localStorage.setItem('encryptedKey', replacedkey)
      // const secreteCode = "cview120";
      // const decryptedKey = crypto.AES.decrypt(replacedkey, secreteCode).toString(crypto.enc.Utf8);
      // console.log('Decrypted Key :',decryptedKey);
    }if(accessKeyForSuperAdmin){
      this.SpinnerService.show();
      let replacedAkey = accessKeyForSuperAdmin.split('xMl3Jk').join('+');
      replacedAkey = replacedAkey.split('Por21Ld').join('/');
      replacedAkey = replacedAkey.split('Ml32').join('=');
      let secreteAccessCode = 'cview!@#$%^&*()_+Survey';
      const decryptedAccessKey = crypto.AES.decrypt(replacedAkey, secreteAccessCode).toString(crypto.enc.Utf8);
      // console.log('decryptedAccessKey Key :',decryptedAccessKey);
      // console.log('decryptedAccessKey Key :', JSON.parse(decryptedAccessKey));
      this.superAdminAccessDirectLoginToCompanyAdmin( JSON.parse(decryptedAccessKey))
    }
    else {
      // window.localStorage.removeItem('encryptedKey')
      console.log('Decrypted Key so key not found.');
    }
       this.browserDetails = this.myBrowser();
  }
private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return '';
}

private deleteCookie(name) {
    this.setCookie(name, '', -1);
}

private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    let d:Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires:string = `expires=${d.toUTCString()}`;
    let cpath:string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
}
//===========================================================================================
  submitForm() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    if(!window.navigator.onLine){
      this.edited_fail = true;
      this.edited_fail_text = 'No Internet Connection.'
      return;
    }
    this.loginService.loginUser({
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      role_id: 2, //For Company admin only
      browser_details:this.browserDetails,
    }).subscribe((response: any) => {
      sessionStorage.setItem('token', response["accessToken"]);
      localStorage.setItem('token', response["accessToken"]);
      this.getAllData(response);
    }, error => {
      if( error.error.message=="Email not verified"){
        this.edited_fail=false
        this.loginFormHide=true;
       }
      this.SpinnerService.hide();
      this.edited_fail = true;
      this.edited_fail_text = error.error.message!="Email not verified"?'Please Enter valid Credentials!':"Email not verified"
      this.dismiss();
    });
  }
  get f() { return this.loginForm.controls; }

  activeplan() {
    this.planservice.activePlanInfo().subscribe((data: any) => {
      if (data.data.remainingDays.free.isPlanExpired || data.data.remainingDays.paid.isPlanExpired || data.data.remainingDays.trial.isPlanExpired) {
        // console.log('verify paln if condition ')
        window.sessionStorage.setItem('thisUserCurrentPlanIsExpired', 'expired');
        this.router.navigate(["./plan-pricing"]);
      } else {
        window.sessionStorage.setItem('thisUserCurrentPlanIsExpired', 'not-expired');
      }

    });
    (error) => {
      // console.log('Find active plan loading API Error',error);
    };
  }

  getAllData(response) {

    let id = response.id
    // console.log(response,"responseseeee");
    this.loginService.findAllUserData(id).subscribe((response) => {
      this.SpinnerService.hide();
      //  console.log(response);
      if (response["data"].role_id != 2 || response["data"].company.IsDeletedBy == 1) {
        this.edited_fail = true;
        this.edited_fail_text = 'Please Enter valid Credentials!'
        this.edited = false;
      } else {
        var data = JSON.stringify(response["data"]);
        sessionStorage.setItem('userData', data);
        sessionStorage.setItem('isLoggedin', 'true');
        this.edited = true;
        this.edited_fail = false;
        this.activeplan();
        this.sucess_login();

      }
    })
  }
 
//================================= Social login with google =======================================
public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        console.log(socialPlatform + " sign in data : ", userData);
        let str = userData.name
        let index = str.indexOf(' ')
        str.substring(0, index)
        // console.log( str.substring(0, index)+userData.id.substring(0, 4));
        let genratedPasswordForSocialLogin = str.substring(0, index)+userData.id.substring(0, 4)
            //  ======================== ADD Company By Social Login =======================================
      this.CompanyService.addCompany({
        company_name: userData.name + "'s Company",
        company_email: userData.email,
        admin_name: userData.name,
        category_id: 9,
        password: genratedPasswordForSocialLogin,
        social_login_id: userData.id,
        login_type: "Google",
      }).subscribe(
        (response: any) => {
          //this.edited = true;
         //this.edited_fail = false;
        //  this.error = null;
          setTimeout(() => {
            this.edited = true;
            this.edited_fail = false;
            this.getloginData(userData.email,genratedPasswordForSocialLogin);
          }, 2000);
        },
        (error: any) => {
          this.SpinnerService.hide();
          if(error.error.message == "Company already registered with this email!"){
            this.getloginData(userData.email,genratedPasswordForSocialLogin);
           }
        }
      );
    });
  }
//=========================================================================================
getloginData(email,password){
    this.loginService.loginUser({
      email: email,
      password: password,
      role_id: 2, //For Company admin only
      }).subscribe((response: any) => {
        this.edited = true;
        this.edited_fail = false;
      sessionStorage.setItem('token', response["accessToken"]);
      localStorage.setItem('token', response["accessToken"]);
       this.getAllData(response);
    }, error => {
    });
}
//================================== GET BROWSER DATA =======================================================
myBrowser() { 

    var navUserAgent = navigator.userAgent;
    var browserName  = navigator.appName;
    var browserVersion  = ''+parseFloat(navigator.appVersion); 
    var majorVersion = parseInt(navigator.appVersion,10);
    var tempNameOffset,tempVersionOffset,tempVersion;
    
    
    if ((tempVersionOffset=navUserAgent.indexOf("Opera"))!=-1) {
     browserName = "Opera";
     browserVersion = navUserAgent.substring(tempVersionOffset+6);
     if ((tempVersionOffset=navUserAgent.indexOf("Version"))!=-1) 
       browserVersion = navUserAgent.substring(tempVersionOffset+8);
    } else if ((tempVersionOffset=navUserAgent.indexOf("MSIE"))!=-1) {
     browserName = "Microsoft Internet Explorer";
     browserVersion = navUserAgent.substring(tempVersionOffset+5);
    } else if ((tempVersionOffset=navUserAgent.indexOf("Chrome"))!=-1) {
     browserName = "Chrome";
     browserVersion = navUserAgent.substring(tempVersionOffset+7);
    } else if ((tempVersionOffset=navUserAgent.indexOf("Safari"))!=-1) {
     browserName = "Safari";
     browserVersion = navUserAgent.substring(tempVersionOffset+7);
     if ((tempVersionOffset=navUserAgent.indexOf("Version"))!=-1) 
       browserVersion = navUserAgent.substring(tempVersionOffset+8);
    } else if ((tempVersionOffset=navUserAgent.indexOf("Firefox"))!=-1) {
     browserName = "Firefox";
     browserVersion = navUserAgent.substring(tempVersionOffset+8);
    } else if ( (tempNameOffset=navUserAgent.lastIndexOf(' ')+1) < (tempVersionOffset=navUserAgent.lastIndexOf('/')) ) {
     browserName = navUserAgent.substring(tempNameOffset,tempVersionOffset);
     browserVersion = navUserAgent.substring(tempVersionOffset+1);
     if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
     }
    }
    
    // trim version
    if ((tempVersion=browserVersion.indexOf(";"))!=-1)
       browserVersion=browserVersion.substring(0,tempVersion);
    if ((tempVersion=browserVersion.indexOf(" "))!=-1)
       browserVersion=browserVersion.substring(0,tempVersion);  
    // alert("BrowserName = " + browserName + "\n" + "Version = " + browserVersion);
    let browserData = ("BrowserName="+browserName+ ","+"Version="+browserVersion);
    return  browserData

}
//=====================================================================================
 renderExternalScript(src: string): HTMLScriptElement {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  script.async = true;
  script.defer = true;
  this.renderer.appendChild(document.body, script);
  var v = document.createElement("script");
  v.type = "text/javascript";
  let url = `function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element'); }`
  v.innerHTML = url;
  this.renderer.appendChild(document.body,v);
  return script;
}
//=========================== NAVIGATION ===========================================================
forget_password() {
  //window.location.href = GlobalConstants.web_domain + './forget';
   this.router.navigate(['/forget']);
}
sucess_login() {
 // window.location.href = GlobalConstants.web_domain + './dashboard';
  this.router.navigate(['/dashboard']);
}
dismiss() {
    this.router.navigate(['./login']);
}
forget() {
  this.router.navigate(['/forget']);
}
signup() {
    //window.location.href = '/signup'
   this.router.navigate(['/signup']);
}
toggleFieldTextType() {
  this.fieldTextType = !this.fieldTextType;
}

//===============================================================================
superAdminAccessDirectLoginToCompanyAdmin(data){
  this.loginService.SuperAdminLoginAsACompany(data).subscribe((response: any) => {
    // console.log(response)
    let removeSessionData = sessionStorage.removeItem('token');
    let removeLocalData = localStorage.removeItem('token');
    let user_data = JSON.stringify(response.userData);
    // console.log(data);
    sessionStorage.setItem('token', response.accessToken);
    sessionStorage.setItem('userData', user_data);
    sessionStorage.setItem('isLoggedin', 'true');
    localStorage.setItem('userData', user_data);
    this.SpinnerService.hide();
    this.router.navigate(['/dashboard']);
  }, error => {
    console.log("API Error")
  });
};
resendVerificationLink(){
  console.log("resendVerificationLink", `${GlobalConstants.apiURL}api/companies/resendVerificationEmailLink/${this.loginForm.value.email}`)
  
  this.http.get(`${GlobalConstants.apiURL}api/companies/resendVerificationEmailLink/${this.loginForm.value.email}`)
  .subscribe((response)=>{
    console.log("response",response)
    this.sendVerificationLink=true
    this.edited_fail_text = response['message']
  },error=>{
    this.sendVerificationLink=false
    this.edited_fail_text = "Something went wrong, please retry"
    console.log("error",error)
  })
};
}
