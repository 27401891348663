import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, pipe, Observer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalConstants } from '../../global-constants';

@Injectable({
  providedIn: 'root'
})
export class CvDashboardService {

  public server = GlobalConstants.apiURL;

  constructor(
    private http: HttpClient,
  ) { }

  getAllUserList(){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get(this.server + "api/user-services/sadmin/getAllUserList", { });
  }

  getAllPaidCompanyCounts(){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get(this.server + "api/payment/sadmin/getCompaniesPlanData", { });
  }

  getUserPlanData(){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get(this.server + "api/payment/sadmin/getUserPlanData", { });
  }

  allTransactionDetails() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem("token"));
    //return this.http.get(this.server + "api/payment/getAllTransactionBetweenDates/"+startDate+"/"+endDate, { })
    return this.http.get(this.server + "api/payment/getAllTransaction", { })
  }

  getDemoBookings(startDate,endDate) {
    return this.http.get(this.server + "api/bookDemo/getDemoBookingsBetweenDates/"+startDate+"/"+endDate,{})
  }
  getDemoBookingsCount(){
    return this.http.get(this.server + "api/bookDemo/sadmin/getDemoBookingsCount",{})
  }

  getPlanCompanies(){
    return this.http.get(this.server + "api/payment/sadmin/getPlansCounts",{})
  }

  getInActiveClients(){
    return this.http.get(this.server + "api/login-logs/sadmin/getLoginLogsForSuperAdmin",{})
  }

  getCompanyUserData(companyId){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get(this.server + "api/v2/registrations/users/company/"+companyId, {headers})
  }

  getTotalResponses(){
    return this.http.get(this.server + "api/survey-data-customer-response/sadmin/getAllResponse",{ })
  }

  getCompanyResponses(companyId){
    return this.http.get(this.server + "api/survey-data-customer-response/sadmin/getAllResponseOfCompanyforSuperAdmin/"+companyId,{ })
  }

  getAllCompanyLoginLogs(){
    return this.http.get(this.server + "api/login-logs/sadmin/getAllCompanyLoginLogsForSuperAdmin",{ })
  }

  getCompanyLogs(companyId){
    return this.http.get(this.server + "api/login-logs/sadmin/getAllLoginLogsOfCompany/"+companyId,{ })
  }

  //========================== site settings APIs ==========================================

  getAllSiteSettingsData(){
    return this.http.get(this.server + "api/site_settings/getAllSlugValue",{ })
  }

  updateSiteSettingsValue(settingsData){
    return this.http.post(this.server + "api/site_settings/updateSlugValue", settingsData )
  }

  //========================== Company Management APIs ======================================

   getAllCountriesList(){
    return this.http.get(this.server + "api/cvCountries/getAllCountriesList",{ })
  }

  getAllStatesOfCountry(countryId){
    return this.http.get(this.server + "api/cvCountries/getStatesOfCounrty/"+countryId, {} )
  }

  getAllCitiesOfState(stateId){
    return this.http.get(this.server + "api/cvCountries/getCitiesOfState/"+stateId, {} )
  }

  //============================= SMS Templates APIs ======================================

  getAlertMessageTemplates(){
    return this.http.get(this.server + "api/sms_templates/getAllAlertSmsTemplates",{ })
  }

  changeTemplateStatus(activeStatusData){
    return this.http.post(this.server + "api/sms_templates/changeAlertSmsTemplateStatus",activeStatusData)
  }

  addNewTemplate(templateData){
    return this.http.post(this.server + "api/sms_templates/createNewAlertSmsTemplate",templateData)
  }

  getAllThankYouSmsTemplates(){
    return this.http.get(this.server + "api/sms_templates/getAllThankYouSmsTemplates",{ })
  }

  userEmailVerification(value):Observable<object> {
    // let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append("x-access-token", sessionStorage.getItem('token'));
    return this.http.get(this.server + "api/auth/sadmin/verifyEmail/"+value);
      // .pipe(tap(() => {this._refeshneeded$.next()})
      // );
  };


}
