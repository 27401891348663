import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TemplateService } from '../template.service'
import { NbDialogRef } from '@nebular/theme';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'ngx-model-deletetemplete',
  templateUrl: './model-deletetemplete.component.html',
  styleUrls: ['./model-deletetemplete.component.scss']
})
export class ModelDeletetempleteComponent implements OnInit {
  index: number;
  loading =false;
  
  constructor( private http: HttpClient,
     private TemplateService: TemplateService,
    private toastrService: NbToastrService,
    protected ref: NbDialogRef<ModelDeletetempleteComponent>
    ) { }

  ngOnInit() {
  }
  deletePost() {
    this.loading =true;
     let id = window.localStorage.getItem("deleteId");
      this.TemplateService.deletetemplete(id)
    .subscribe( 
      (response) =>   {
        this.loading =false;
      this.showToast('top-right', 'success');
        this.dismiss();
     },
     error => {
    console.log('no data found');
   }	)
  }
  
  showToast(position, status) {
     this.index += 1;
     this.toastrService.show(
      status || 'Success',
      `Template  Deleted  sucessfully ! `,
      { position, status });
  }
  // }
dismiss() {
  this.ref.close();
}

}
